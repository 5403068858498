import { Button } from 'react-bootstrap';
import TextTruncate from 'react-text-truncate';

import PermissionsHelper from '../../util/PermissionsHelper';

const DraftCard = (props) => {
  const { dataset, user } = props;
  const publisherView = PermissionsHelper(user.roles, 'publisher');
  const approverView = PermissionsHelper(user.roles, 'approver');
  const adminView = PermissionsHelper(user.roles, 'admin');
  const displayPublishing = publisherView || adminView;
  const displayApproving = approverView || adminView;

  return (
    <div
      style={{
        width: '1151px',
        height: '224px',
        borderWidth: '2px',
        borderColor: '#525335',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        flexDirection: 'row',
        padding: '20px',
        marginBottom: '14px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          paddingRight: '20px',
        }}
      >
        <h3
          style={{
            fontSize: '20px',
            fontWeight: 'bolder',
            marginBottom: '0',
          }}
        >
          {dataset.title}
        </h3>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '4px',
          }}
        >
          <p
            style={{ fontSize: '12px', marginBottom: '4px', textWrap: 'wrap' }}
          >
            Org: {dataset.organization.title}
          </p>
          <p style={{ fontSize: '12px', marginBottom: '4px' }}>
            Author: {dataset.author || 'N/A'}
            <br />
            {dataset.author_email || ''}
          </p>
          <p style={{ fontSize: '12px', marginBottom: '4px' }}>
            Creator: {dataset.creator.fullname}
            <br />
            {dataset.creator.email}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
            height: '100%',
          }}
        >
          <TextTruncate
            line={4}
            element="div"
            truncateText="…"
            text={dataset.notes}
            style={{ fontSize: '13px', marginBottom: '8px' }}
          />
          <div style={{ marginBottom: '2px' }}>
            <Button
              style={{
                backgroundColor: '#525335',
                marginRight: '4px',
                border: 'none',
              }}
              // onClick={handleView}
            >
              View More
            </Button>

            <Button
              style={{
                backgroundColor: '#823F20',
                marginRight: '4px',
                border: 'none',
              }}
              // onClick={handleServicesView}
            >
              Services
            </Button>
          </div>
        </div>
      </div>
      <div>
        <div
          style={{
            width: '180px',
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '4px',
          }}
        >
          {displayPublishing ? (
            <>
              <p style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                Publisher Actions
              </p>
              <div>
                <Button
                  style={{
                    backgroundColor: '#CA7E15',
                    marginRight: '4px',
                    border: 'none',
                    width: '83px',
                  }}
                  onClick={props.handleEdit}
                >
                  Edit
                </Button>
                <Button
                  style={{
                    backgroundColor: '#823F20',
                    marginRight: '4px',
                    border: 'none',
                    width: '83px',
                  }}
                  onClick={props.handleDelete}
                >
                  Delete
                </Button>
              </div>
            </>
          ) : null}
        </div>

        <div
          style={{ width: '180px', display: 'flex', flexDirection: 'column' }}
        >
          {displayApproving ? (
            <>
              <p style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                Approver Actions
              </p>
              <div>
                <Button
                  style={{
                    backgroundColor: '#525335',
                    marginRight: '4px',
                    border: 'none',
                    width: '83px',
                  }}
                  onClick={props.handleApprove}
                >
                  Approve
                </Button>

                <Button
                  style={{
                    backgroundColor: '#a64500',
                    marginRight: '4px',
                    border: 'none',
                    width: '83px',
                  }}
                  onClick={props.handleDeny}
                >
                  Deny
                </Button>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default DraftCard;
