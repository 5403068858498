import React from 'react';
import { FilterOutlined } from '@ant-design/icons';
import { Modal, Typography, Checkbox, notification } from 'antd';
import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons';

import 'antd/dist/antd.css';
import './query.css';
import Button from 'antd/es/button';
import { Tooltip } from 'antd';

import GeoJSON from 'ol/format/GeoJSON';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { Stroke, Style } from 'ol/style';
import config from './config';
import { Circle as CircleStyle, Fill } from 'ol/style';
import WorkspaceContext from '../WorkspaceContext';
import OlLayerTile from 'ol/layer/Tile';
import OlSourceTileWMS from 'ol/source/TileWMS';
import { getSLDForLayer } from './utils';
import { toInteger } from 'lodash';
import { getIntersection } from 'ol/extent';

const { Text } = Typography;
const image = new CircleStyle({
  radius: 5,
  fill: null,
  stroke: new Stroke({ color: 'red', width: 1 }),
});

const styles = {
  Point: new Style({
    image: image,
  }),
  LineString: new Style({
    stroke: new Stroke({
      color: 'rgba(0, 0, 240, 0.8)',
      width: 1,
    }),
  }),
  MultiLineString: new Style({
    stroke: new Stroke({
      color: 'green',
      width: 1,
    }),
  }),
  MultiPoint: new Style({
    image: image,
  }),
  MultiPolygon: new Style({
    stroke: new Stroke({
      color: 'rgba(0, 0, 240, 0.8)',
      width: 1,
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 240, 0.8)',
    }),
  }),
  Polygon: new Style({
    stroke: new Stroke({
      color: 'rgba(0, 0, 240, 0.8)',
      lineDash: [4],
      width: 1,
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 240, 0.8)',
    }),
  }),
  GeometryCollection: new Style({
    stroke: new Stroke({
      color: 'magenta',
      width: 2,
    }),
    fill: new Fill({
      color: 'magenta',
    }),
    image: new CircleStyle({
      radius: 10,
      fill: null,
      stroke: new Stroke({
        color: 'magenta',
      }),
    }),
  }),
  Circle: new Style({
    stroke: new Stroke({
      color: 'red',
      width: 2,
    }),
    fill: new Fill({
      color: 'rgba(0, 0, 240, 0.8)',
    }),
  }),
};

const styleFunction = function (feature) {
  return styles[feature.getGeometry().getType()];
};

class ForestDataMapIntersect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      visible: false,
      is_intersect: false,
    };
    this.toggleHover = this.toggleHover.bind(this);
    this.showGUI = this.showGUI.bind(this);
    this.hideGUI = this.hideGUI.bind(this);
    this.onQuery = this.onQuery.bind(this);
    this.onReset = this.onReset.bind(this);
    this.getConfigLabels = this.getConfigLabels.bind(this);
    this.openNotification = this.openNotification.bind(this);
  }

  toggleHover() {
    this.setState({ hover: !this.state.hover });
  }

  showGUI() {
    this.setState({
      visible: true,
    });
  }

  hideGUI() {
    this.setState({
      visible: false,
    });
  }

  onReset() {}

  getConfigLabels() {
    let allConfigNames = {};

    let rrk_datasets = config['Regional Resource Kit']['Southern California'];
    let its_datasets = config['Interagency Tracking System'];

    rrk_datasets.forEach((rrk) => {
      allConfigNames[rrk.layer] = rrk.name;
    });

    its_datasets.forEach((its) => {
      allConfigNames[its.layer] = its.name;
    });

    return allConfigNames;
  }

  handleCheckboxChange(event) {
    this.setState({ is_intersect: event.target.checked });
  }

  openNotification(placement, message) {
    notification.info({
      message: message,
      icon: <StopOutlined />,
      style: { color: 'red' },
      placement,
    });
  }

  async onQuery() {
    //start spinner
    document.getElementById('map').classList.add('spinner');

    let datasetInfo = this.props.getQueryData();
    let keys = Object.keys(datasetInfo);
    let selected_layers = [];

    this.props.layers.forEach((layer) => {
      if (keys.includes(layer.getSource().getParams().LAYERS)) {
        selected_layers.push(layer.getSource());
        var vectorLayer = new VectorLayer({
          source: layer.getSource(),
          zIndex: 100,
          opacity: 0.3,
        });
      }
    });

    let test = await getIntersection(keys[0], keys[1]);
  }
  // let to_intersect = this.state.is_intersect

  // let base_url = process.env.REACT_APP_API_URL

  // const response = await fetch(`${base_url}/query_views/get_query?data=${JSON.stringify(datasetInfo)}&is_intersect=${to_intersect}`,
  //     {
  //         method: "GET",
  //         headers: {
  //             'Accept': 'application/json',
  //             'Content-Type': 'application/json',
  //         },
  //     })
  //     .then((response) => response.json())
  //     .then((responseData) => {
  //         return responseData;
  //     })
  //     .catch(error => console.warn(error));

  // if(response !== undefined && !('status' in response))
  // {
  //   let datasetLabels = this.getConfigLabels()

  //   let allQueryLayers = this.props.queryLayers
  //   let allqueryLayerInfo = this.props.queryLayerInfo
  //   let allCheckedQueries = this.props.checkedQuerysets

  //   let features = response['allFeatures']
  //   let datasetNames = response['datasetNames']

  //   let queryResource = new VectorSource({
  //     features: new GeoJSON().readFeatures(features),
  //   })

  //   let queryResultLayer = new VectorLayer({
  //     source: queryResource,
  //     style: styleFunction
  //   })

  //   let ol_uid = toInteger(queryResultLayer["ol_uid"])
  //   let layerName = "Intersection"
  //   let layerId = "filter: Intersection" + String(ol_uid -1)
  //   let tooltipList = []

  //   datasetNames.forEach(e => {
  //     if(e in datasetLabels){
  //       tooltipList.push(datasetLabels[e])
  //     } else {
  //       tooltipList.push(e)
  //     }
  //   })

  //   let tooltip = tooltipList.join(', ')

  //   queryResultLayer.setProperties({
  //     layerId: layerId
  //   })

  //   let item = {
  //     "color": 'rgba(0, 0, 240, 0.8)',
  //     "color_end" : "#160FFD",
  //     "layerId" : layerId,
  //     "name" : layerName,
  //     'tooltip': tooltip,
  //     'min': 0,
  //     'max': 1,
  //     "color_start" : "#FFD1FB",
  //     "type" : "vector",
  //     "url" : 'filter'
  //   }

  //   let dataset = {
  //     folder: "/Filtered By Intersection",
  //     mapping: [item],
  //     visible: true
  //   }

  //   allQueryLayers.push(queryResultLayer)
  //   allqueryLayerInfo.push(dataset)
  //   allCheckedQueries[layerId] = true

  //   this.props.map.addLayer(queryResultLayer)

  //   this.props.setQueryLayers(allQueryLayers);
  //   this.props.setQueryLayerInfo(allqueryLayerInfo)
  //   this.props.setCheckedQuerysets(allCheckedQueries)

  //   document.getElementById("map").classList.remove('spinner');

  // } else {
  //   this.openNotification('top', response['message'])
  //   document.getElementById("map").classList.remove('spinner');
  // }
  //}

  render() {
    let rootStyle = this.props.style;
    if (rootStyle === undefined) {
      rootStyle = {
        position: 'absolute',
        top: '230px',
        left: '7px',
        padding: '1px 4px 1px 4px',
        borderRadius: '6px',
        backgroundColor: this.state.hover
          ? 'rgba(0, 60, 136, .8)'
          : 'rgba(0, 60, 136, .5)',
        border: '3px solid rgba(255, 255, 255, 0.72)',
      };
    }

    return (
      <div
        style={rootStyle}
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
      >
        <Tooltip title={'Intersection'} placement="right">
          <FilterOutlined onClick={this.onQuery} style={{ color: 'white' }} />
        </Tooltip>
      </div>
    );
  }
}

export default ForestDataMapIntersect;
