const WPS = {
  getDownload: async (fileName, gisItem, boundaryData, filterArea) => {
    const fileITS = gisItem.service_url.search('ITS') !== -1;
    let wpsRequestBody;

    if (!fileITS) {
      wpsRequestBody = `<wps:Execute version="1.0.0" service="WPS" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.opengis.net/wps/1.0.0" xmlns:wfs="http://www.opengis.net/wfs" xmlns:wps="http://www.opengis.net/wps/1.0.0" xmlns:ows="http://www.opengis.net/ows/1.1" xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" xmlns:wcs="http://www.opengis.net/wcs/1.1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xsi:schemaLocation="http://www.opengis.net/wps/1.0.0 http://schemas.opengis.net/wps/1.0.0/wpsAll.xsd">
   <ows:Identifier>gs:IntersectionFeatureCollection</ows:Identifier>
   <wps:DataInputs>
     <wps:Input>
       <ows:Identifier>first feature collection</ows:Identifier>
       <wps:Reference mimeType="text/xml" xlink:href="http://geoserver/wfs" method="POST">
         <wps:Body>
           <wfs:GetFeature service="WFS" version="1.0.0" outputFormat="GML2" xmlns:boundary="https://sparcal.sdsc.edu/geoserver/boundary">
             <wfs:Query typeName="${boundaryData.name}" srsName="EPSG:4269">
               <ogc:Filter>
                 <ogc:PropertyIsEqualTo>
                   <ogc:PropertyName>${boundaryData.label}</ogc:PropertyName>
                   <ogc:Literal>${filterArea}</ogc:Literal>
                 </ogc:PropertyIsEqualTo>
               </ogc:Filter>
             </wfs:Query>
           </wfs:GetFeature>
         </wps:Body>
       </wps:Reference>
     </wps:Input>
     <wps:Input>
       <ows:Identifier>second feature collection</ows:Identifier>
       <wps:Reference mimeType="text/xml" xlink:href="http://geoserver/wfs" method="POST">
         <wps:Body>
           <wfs:GetFeature service="WFS" version="1.0.0" outputFormat="GML2" xmlns:rrk="rrk">
             <wfs:Query typeName="${gisItem.layer_name}" srsName="EPSG:4269"/>
           </wfs:GetFeature>
         </wps:Body>
       </wps:Reference>
     </wps:Input>
     <wps:Input>
       <ows:Identifier>first attributes to retain</ows:Identifier>
       <wps:Data>
         <wps:LiteralData>${boundaryData.label}</wps:LiteralData>
       </wps:Data>
     </wps:Input>
     <wps:Input>
       <ows:Identifier>second attributes to retain</ows:Identifier>
       <wps:Data>
         <wps:LiteralData>*</wps:LiteralData>
       </wps:Data>
     </wps:Input>
     <wps:Input>
       <ows:Identifier>intersectionMode</ows:Identifier>
       <wps:Data>
         <wps:LiteralData>INTERSECTION</wps:LiteralData>
       </wps:Data>
     </wps:Input>
   </wps:DataInputs>
   <wps:ResponseForm>
     <wps:RawDataOutput mimeType="application/json">
       <ows:Identifier>result</ows:Identifier>
     </wps:RawDataOutput>
   </wps:ResponseForm>
 </wps:Execute>`;
    } else {
      wpsRequestBody = `<?xml version="1.0" encoding="UTF-8"?><wps:Execute version="1.0.0" service="WPS" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.opengis.net/wps/1.0.0" xmlns:wfs="http://www.opengis.net/wfs" xmlns:wps="http://www.opengis.net/wps/1.0.0" xmlns:ows="http://www.opengis.net/ows/1.1" xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" xmlns:wcs="http://www.opengis.net/wcs/1.1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xsi:schemaLocation="http://www.opengis.net/wps/1.0.0 http://schemas.opengis.net/wps/1.0.0/wpsAll.xsd">                                                                                                                                                                                                                                                              
    <ows:Identifier>gs:IntersectionFeatureCollection</ows:Identifier>
    <wps:DataInputs>
      <wps:Input>
        <ows:Identifier>first feature collection</ows:Identifier>
        <wps:Reference mimeType="text/xml" xlink:href="http://geoserver/wfs" method="POST">
          <wps:Body>
            <wfs:GetFeature service="WFS" version="1.0.0" outputFormat="GML2" xmlns:boundary="https://sparcal.sdsc.edu/geoserver/boundary">
              <wfs:Query typeName="${boundaryData.name}" srsName="EPSG:4269">
                <ogc:Filter>
                  <ogc:PropertyIsEqualTo>
                    <ogc:PropertyName>${boundaryData.label}</ogc:PropertyName>
                    <ogc:Literal>${filterArea}</ogc:Literal>
                  </ogc:PropertyIsEqualTo>
                </ogc:Filter>
              </wfs:Query>
            </wfs:GetFeature>
          </wps:Body>
        </wps:Reference>
      </wps:Input>
      <wps:Input>
        <ows:Identifier>second feature collection</ows:Identifier>
        <wps:Reference mimeType="text/xml" xlink:href="http://geoserver/wfs" method="POST">
          <wps:Body>
            <wfs:GetFeature service="WFS" version="1.0.0" outputFormat="GML2" xmlns:ITS="https://sparcal.sdsc.edu/geoserver/ITS">
              <wfs:Query typeName="${gisItem.layer_name}" srsName="EPSG:4269"/>
            </wfs:GetFeature>
          </wps:Body>
        </wps:Reference>
      </wps:Input>
      <wps:Input>
        <ows:Identifier>first attributes to retain</ows:Identifier>
        <wps:Data>
          <wps:LiteralData>${boundaryData.label}</wps:LiteralData>
        </wps:Data>
      </wps:Input>
      <wps:Input>
        <ows:Identifier>intersectionMode</ows:Identifier>
        <wps:Data>
          <wps:LiteralData>SECOND</wps:LiteralData>
        </wps:Data>
      </wps:Input>
    </wps:DataInputs>
    <wps:ResponseForm>
      <wps:RawDataOutput mimeType="application/json">
        <ows:Identifier>result</ows:Identifier>
      </wps:RawDataOutput>
    </wps:ResponseForm>
  </wps:Execute>`;
    }
    const response = await fetch('https://sparcal.sdsc.edu/geoserver/wps', {
      method: 'POST',
      headers: { 'Content-Type': 'text/xml' },
      body: wpsRequestBody,
    })
      .then(async (response) => {
        return await response.blob();
      })
      .then((blob) => {
        var blobURL = URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.style.display = 'none';
        anchor.href = blobURL;
        anchor.download = fileName + '.geojson';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      });
  },
};
export default WPS;
