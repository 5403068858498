import axios from 'axios';

const CKAN = {
  getDatasets: async () => {
    const orgsData = await axios
      .get(
        `${process.env.REACT_APP_PUBLIC_CKAN_CATALOG_URL}/organization_list?all_fields=true`
      )
      .then((resp) => {
        return resp.data.result;
      });

    var newOrgsData = {};
    orgsData.map((org) => {
      newOrgsData[String(org.title)] = org.image_display_url;
    });

    var newDatasets = [];
    const clmPackages = await axios
      .get(
        `${process.env.REACT_APP_PUBLIC_CKAN_CATALOG_URL}/package_search?fq=organization:california-wildfire-forest-resilience-task-force&rows=1000`
      )
      .then((resp) => {
        return resp.data.result.results;
      });

    clmPackages.map((d) => {
      d.notes = d.notes
        ?.replace(/<[^>]+>/g, '')
        .replaceAll('NBSP', '')
        .replace(/\\r\\n/g, '');
      // var rootKeys = [
      //   'id',
      //   'title',
      //   'name',
      //   'notes',
      //   'num_resources',
      //   'num_tags',
      //   'extras',
      //   'resources',
      //   'tags',
      //   'title',
      // ];
      let dObj = Object.keys(d)
        // .filter((key) => rootKeys.includes(key))
        .reduce((acc, key) => {
          acc[key] = d[key];
          return acc;
        }, {});
      dObj['org_title'] = d.organization?.title ?? 'Unknown';
      dObj['org_image_url'] = newOrgsData[d.organization?.title] ?? '';
      let newResources = [];
      d.resources.map(async (r) => {
        let newResourceObj = Object.keys(r)
          // .filter(
          //   (key) =>
          //     key === 'name' ||
          //     key === 'url' ||
          //     key === 'id' ||
          //     key === 'format' ||
          //     key === 'description'
          // )
          .reduce((acc, key) => {
            acc[key] = r[key];
            return acc;
          }, {});
        newResources.push(newResourceObj);
      });
      // dObj['resources'] = newResources;
      let newTags = [];
      d.tags.map((t) => {
        let newTagObj = Object.keys(t)
          .filter((key) => key === 'name')
          .reduce((acc, key) => {
            acc[key] = t[key];
            return acc;
          }, {});

        newTags.push(newTagObj);
      });
      dObj['tags'] = newTags;
      newDatasets.push(dObj);
    });
    return newDatasets;
  },
  searchDatasets: async (props) => {
    const { searchString, collectionNames, pillar, element } = props;
    let collectionQuery = '';
    if (collectionNames != '') {
      collectionQuery += `extras_collection_name:"${collectionNames}"`;
      if (pillar != '') {
        collectionQuery += ` AND extras_pillar:"${pillar}"`;
        if (element != '') {
          collectionQuery += ` AND extras_element:"${element}"`;
        }
      }
    }
    let searchStringParams = '';
    if (searchString) {
      searchStringParams = 'q=' + searchString;
    }

    const clmPackages = await axios
      .get(
        `${process.env.REACT_APP_PUBLIC_CKAN_CATALOG_URL}/package_search?fq=organization:california-wildfire-forest-resilience-task-force${collectionQuery && ' AND ' + collectionQuery}&${searchStringParams ? searchStringParams + '&' : ''}rows=1000`
      )
      .then((resp) => {
        return resp.data.result.results;
      });

    const orgsData = await axios
      .get(
        `${process.env.REACT_APP_PUBLIC_CKAN_CATALOG_URL}/organization_list?all_fields=true`
      )
      .then((resp) => {
        return resp.data.result;
      });

    var newOrgsData = {};
    orgsData.map((org) => {
      newOrgsData[String(org.title)] = org.image_display_url;
    });

    var newDatasets = [];

    clmPackages.map((d) => {
      d.notes = d.notes
        ?.replace(/<[^>]+>/g, '')
        .replaceAll('NBSP', '')
        .replace(/\\r\\n/g, '');
      // var rootKeys = [
      //   'id',
      //   'title',
      //   'name',
      //   'notes',
      //   'num_resources',
      //   'num_tags',
      //   'extras',
      //   'resources',
      //   'tags',
      //   'title',
      // ];
      let dObj = Object.keys(d)
        // .filter((key) => rootKeys.includes(key))
        .reduce((acc, key) => {
          acc[key] = d[key];
          return acc;
        }, {});
      dObj['org_title'] = d.organization?.title ?? 'Unknown';
      dObj['org_image_url'] = newOrgsData[d.organization?.title] ?? '';
      let newResources = [];
      d.resources.map(async (r) => {
        let newResourceObj = Object.keys(r)
          // .filter(
          //   (key) =>
          //     key === 'name' ||
          //     key === 'url' ||
          //     key === 'id' ||
          //     key === 'format' ||
          //     key === 'description'
          // )
          .reduce((acc, key) => {
            acc[key] = r[key];
            return acc;
          }, {});
        newResources.push(newResourceObj);
      });
      // dObj['resources'] = newResources;
      let newTags = [];
      d.tags.map((t) => {
        let newTagObj = Object.keys(t)
          .filter((key) => key === 'name')
          .reduce((acc, key) => {
            acc[key] = t[key];
            return acc;
          }, {});

        newTags.push(newTagObj);
      });
      dObj['tags'] = newTags;
      newDatasets.push(dObj);
    });
    return newDatasets;
  },
  getSpecificDatasetById: async (id) => {
    const dataset = await axios
      .get(
        `${process.env.REACT_APP_PUBLIC_CKAN_CATALOG_URL}/package_search?fq=id:${id}`
      )
      .then((resp) => {
        return resp.data.result;
      });
    return dataset;
  },
  getSpecificDatasetByName: async (name) => {
    const dataset = await axios
      .get(
        `${process.env.REACT_APP_PUBLIC_CKAN_CATALOG_URL}/package_search?fq=name:${name}`
      )
      .then((resp) => {
        return resp.data.result.results;
      })
      .catch((err) => {
        return { status: err.status, message: err.message };
      });
    return dataset;
  },
  getBoundaryDataset: async () => {
    const dataset = await axios
      .get(
        `${process.env.REACT_APP_PUBLIC_CKAN_CATALOG_URL}/package_search?q=extras_collection_name:"Boundary Datasets"`
      )
      .then((resp) => {
        return resp.data.result.results;
      });
    return dataset;
  },

  getWfsBoundaryData: async (url, param) => {
    const getUrl =
      url +
      '/' +
      param +
      '?service=WFS&version=1.0.0&request=GetFeature&outputFormat=application/json&typeName=' +
      param;
    const dataset = await axios
      .get(getUrl, { responseType: 'arraybuffer' })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return { status: err.status, message: err.message };
      });
    return dataset;
  },
  // getWMSImage: async (itemGis, width, height) => {
  //   const spatial = JSON.parse(itemGis.dataset_collections[0]?.spatial);
  //   const coordinates1 = spatial.coordinates[0][0];
  //   const coordinates2 = spatial.coordinates[0][2];
  //   const coordString = `${coordinates1[0]},${coordinates1[1]},${coordinates2[0]},${coordinates2[1]}`;

  //   const getUrl =
  //     itemGis.service_url +
  //     '?service=WMS&version=1.1.0&request=GetMap&layers=' +
  //     itemGis.layer_name +
  //     `&bbox=${coordString}&width=` +
  //     width +
  //     '&height=' +
  //     height +
  //     '&srs=EPSG:4326&styles=&format=image/png&TRANSPARENT=TRUE';
  //   const dataset = await axios
  //     .get(getUrl, { responseType: 'arraybuffer' })
  //     .then((resp) => {
  //       const base64 = btoa(
  //         new Uint8Array(resp.data).reduce(
  //           (data, byte) => data + String.fromCharCode(byte),
  //           ''
  //         )
  //       );
  //       return base64;
  //     })
  //     .catch((err) => {
  //       return { status: err.status, message: err.message };
  //     });
  //   return dataset;
  // },
};

export default CKAN;
