import { BookOutlined, LoadingOutlined } from '@ant-design/icons';
import { Modal, Tooltip, Spin } from 'antd';
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { BoundaryData } from './BoundaryData';

const ForestDataMapITSReport = () => {
  const [openModal, setOpenModal] = useState(false);
  const [downloadLoading, setDownlaodLoading] = useState(false);
  const [hover, setHover] = useState(false);
  const [boundary, setBoundary] = useState(undefined);
  const [location, setLocation] = useState(undefined);
  const [format, setFormat] = useState('pdf');
  const [download, setDownload] = useState(undefined);
  //   const [percentage, setPercentage] = useState(0);
  const [statusSection, setStatusSection] = useState(null);

  const toggleHover = () => {
    setHover(!hover);
  };

  const closeModal = () => {
    setLocation(undefined);
    setBoundary(undefined);
    setStatusSection(null);
    setDownload(undefined);
    setDownlaodLoading(undefined);
    setOpenModal(false);
  };

  const handleFileDownload = (result) => {
    try {
      const url = URL.createObjectURL(result);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${location.replace(/ /g, '_')}_report.${format}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      setStatusSection(
        <div>
          <h4>Download Complete</h4>
          <p>
            {format.toUpperCase()} report for {location} has been downloaded.
          </p>
        </div>
      );
    } catch (error) {
      setStatusSection(
        <div>
          <h4>Download Failed</h4>
          <p>Failed to download the report: {error.message}</p>
        </div>
      );
    }
  };

  const handleLoader = async () => {
    setStatusSection(null);
    setDownlaodLoading(true);

    // let reportData = null;
    // let totalTime = 30000;
    // let progress = 0;
    // const updateInterval = 500; // Update every 500ms
    // const increment = (updateInterval / totalTime) * 100;
    // const progressInterval = setInterval(() => {
    //   progress += increment;
    //   setPercentage(progress);
    //   //   progressBar.style.width = `${Math.min(progress, 100)}%`;

    //   // If we have the report and progress is at least at threshold
    //   // Excel reports are fast, so we don't need to wait as long
    //   const progressThreshold = 90;

    //   if (downloadLoading && progress >= progressThreshold) {
    //     progress = 100;
    //     setPercentage(progress);
    //     // progressBar.style.width = '100%';
    //     clearInterval(progressInterval);

    //     // Stop spinner and change its appearance
    //     // loadingSpinner.classList.add('completed');

    //     // Update status text
    //     // statusText.textContent = `The report for ${county} is ready!`;

    //     // Show the view/download report button
    //     // viewReportBtn.style.display = 'block';
    //   }

    //   // If progress reaches 100% without the report
    //   if (progress >= 100 && !download) {
    //     clearInterval(progressInterval);
    //   }
    // }, updateInterval);
    const apiUrl = `https://sparcal.sdsc.edu/api/v1/Utility/its_region_report?boundary_dataset_name=${boundary}&region_name=${location}&output_format=${format}`;

    await fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          // First check if it's a JSON error response
          return response
            .json()
            .then((errorData) => {
              // If we successfully parsed JSON, throw with the specific error message
              throw new Error(
                errorData.detail ||
                  `Server returned ${response.status}: ${response.statusText}`
              );
            })
            .catch((jsonError) => {
              // If JSON parsing fails, just throw the HTTP error
              if (jsonError instanceof SyntaxError) {
                throw new Error(
                  `Server returned ${response.status}: ${response.statusText}`
                );
              }
              throw jsonError; // Re-throw if it's our custom error
            });
        }

        return response.blob();
      })
      .then(async (result) => {
        // Store the report data
        console.log('result', result);
        setDownload(result);
        setDownlaodLoading(false);

        handleFileDownload(result);
      })
      .catch((error) => {
        console.log('error', error);
        const errorMessage =
          error.name === 'AbortError'
            ? 'Request was cancelled.'
            : error.message ||
              'An unexpected error occurred while generating the report.';

        // Check for common error types and provide appropriate UI
        if (errorMessage.includes('No treatment data found')) {
          // No data available error
          setStatusSection(
            <div>
              <h4>No Treatment Data Available</h4>
              <p>{errorMessage}</p>
              <p>
                Please select a different location or dataset that contains
                treatment data.
              </p>
            </div>
          );
        } else if (errorMessage.includes('No boundary dataset')) {
          // Invalid dataset error
          setStatusSection(
            <div>
              <h4>Invalid Dataset</h4>
              <p>{errorMessage}</p>
              <p>Please select a different boundary dataset.</p>
            </div>
          );
        } else if (errorMessage.includes('Invalid output format')) {
          // Invalid format error
          setStatusSection(
            <div>
              <h4>Invalid Format</h4>
              <p>{errorMessage}</p>
              <p>Please select a different output format.</p>
            </div>
          );
        } else {
          // General error (network, server, etc.)
          setStatusSection(
            <div>
              <h4>Error Generating Report</h4>
              <p>{errorMessage}</p>
              <p>
                Please check your network connection and try again. If the
                problem persists, contact support.
              </p>
            </div>
          );
        }
        setDownlaodLoading(false);
        setDownload(undefined);
      });
  };

  let rootStyle;
  if (rootStyle === undefined) {
    rootStyle = {
      ...rootStyle,
      position: 'absolute',
      // top: '96px',
      // left: '7px',
      padding: '1px 4px 1px 4px',
      borderRadius: '6px',
      backgroundColor: hover ? '#61633f' : '#9C9D86',
      border: '3px solid rgba(255, 255, 255, 0.72)',
    };
  }
  return (
    <span>
      <span
        style={rootStyle}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
      >
        <Tooltip title={'Reports'} placement="top">
          <BookOutlined
            onClick={() => {
              setOpenModal(true);
            }}
            style={{ color: 'white' }}
          />
        </Tooltip>
      </span>
      <Modal
        title="ITS Report Generator"
        open={openModal}
        onCancel={closeModal}
        destroyOnClose={true}
        width={700}
        bodyStyle={{
          maxHeight: 350,
        }}
        footer={
          <>
            {downloadLoading ? (
              <Button form="download_data" type="primary" disabled>
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
                />
                <span style={{ paddingLeft: 10 }}>Download</span>
              </Button>
            ) : (
              <>
                <Button
                  form="download_data"
                  type="primary"
                  onClick={handleLoader}
                  //   disabled={filterData}
                  style={{
                    backgroundColor: '#61633f',
                    border: 'none',
                  }}
                >
                  Generate Report
                </Button>
              </>
            )}
          </>
        }
      >
        <Form
          name="download_data"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 24,
          }}
          layout="vertical"
          autoComplete="off"
          //   onFinish={(values) => this.onDownload(values)}
        >
          <div>Select Boundary Dataset</div>
          {/* <FormControl name="layer_name"> */}
          <Form.Select
            onChange={(value) => {
              setBoundary(value.target.value);
              setDownload(null);
              setLocation(null);
              setStatusSection(null);
            }}
          >
            <option value={undefined}>-- Select a Dataset --</option>
            {Object.keys(BoundaryData).map((dataset) => {
              return (
                <option key={dataset} value={dataset}>
                  {dataset}
                </option>
              );
            })}
          </Form.Select>
          <div style={{ paddingTop: '12px' }}>Select Location from Dataset</div>
          <Form.Select
            onChange={(value) => {
              setLocation(value.target.value);
              setDownload(undefined);
              setStatusSection(null);
            }}
          >
            <option value={undefined}>-- Select a Location --</option>
            {boundary &&
              BoundaryData[boundary].map((boundaryRegion) => {
                return (
                  <option key={boundaryRegion} value={boundaryRegion}>
                    {boundaryRegion}
                  </option>
                );
              })}
          </Form.Select>
          <div style={{ paddingTop: '12px' }}>Output Format</div>
          <Form.Select
            onChange={(value) => {
              setFormat(value.target.value);
              setStatusSection(null);
            }}
          >
            <option value="pdf">PDF</option>
            <option value="xlsx">Excel (XLSX)</option>
          </Form.Select>
          {/* </FormControl> */}
        </Form>
        {downloadLoading && !download && (
          <div>
            <div>
              <p>
                Generating treatment report for {boundary}, {location}...
              </p>
            </div>
          </div>
        )}
        <div style={{ paddingTop: 4 }}>{statusSection}</div>
      </Modal>
    </span>
  );
};
export default ForestDataMapITSReport;
