import {
  Layout,
  Menu,
  Space,
  Typography,
  Modal,
  Button,
  Dropdown,
  message,
  Form,
  Input,
  Table,
  Pagination,
  Tooltip,
  Row,
  Col,
} from 'antd';
import {
  CaretDownOutlined,
  SearchOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import './rrk.css';
import Breadcrumb from 'antd/es/breadcrumb';

import OlMap from 'ol/Map';
import OlView from 'ol/View';
import OlLayerTile from 'ol/layer/Tile';
import { MapComponent } from '@terrestris/react-geo';
import XYZ from 'ol/source/XYZ';
import OlSourceTileWMS from 'ol/source/TileWMS';
import axios from 'axios';
import WorkspaceContext from '../WorkspaceContext';
import download from './download';
import download_shape from './download_shape';
import WMSCapabilities from 'ol/format/WMSCapabilities';
import { boundingExtent } from 'ol/extent';
import { transformExtent, get, fromLonLat } from 'ol/proj';
// import {getCollectionList} from './catalog_func.js';

import {
  getCollectionList,
  setTaxonomyFromCollectionId,
  find_dataset_taxa,
  getSelectedKeys,
  setDatasetAndDict,
  getSetCollectionList,
  getExtent,
} from './CatalogFunc';
import userEvent from '@testing-library/user-event';
import fetch from 'sync-fetch';

const { Header, Content, Sider } = Layout;
const { Search } = Input;
const { Text, Title } = Typography;

const DataCollection = (props) => {
  const [selected_collection, setSelectedCollection] = useState();

  // const collections = [
  //     {
  //         "key" : "its",
  //         "label" : "Interagency Tracking System"
  //     },
  //     {
  //         "key" : "clm",
  //         "label" : "California Landscape Metrics"
  //     }
  // ]

  const iframe_url = {
    its: 'https://interagencytrackingsystem.org/',
    clm: 'https://caregionalresourcekits.org/clm.html',
  };

  useEffect(() => {
    if ((props.display == 'its') | (props.display == 'clm')) {
      setSelectedCollection(props.display);
    } else {
      setSelectedCollection(null);
    }
  }, [props.display]);

  // const selectCollections = ({ key }) => {
  //     setSelectedCollection(key)
  // }

  return (
    <>
      {(props.display === 'its' || props.display === 'clm') && (
        <div
          id="data_browse"
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: 'calc(100vh - 40px)',
          }}
        >
          <Layout>
            {/* <Header
                   style={{
                       display: 'flex',
                       alignItems: 'center',
                       backgroundColor: '#EEEDE3',
                       height: 40,
                       marginLeft: -27,
                       color: 'black',
                       fontWeight: 'bold',
                       marginBottom: '5px'
                   }}
               >
                   Data Collections
                   <span style={{width: 20, color:'lightgray'}}></span>
                   <Dropdown menu={{
                               items: collections,
                               onClick: selectCollections,
                             }}
                             placement={"bottomLeft"}
                             style={{ color: 'black' }}>
                            <Button style={{borderRadius: 5}}>
                                <Space>
                                <span>
                                    {selected_collection !== null
                                    ? collections.filter(e => e.key == selected_collection)[0]['label']
                                        : '- SELECT -'}
                                </span>
                                <CaretDownOutlined style={{color: 'gray', paddingLeft: 5}}/>
                                </Space>
                            </Button>
                       
                   </Dropdown>
                </Header> */}
            <iframe
              style={{
                position: 'absolute',
                // top:95,
                left: 0,
                width: '100%',
                height: '100%',
                border: 'none',
                overflow: 'hidden',
              }}
              src={selected_collection ? iframe_url[selected_collection] : ''}
            ></iframe>
          </Layout>
        </div>
      )}
    </>
  );
};

export default DataCollection;
