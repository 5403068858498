import { Form, Button, Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import * as formik from 'formik';
import * as yup from 'yup';
import ResponseModal from '../components/modal/ResponseModal';
import Sparcal from '../lib/Sparcal';
import AddEditCustomFields from './AddEditCustomFields';

const AddEditDataForm = (props) => {
  const { user } = props;
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState(null);
  const { Formik } = formik;

  const schema = yup.object().shape({
    title: yup.string().required(),
    notes: yup.string().required(),
    tags: yup.string().required(),
    url: yup.string().required(),
    author: yup.string(),
    author_email: yup.string(),
    maintainer: yup.string(),
    maintainer_email: yup.string(),
    version: yup.string(),
    license: yup.string(),
    customFields: yup.array(),
  });
  const handleSave = async (values) => {
    const name = values.title.trim().split(' ').join('-').toLowerCase();
    const tags = values.tags.split(',');
    const tagList = tags.map((tag) => {
      return { name: tag.trim() };
    });
    const extras = values.customFields?.filter((value) => value !== '');
    let data = {
      name: name,
      title: values.title,
      notes: values.notes,
      tags: tagList,
      extras: extras || null,
      author: values.author || null,
      author_email: values.author_email || null,
      maintainer: values.maintainer || null,
      maintainer_email: values.maintainer_email || null,
      url: values.url,
      version: values.version || null,
      license: values.license,
    };

    const response = await Sparcal.createDataset(user, data);
    console.log('response', response);
    if (response.status == '200') {
      setModalShow(true);
      setModalData({ title: 'Save Successful!', description: '' });
    } else if (response.status == '401') {
      setModalShow(true);
      setModalData({
        title: 'Unsuccessful!',
        description: 'Please relog in, access_code has expired.',
      });
    } else {
      setModalShow(true);
      setModalData({ title: 'Unsuccessful!', description: response.message });
    }
  };

  return (
    <>
      {modalShow && (
        <ResponseModal
          data={modalData}
          onHide={() => {
            setModalShow(false);
            setModalData(null);
          }}
        />
      )}
      <Formik
        validationSchema={schema}
        onSubmit={handleSave}
        initialValues={{
          title: '',
          notes: '',
          tags: '',
          url: '',
          author: '',
          author_email: '',
          maintainer: '',
          maintainer_email: '',
          version: '',
          license: '',
          customFields: [],
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
        }) => (
          <div
            style={{
              backgroundColor: '#EEEDE2',
              backgroundSize: 'cover',
              flexGrow: 1,
              display: 'flex',
              padding: '35px 10%',
            }}
          >
            <Form
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
              onSubmit={handleSubmit}
              noValidate
            >
              <Form.Group className="mb-3">
                <Form.Label
                  style={{
                    color: '#525335',
                    fontSize: '22px',
                    fontWeight: 'bolder',
                  }}
                >
                  Title:
                </Form.Label>
                <Form.Control
                  placeholder=""
                  name="title"
                  type="string"
                  value={values.title}
                  onChange={handleChange}
                  required
                  isInvalid={touched.title && !!errors.title}
                />
              </Form.Group>

              <Row>
                <Form.Group
                  as={Col}
                  className="mb-3"
                  style={{ width: '330px' }}
                >
                  <Form.Label style={{ color: '#525335', fontSize: '22px' }}>
                    Description:
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="notes"
                    type="string"
                    value={values.notes}
                    onChange={handleChange}
                    isInvalid={touched.notes && !!errors.notes}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  as={Col}
                  className="mb-3"
                  style={{ width: '330px' }}
                >
                  <Form.Label style={{ color: '#525335', fontSize: '22px' }}>
                    Tags:
                  </Form.Label>
                  <Form.Control
                    name="tags"
                    type="string"
                    value={values.tags}
                    onChange={handleChange}
                    isInvalid={touched.tags && !!errors.tags}
                  />
                  <Form.Label style={{ color: '#525335', fontSize: '12px' }}>
                    (separated by ',')
                  </Form.Label>
                </Form.Group>
                <Form.Group
                  as={Col}
                  className="mb-3"
                  style={{ width: '330px' }}
                >
                  <Form.Label style={{ color: '#525335', fontSize: '22px' }}>
                    Version:
                  </Form.Label>
                  <Form.Control
                    name="version"
                    type="string"
                    value={values.version}
                    onChange={handleChange}
                    isInvalid={touched.version && !!errors.version}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  as={Col}
                  className="mb-3"
                  style={{ width: '330px' }}
                >
                  <Form.Label style={{ color: '#525335', fontSize: '22px' }}>
                    Source:
                  </Form.Label>
                  <Form.Control
                    name="url"
                    type="string"
                    value={values.url}
                    onChange={handleChange}
                    isInvalid={touched.url && !!errors.url}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Label style={{ color: '#525335', fontSize: '22px' }}>
                  Author
                </Form.Label>
                <Form.Group
                  as={Col}
                  className="mb-3"
                  style={{ width: '330px' }}
                >
                  <Form.Label style={{ color: '#525335', fontSize: '18px' }}>
                    Name:
                  </Form.Label>
                  <Form.Control
                    name="author"
                    type="string"
                    value={values.author}
                    onChange={handleChange}
                    isInvalid={touched.author && !!errors.author}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  className="mb-3"
                  style={{ width: '330px' }}
                >
                  <Form.Label style={{ color: '#525335', fontSize: '18px' }}>
                    Email:
                  </Form.Label>
                  <Form.Control
                    name="author_email"
                    type="string"
                    value={values.author_email}
                    onChange={handleChange}
                    isInvalid={touched.author_email && !!errors.author_email}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Label style={{ color: '#525335', fontSize: '22px' }}>
                  Maintainer
                </Form.Label>
                <Form.Group
                  as={Col}
                  className="mb-3"
                  style={{ width: '330px' }}
                >
                  <Form.Label style={{ color: '#525335', fontSize: '18px' }}>
                    Name:
                  </Form.Label>
                  <Form.Control
                    name="maintainer"
                    type="string"
                    value={values.maintainer}
                    onChange={handleChange}
                    isInvalid={touched.maintainer && !!errors.maintainer}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  className="mb-3"
                  style={{ width: '330px' }}
                >
                  <Form.Label style={{ color: '#525335', fontSize: '18px' }}>
                    Email:
                  </Form.Label>
                  <Form.Control
                    name="maintainer_email"
                    type="string"
                    value={values.maintainer_email}
                    onChange={handleChange}
                    isInvalid={
                      touched.maintainer_email && !!errors.maintainer_email
                    }
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  as={Col}
                  className="mb-3"
                  style={{ width: '330px' }}
                >
                  <Form.Label style={{ color: '#525335', fontSize: '22px' }}>
                    License
                  </Form.Label>
                  <Form.Select name="license" type="string">
                    <option value="Creative Commons Attribution (CC BY)">
                      Creative Commons Attribution (CC BY)
                    </option>

                    <option
                      value="Open Data Commons Public Domain Dedication and License
                      (PDDL)"
                    >
                      Open Data Commons Public Domain Dedication and License
                      (PDDL)
                    </option>
                    <option value="Open Data Commons Open Database License (ODbL)">
                      Open Data Commons Open Database License (ODbL)
                    </option>
                    <option value="Open Data Commons Attribution License">
                      Open Data Commons Attribution License
                    </option>
                    <option value="Creative Commons CCZero (CC0)">
                      Creative Commons CCZero (CC0)
                    </option>
                    <option value="Creative Commons Attribution Share-Alike (CC BY-SA)">
                      Creative Commons Attribution Share-Alike (CC BY-SA)
                    </option>
                    <option value="GNU Free Document License">
                      GNU Free Document License
                    </option>
                    <option value="License Creative Commons Non-Commercial Other">
                      License Creative Commons Non-Commercial Other
                    </option>
                    <option value="Other (Open)">Other (Open)</option>
                    <option value="Other (Public Domain) Other (Attribution) Open Government">
                      Other (Public Domain) Other (Attribution) Open Government
                    </option>
                    <option value="(Non-Commercial) Other (Closed)">
                      (Non-Commercial) Other (Closed)
                    </option>
                    <option value="Not Specified">Not Specified</option>
                  </Form.Select>
                  <Form.Label style={{ fontSize: '12px' }}>
                    License definitions and additional information can be found
                    at{' '}
                    <a href="https://opendefinition.org/">opendefinition.org</a>
                  </Form.Label>
                </Form.Group>
              </Row>
              <Row>
                <Form.Label style={{ color: '#525335', fontSize: '22px' }}>
                  Custom Fields
                </Form.Label>
                <AddEditCustomFields
                  values={values.customFields}
                  setFieldValue={setFieldValue}
                />
              </Row>

              <Button
                style={{
                  backgroundColor: '#CA7E15',
                  fontWeight: 'bolder',
                  fontSize: '14px',
                  justifyContent: 'end',
                  width: '100px',
                  alignSelf: 'end',
                }}
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
};
export default AddEditDataForm;
