import React from 'react';
import { useState, useEffect } from 'react';
import { FilterOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Modal,
  Typography,
  Alert,
  Avatar,
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Slider,
  Col,
  Row,
} from 'antd';
import OlSourceTileWMS from 'ol/source/TileWMS';
import OlLayerTile from 'ol/layer/Tile';
import 'antd/dist/antd.css';
import './query.css';
import { Tooltip, notification, Dropdown, Space } from 'antd';
import {
  LoginOutlined,
  CheckCircleOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { UserContext } from '../UserContext';
import { filter } from 'lodash';
import WMSGetFeatureInfo from 'ol/format/WMSGetFeatureInfo';
import TextTruncate from 'react-text-truncate';
import { Button } from 'react-bootstrap';

const { RangePicker } = DatePicker;

const { Text, Title } = Typography;

const FilterModal = (props) => {
  const [hover, toggleHover] = useState();
  const [visible, setVisible] = useState();
  const [units, setUnits] = useState(null);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(false);

  const [notUsedCols, setNotUsedCols] = useState([
    'geom',
    'wkb_geometry',
    'objectid',
    'shape_length',
    'shape_area',
    'aland',
    'awater',
    'website',
    'lastupdated',
    'name',
    'acres',
    'statefp',
    'intptlat',
    'intptlon',
    'countyfp',
    'countyns',
    'geoid',
    'lsad',
    'classfp',
    'mtfcc',
    'csafp',
    'cbsafp',
    'metdivfp',
    'funcstat',
    'macsid',
    'notes',
    'address',
    'phone',
    'activity_end',
    'activity_uom',
    'fid_spaghetti2020',
    'footprintacres',
    'point_count',
    'polygonarea',
  ]);

  const numberType = [
    'int',
    'double',
    'integer',
    'number',
    'esrifieldtypedouble',
  ];

  const translateArcGISToGeneralFields = (fields) => {
    let final = [];
    fields.forEach((e) => {
      final.push({
        name: e.modelName,
        type: e.type,
        label: e.alias,
        coded_values:
          e.domain !== null
            ? e.domain.codedValues.map((item) => {
                return {
                  value: item.code,
                  label: item.name,
                };
              })
            : [],
      });
    });

    return final;
  };

  const translateWmsToGeneralFields = (fields) => {
    let final = [];
    fields.forEach((e) => {
      final.push({
        name: e.name,
        type: e.localType,
        label: e.name,
        coded_values:
          e.coded_values != undefined && e.coded_values.length > 0
            ? e.coded_values.map((item) => {
                return {
                  value: item,
                  label: item,
                };
              })
            : undefined,
      });
    });
    return final;
  };

  const getSetFilterOptions = async (gis_service, key, item) => {
    if (gis_service.service_type === 'ArcGIS') {
      //get attributes
      let information = await fetch(`${gis_service.service_url}?f=pjson`).then(
        (response) => {
          return response.json();
        }
      );

      let filterItems = { ...props.filterItems };
      let prevFilter = filterItems[key];
      let fields = translateArcGISToGeneralFields(information.fields);
      let options = createOptions(fields, prevFilter);

      let filter_options = { ...props.filter_options };
      filter_options[key] = options;
      props.setFilterOptions(filter_options);
    } else if (
      gis_service.data_type === 'vector' &&
      gis_service.service_type === 'GeoServer'
    ) {
      //get attribute table
      let url = `${gis_service.service_url}?SERVICE=WFS&version=1.3.0&request=DescribeFeatureType&typeNames=${gis_service.layer_name}&outputFormat=application/json`;
      let response = await fetch(url);
      let data = await response.json();
      let features = data.featureTypes[0].properties;
      let complete_features = [];
      const promises = features
        .filter((e) => !notUsedCols.includes(e.name)) // Filter features before processing
        .filter((e) => !numberType.includes(e.type.toLowerCase())) // Filter by type
        .map(async (e) => {
          let feature_url = `https://sparcal.sdsc.edu/geoserver/wfs?service=wfs&version=2.0.0&request=GetFeature&typeNames=${gis_service.layer_name}&propertyName=${e.name}`;
          let response = await fetch(feature_url);
          let text_xml = await response.text();
          const xmlDoc = await new window.DOMParser().parseFromString(
            text_xml,
            'text/xml'
          );
          let namespace = gis_service.layer_name.split(':')[0];

          let element_val = xmlDoc.getElementsByTagName(
            namespace + ':' + e.name
          );
          let element_list = Array.from(element_val);

          let values = element_list.map((val) => val.textContent);
          let unique = [...new Set(values)];

          unique.sort();
          e.coded_values = unique;
          return e; // Return the updated feature
        });

      // Wait for all promises to resolve
      const results = await Promise.all(promises);
      complete_features.push(...results);

      let filterItems = { ...props.filterItems };
      let prevFilter = filterItems[key];

      let fields = translateWmsToGeneralFields(complete_features);
      let options = createOptions(fields, prevFilter);

      let filter_options = { ...props.filter_options };
      filter_options[key] = options;
      props.setFilterOptions(filter_options);
    } else {
      let min_max = undefined;
      let prevFilter = props.filterItems[key];

      // look to color map for min/max values of raster
      if (prevFilter == undefined) {
        let color_map = gis_service.color_map;

        if (color_map !== undefined && color_map !== null) {
          let color_entries = color_map.color_map_entries.map(
            (e) => e.float_value
          );
          min_max = {
            values: [Math.min(...color_entries), Math.max(...color_entries)],
          };
        } else {
          let values = [
            gis_service.dataset_collections[0].minimum_value,
            gis_service.dataset_collections[0].maximum_value,
          ];
          min_max = {
            values: [Math.min(...values), Math.max(...values)],
          };
        }
      } else {
        min_max = prevFilter;
      }

      let var_name = 'values';
      if (props.item.dataset_metadata) {
        let units = props.item.dataset_metadata.filter((e) =>
          e.name.toLowerCase().includes('units')
        );
        if (units.length > 0) {
          setUnits(units[0].text_value);
        }
      } else {
        let units = props.item.extras.find((obj) => {
          if (obj.key === 'data_units') {
            return obj.value;
          }
        });
        if (units) {
          setUnits(units.value);
        }
      }

      if (min_max != undefined && var_name in min_max) {
        let fields = [
          {
            type: 'esriFieldTypeDouble',
            label: 'Start Value',
            name: var_name + '_min',
            value: min_max[var_name][0],
            coded_values: [],
          },
          {
            type: 'esriFieldTypeDouble',
            label: 'End Value',
            name: var_name + '_max',
            value: min_max[var_name][1],
            coded_values: [],
          },
        ];

        let filter_options = { ...props.filter_options };
        filter_options[key] = fields;
        props.setFilterOptions(filter_options);
      }
    }
  };

  const createOptions = (fields, prevFilter) => {
    let final = [];
    fields.forEach((e) => {
      // if the field is a number, add min and max values for range
      if (numberType.includes(e.type.toLowerCase())) {
        final.push({
          name: e.name + '_min',
          type: e.type,
          label: e.label,
          placeholder: 'Start Value',
          value:
            prevFilter !== undefined && prevFilter[e.name] !== undefined
              ? prevFilter[e.name][0]
              : undefined,
          // coded_values: e.coded_values
          coded_values: undefined,
        });

        final.push({
          name: e.name + '_max',
          type: e.type,
          label: '</br>',
          placeholder: 'End Value',
          value:
            prevFilter !== undefined && prevFilter[e.name] !== undefined
              ? prevFilter[e.name][1]
              : undefined,
          coded_values: undefined,
          // coded_values: e.coded_values
        });
      } else {
        final.push({
          name: e.name,
          type: e.type,
          label: e.label,
          value:
            prevFilter !== undefined && prevFilter[e.name] !== undefined
              ? prevFilter[e.name]
              : undefined,
          coded_values: e.coded_values,
        });
      }
    });
    return final;
  };

  const showModal = async () => {
    setVisible(true);
    setLoading(true);

    let gis_service = props.getGisService(props.item);
    let key = props.getItemKey(props.item);
    await getSetFilterOptions(gis_service, key, props.item);

    setLoading(false);
  };

  const saveFilter = (values) => {
    let key = props.getItemKey(props.item);

    //filter for double variables are in two seperate fields (name_min, name_max), they need to be brought together
    props.filter_options[key]
      .filter(
        (e) =>
          numberType.includes(e.type.toLowerCase()) &&
          values[e.name] !== undefined
      )
      .forEach((e) => {
        let is_min = e.name.endsWith('_min') ? true : false;
        let original_name = e.name.slice(0, -4);

        if (original_name in values === false) {
          values[original_name] = [0, 0];
        }

        values[original_name][is_min ? 0 : 1] = Number(values[e.name]);

        delete values[e.name];
      });

    // props.filter_options.filter(e => (e.type.toLowerCase() == 'string')
    //     && values[e.name] !== undefined
    //     && values[e.name] )
    //     .forEach(e => {
    //         let value_list = values[e.name].join(',')
    //         values[e.name] = value_list
    //     })

    let itemKey = props.getItemKey(props.item);
    let filterItems = { ...props.filterItems };
    filterItems[itemKey] = values;
    props.setFilterItems(filterItems);

    setVisible(false);
    setUnits(null);
  };

  let rootStyle = props.style;
  if (rootStyle === undefined) {
    rootStyle = {
      position: 'absolute',
      // top: '196px',
      // left: '7px',
      padding: '1px 4px 1px 4px',
      borderRadius: '6px',
      backgroundColor: hover ? '#61633f' : '#9C9D86',
      border: '3px solid rgba(255, 255, 255, 0.72)',
    };
  }

  let disabled_style = {
    position: 'absolute',
    // top: '196px',
    // left: '7px',
    padding: '1px 4px 1px 4px',
    borderRadius: '6px',
    border: '3px solid rgba(255, 255, 255, 0.72)',
  };

  let label_dic = {
    objectid: 'Unique Identifier',
    countyfp: 'FIPS code',
    countyns: 'GNIS code',
    geoid: 'County Identifier',
    namelsad: 'County Name',
    lsad: 'Legal/statistical Code',
    classfp: 'FIPS class code',
    mtfcc: 'MAF/TIGER feature class code',
    csafp: 'Combined statistical area code',
    cbsafp: 'Metropolitan area code',
    metdivfp: 'Metropolitan division code',
    funcstat: 'Functional status',
    aland: 'Land area',
    awater: 'Water area',
    intptlat: 'Latitude',
    intptlon: 'Longitude',
    county: 'County Name',
    fdid: 'Fire Department Identification Number',
    macsid: 'MACS ID',
    firechief: 'Fire Chief',
    notes: 'Extra Notes',
    zip: 'Zip Code',
    address: 'Address',
    city: 'City',
    phone: 'Phone Number',
    calfireunit: 'CALFIRE Unit',
    rrk_region: 'RRK Region',
    primary_ownership_group: 'Primary Ownership Group',
    broad_vegetation_type: 'Broad Vegetation Type',
    region: 'Region',
    wui: 'Wui',
    year_txt: 'Year',
    agency: 'Agency',
    administering_org: 'Administering Org',
    activity_description: 'Activity Description',
    activity_cat: 'Activity Category',
    activity_status: 'Activity Status',
    activity_quantity: 'Activity Quantity',
    entity_type: 'Entity Type',
  };

  const getLabel = (label) => {
    return label_dic[label] ? label_dic[label] : label;
  };

  let itemKey = props.getItemKey(props.item);
  return (
    <div>
      {/* {Object.keys(props.filter_options).includes(itemKey) ? */}
      <div>
        <div
          style={rootStyle}
          onMouseEnter={() => toggleHover(true)}
          onMouseLeave={() => toggleHover(false)}
        >
          <Tooltip title={'Filter Map'} placement="top">
            <FilterOutlined onClick={showModal} style={{ color: 'white' }} />
          </Tooltip>
        </div>
        {/* TODO fix name display for ckan data */}
        <Modal
          title={'Filter - ' + props.item.title}
          open={visible}
          onCancel={() => setVisible(false)}
          destroyOnClose={true}
          footer={null}
          width={800}
        >
          {loading ? (
            <div style={{ textAlign: 'center' }}>
              <LoadingOutlined></LoadingOutlined> Loading Filters
            </div>
          ) : (
            <div>
              <Form
                name="filter_modal"
                layout="vertical"
                onFinish={saveFilter}
                preserve={true}
                autoComplete="off"
                fields={props.filter_options[itemKey]}
                scrollToFirstError="true"
              >
                <Row>
                  {Object.keys(props.filter_options).includes(itemKey)
                    ? props.filter_options[itemKey].map((option, i) => {
                        return option.coded_values &&
                          option.coded_values.length > 0 ? (
                          <Col
                            key={props?.item?.name + i}
                            span={12}
                            style={{ paddingRight: 20 }}
                          >
                            <Form.Item
                              name={option.name}
                              label={
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: getLabel(option.label),
                                  }}
                                ></span>
                              }
                              id={option.name}
                            >
                              <Select
                                mode="multiple"
                                allowClear
                                placeholder="- SELECT -"
                                options={option.coded_values}
                              />
                            </Form.Item>
                          </Col>
                        ) : option.type.toLowerCase().includes('date') ? (
                          <Col
                            key={props?.item?.name + i}
                            span={12}
                            style={{ paddingRight: 20 }}
                          >
                            <Form.Item
                              name={option.name}
                              label={
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: getLabel(option.label),
                                  }}
                                ></span>
                              }
                            >
                              <RangePicker />
                            </Form.Item>
                          </Col>
                        ) : numberType.includes(option.type.toLowerCase()) ? (
                          <Col
                            key={props?.item?.name + i}
                            span={6}
                            style={{ paddingRight: 20 }}
                          >
                            <Row>
                              <Col span={24} style={{ paddingBottom: 8 }}>
                                {
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: getLabel(option.label),
                                    }}
                                  ></span>
                                }
                              </Col>
                              <Col span={24}>
                                <Form.Item name={option.name}>
                                  <InputNumber
                                    style={{ width: '90%' }}
                                    placeholder={option.placeholder}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        ) : option.type.toLowerCase().includes('string') ? (
                          <Col
                            key={props?.item?.name + i}
                            span={12}
                            style={{ paddingRight: 20 }}
                          >
                            <Form.Item
                              name={option.name}
                              label={
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: getLabel(option.label),
                                  }}
                                ></span>
                              }
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        ) : null;
                      })
                    : []}
                </Row>
                {Object.keys(props.filter_options).includes(itemKey) ? (
                  <div>
                    <Col span={24}>
                      <div style={{ fontSize: 10 }}>
                        *All filter value ranges are inclusive.
                      </div>
                    </Col>
                    <Col
                      span={24}
                      align="end"
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          backgroundColor: '#61633f',
                          border: 'none',
                          width: '80px',
                        }}
                      >
                        Filter
                      </Button>
                    </Col>
                  </div>
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    Filter is unavailable for this dataset
                  </div>
                )}
              </Form>
              {/* TODO implement units with ckan raster data */}
              {units != null ? (
                <div style={{ paddingBottom: 10 }}>
                  <hr
                    style={{
                      borderTop: 'dashed 1px',
                      color: 'lightgrey',
                      marginTop: '15px',
                    }}
                  />
                  <div>
                    Units:{' '}
                    {view ? (
                      <span>{units}</span>
                    ) : (
                      <TextTruncate
                        line={2}
                        element="span"
                        truncateText="…"
                        text={units}
                        textTruncateChild={
                          <Button
                            variant="link"
                            onClick={() => {
                              setView(!view);
                            }}
                            style={{ padding: 0, color: 'green' }}
                          >
                            {view ? 'Show Less' : 'Show More'}
                          </Button>
                        }
                      />
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default FilterModal;
