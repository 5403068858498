export const BoundaryData = {
  'California Counties': [
    'Alameda County',
    'Alpine County',
    'Amador County',
    'Butte County',
    'Calaveras County',
    'Colusa County',
    'Contra Costa County',
    'Del Norte County',
    'El Dorado County',
    'Fresno County',
    'Glenn County',
    'Humboldt County',
    'Imperial County',
    'Inyo County',
    'Kern County',
    'Kings County',
    'Lake County',
    'Lassen County',
    'Los Angeles County',
    'Madera County',
    'Marin County',
    'Mariposa County',
    'Mendocino County',
    'Merced County',
    'Modoc County',
    'Mono County',
    'Monterey County',
    'Napa County',
    'Nevada County',
    'Orange County',
    'Placer County',
    'Plumas County',
    'Riverside County',
    'Sacramento County',
    'San Benito County',
    'San Bernardino County',
    'San Diego County',
    'San Francisco County',
    'San Joaquin County',
    'San Luis Obispo County',
    'San Mateo County',
    'Santa Barbara County',
    'Santa Clara County',
    'Santa Cruz County',
    'Shasta County',
    'Sierra County',
    'Siskiyou County',
    'Solano County',
    'Sonoma County',
    'Stanislaus County',
    'Sutter County',
    'Tehama County',
    'Trinity County',
    'Tulare County',
    'Tuolumne County',
    'Ventura County',
    'Yolo County',
    'Yuba County',
  ],
  'California Local Fire Districts': [
    'ADELANTO FD',
    'ADIN FPD',
    'ALAMEDA COUNTY FD',
    'ALAMEDA FD',
    'ALBION LITTLE RIVER FPD',
    'ALHAMBRA FD',
    'ALPINE FPD',
    'ALPINE SPRINGS COUNTY WATER DISTRICT',
    'ALTA FPD',
    'ALTAVILLE‐MELONES FPD',
    'ALTURAS CITY FD',
    'ALTURAS RURAL FD',
    'AMADOR FPD',
    'AMERICAN CANYON FPD',
    'ANAHEIM FD',
    'ANDERSON FPD',
    'ANDERSON VALLEY FD',
    'ANTELOPE VALLEY FPD',
    'APPLE VALLEY FPD',
    'ARBUCKLE COLLEGE CITY FPD',
    'ARCADIA FD',
    'ARCATA FPD',
    'AROMAS TRI COUNTY FPD',
    'ARROWBEAR LAKE CWD',
    'ARTOIS FPD',
    'ATASCADERO FD',
    'ATWATER FD',
    'AUBERRY VFD',
    'AUBURN VFD',
    'AVILA BEACH FPD',
    'BAKERSFIELD FD',
    'BALD MOUNTAIN VFD',
    'BARONA FD',
    'BARSTOW FPD',
    'BEAR VALLEY FIRE',
    'BEAR VALLEY-INDIAN VALLEY',
    'BEAR VLY INDIAN VLY FPD',
    'BECKWOURTH FD',
    'BELVEDERE FIRE',
    'BEN LOMOND FPD',
    'BENICIA FD',
    'BERKELEY FD',
    'BEVERLY HILLS FD',
    'BICKFORD RANCH FIRE (CFD 2021-2)',
    'BIG BEAR FIRE AUTHORITY',
    'BIG PINE FPD',
    'BIG SUR FIRE',
    'BIG VALLEY FPD',
    'BIGGS FD',
    'BISHOP FD',
    'BLUE LAKE VFD',
    'BLYTHE FD',
    'BOGGS TRACT FPD',
    'BOLINAS FPD',
    'BONITA SUNNYSIDE FPD',
    'BORREGO SPRINGS FPD',
    'BOULDER CREEK FPD',
    'BRANCIFORTE FPD',
    'BRAWLEY FD',
    'BREA FD',
    'BRICELAND VFD',
    'BRIDGEPORT FPD',
    'BRIDGEVILLE VFD',
    'BROOKTRAILS TOWNSHIP CSD FD',
    'BUCKEYE FPD',
    'BURBANK FD',
    'BURBANK PARADISE FPD',
    'BURNEY FPD',
    'BUTTE CFD',
    'BUTTE VALLEY FPD',
    'C ROAD FD',
    'CACHAGUA FPD',
    'CAL FIRE/USFS',
    'CALAVERAS CONSOLIDATED FPD',
    'CALEXICO FD',
    'CALIFORNIA CITY FD',
    'CALIFORNIA CORRECTIONAL CENTER',
    'CALIMESA FD',
    'CALIPATRIA FD',
    'CALPINES CSD',
    'CAMARILLO CITY FD',
    'CAMBRIA CSD FD',
    'CAMERON PARK FD',
    'CAMP PARKS FIRE DEPT',
    'CAMP PENDLETON',
    'CAMPO RESERVATION',
    'CANBY FPD',
    'CANYON LAKE FD',
    'CAPAY VALLEY FPD',
    'CAPAY VFD',
    'CAPITAN GRANDE RESERVATION',
    'CARLOTTA CSD',
    'CARLSBAD FD',
    'CARMEL HIGHLANDS FPD',
    'CARPINTERIA SUMMERLAND FPD',
    'CASTELLA FD',
    'CATHEDRAL CITY FD',
    'CAZADERO CSD',
    'CDF‐COLUSA COUNTY',
    'CDF‐FRESNO COUNTY',
    'CDF‐GLENN COUNTY',
    'CDF‐LAKE COUNTY',
    'CDF‐NAPA COUNTY',
    'CDF‐SAN BENITO COUNTY',
    'CDF‐SAN JOAQUIN UNPROTECT AREA',
    'CDF‐SANTA CLARA COUNTY',
    'CDF‐SHASTA COUNTY',
    'CDF‐SISKIYOU COUNTY',
    'CDF‐SOLANO COUNTY',
    'CDF‐TEHAMA COUNTY',
    'CDF‐TULARE COUNTY',
    'CEDARVILLE FPD',
    'CENTRAL CALAVERAS FIRE/RESCUE',
    'CENTRAL COUNTY FD',
    'CENTRAL FPD',
    'CERES CITY FIRE DEPARTMENT',
    'CERES FIRE PROTECTION DISTRICT',
    'CHALFANT FD',
    'CHESTER FPD',
    'CHINO VALLEY FD',
    'CHOWCHILLA VFD',
    'CHULA VISTA FD',
    'CITY OF ALBANY FD',
    'CITY OF ANGELS FD',
    'CITY OF AVALON FD',
    'CITY OF CHICO FD',
    'CITY OF DAVIS FIRE DEPARTMENT',
    'CITY OF FOLSOM FD',
    'CITY OF LONG BEACH FD',
    'CITY OF MERCED FD',
    'CITY OF NAPA FD',
    'CITY OF RIVERSIDE FD',
    'CITY OF UKIAH',
    'CLARKSBURG FD',
    'CLEAR CREEK VFD',
    'CLEMENTS RURAL FPD',
    'CLOVERDALE FD',
    'CLOVIS FD',
    'COALINGA FD',
    'COASTSIDE FIRE DISTRICT',
    'COFFEE CREEK VOLUNTEER FD',
    'COLFAX FD',
    'COLLEGEVILLE FD',
    'COLMA FPD',
    'COLTON FD',
    'COLUMBIA FPD',
    'COMPTCHE VFD',
    'COMPTON FD',
    'CONTRA COSTA CO FPD',
    'COPCO LAKE FPD',
    'COPPEROPOLIS FPD',
    'CORDELIA FD',
    'CORNING VFD',
    'CORONA FD',
    'CORONADO FD',
    'CORTE MADERA FIRE DEPARTMENT',
    'COSTA MESA FD',
    'COSUMNES CSD FD',
    'COTTONWOOD FPD',
    'COUNTRY CLUB',
    'COURTLAND FPD',
    'COVELO FPD',
    'CRESCENT FPD',
    'CRESCENT MILLS FPD',
    'CROCKETT CARQUINEZ FPD',
    'CSA #9 FPD',
    'CULVER CITY FD',
    'CUYAPAIPE RESERVATION',
    'CYPRESS FPD',
    'DAGGETT FD',
    'DAVIS CREEK VFD',
    'DEER SPRINGS FPD',
    'DEL MAR FD',
    'DELTA FPD',
    'DENAIR FPD',
    'DIAMOND SPRINGS ‐ EL DORADO FPD',
    'DINUBA FD',
    'DIXON FD',
    'DIXON FIRE PROTECTION DISTRICT',
    'DOBBINS OREGON HOUSE FPD',
    'DORRIS VFD',
    'DOUGLAS CITY VFD',
    'DOWNEY FD',
    'DOWNIEVILLE FPD',
    'DOYLE FPD',
    'DRY CREEK FIRE (CSA 28 ZONE 165)',
    'DRY CREEK RANCHERIA FD',
    'DUNNIGAN FPD',
    'DUNSMUIR FD',
    'DUTCH FLAT FIRE (CSA 28 ZONE 150)',
    'EAGLEVILLE FPD',
    'EAST CONTRA COSTA FPD',
    'EAST DAVIS FPD',
    'EAST VALLEJO FIRE DISTRICT',
    'EASTERN ALPINE CO. FD',
    'EASTERN PLUMAS RURAL FPD',
    'EASTSIDE',
    'EBBETTS PASS FPD',
    'EL CENTRO FD',
    'EL CERRITO FD',
    'EL DORADO CO FPD',
    'EL DORADO HILLS FD',
    'EL MEDIO FPD',
    'EL SEGUNDO FD',
    'ELK CREEK FPD',
    'ELK VFD',
    'ELKHORN VFD',
    'ENCINITAS FPD',
    'ESCALON CONSOLIDATED FPD',
    'ESCONDIDO FD',
    'ESPARTO FPD',
    'ETNA FD',
    'EXETER FD',
    'FAIRFIELD FD',
    'FAIRVIEW FIRE PROTECTION DISTRICT',
    'FALL RIVER VALLEY FPD',
    'FALLEN LEAF FD',
    'FARMERSVILLE FD',
    'FARMINGTON RURAL FPD',
    'FELTON FPD',
    'FERNDALE FPD',
    'FIELDBROOK FD',
    'FILLMORE FD',
    'FIREBAUGH CITY FD',
    'FIVE CITIES FIRE AUTHORITY',
    'FONTANA FPD',
    'FOOTHILL VOLUNTEER FD',
    'FORESTHILL FPD',
    'FORT BIDWELL VFD',
    'FORT BRAGG FD',
    'FORT BRAGG RURAL FPD',
    'FORT DICK FPD',
    'FORT JONES FD',
    'FORTUNA FPD',
    'FOUNTAIN VALLEY FD',
    'FREMONT FD',
    'FRENCH CAMP MCKINLEY RURAL FPD',
    'FRESNO FD',
    'FRUITLAND VFC',
    'FULLERTON FD',
    'GARBERVILLE FPD',
    'GARDEN VALLEY FPD',
    'GASQUET FPD',
    'GAZELLE FD',
    'GEORGETOWN FPD',
    'GILROY FD',
    'GLENDALE FD',
    'GLENN - COLUSA',
    'GLENN-COLUSA',
    'GLENN‐CODORA FPD',
    'GOLD MOUNTAIN CSD',
    'GOLD RIDGE FPD',
    'GONZALES VFD',
    'GRAEAGLE FPD',
    'GRASS VALLEY FD',
    'GRATON FPD',
    'GREENFIELD FD',
    'GREENHORN CREEK VFD',
    'GRENADA FPD',
    'GRIDLEY FD',
    'GROVELAND FD',
    'GUADALUPE FD',
    'HALLWOOD COMM SERVICE DISTRICT',
    'HAMILTON BRANCH FPD',
    'HAMILTON CITY FD',
    'HAMILTON-BAYLISS',
    'HAMMOND RANCH FIRE',
    'HAPPY CAMP FPD',
    'HAPPY VALLEY FPD',
    'HAWTHORNE-CITY FIRE DEPT',
    'HAYFORK FD',
    'HAYWARD FD',
    'HEALDSBURG FD',
    'HEARTLAND FIRE & RESCUE',
    'HEBER',
    'HEMET FD',
    'HERALD FPD',
    'HERLONG VFD',
    'HIGGINS FPD',
    'HIGHLAND FD',
    'HILT',
    'HOLTVILLE FD',
    'HORNBROOK FPD',
    'HUGHSON FPD',
    'HUMBOLDT BAY FIRE JPA',
    'HUMBOLDT CSA #4',
    'HUNTINGTON BEACH FD',
    'HYAMPOM FD',
    'IDYLLWILD FPD',
    'IMPERIAL',
    'IMPERIAL BEACH FD',
    'IMPERIAL COUNTY FD',
    'INAJA AND COSMIT RESERVATION',
    'INDEPENDENCE FPD',
    'INDIAN VALLEY FIRE AND RESCUE',
    'INDUSTRIAL FIRE PROTECTION DISTRICT',
    'INVERNESS VFD',
    'IONE FD',
    'ISLETON FD',
    'JACKSON FD',
    'JACKSON VALLEY FPD',
    'JAMESTOWN FPD',
    'JAMUL INDIAN VILLAGE',
    'JANESVILLE FPD',
    'JUNCTION CITY FIRE PROTECTION DISTRICT',
    'JUNE LAKE FPD',
    'KANAWHA FPD',
    'KELSEYVILLE FPD',
    'KENSINGTON FIRE PROTECTION DISTRICT',
    'KENTFIELD FPD',
    'KENWOOD FPD',
    'KERN CO FD',
    'KEYES FPD',
    'KING CITY FD',
    'KINGS COUNTY FD',
    'KINGSBURG FD',
    'KIRKWOOD MEADOWS P.U.D.',
    'KIRKWOOD VFD',
    'KLAMATH FIRE PROTECTION DISTRICT',
    'KLAMATH RIVER HC',
    'KNEELAND FPD',
    'KNIGHTS LANDING VFD',
    'LA HABRA HEIGHTS VFD',
    'LA JOLLA RESERVATION FD',
    'LA PORTE FPD',
    'LA POSTA RESERVATION',
    'LA VERNE FD',
    'LAGUNA BEACH FD',
    'LAKE CITY FPD',
    'LAKE FOREST FPD',
    'LAKE PILLSBURY FPD',
    'LAKE SHASTINA FD',
    'LAKE VALLEY FPD',
    'LAKEPORT FPD',
    'LAKESIDE FPD',
    'LARKSPUR FIRE DEPARTMENT',
    'LATHROP‐MANTECA FPD',
    'LATON VFD',
    'LEE VINING VFD',
    'LEGGETT VALLEY VOL FD',
    'LEWISTON FD',
    'LIBERTY RURAL FPD',
    'LIKELY FPD',
    'LINCOLN',
    'LINCOLN FD',
    'LINDA FPD',
    'LINDEN PETERS RURAL FPD',
    'LINDSAY DEPT OF PUBLIC SAFETY',
    'LITTLE LAKE FPD',
    'LITTLE VALLEY CSD',
    'LIVERMORE‐ PLEASANTON FD',
    'LOCKWOOD FPD',
    'LODI FD',
    'LOLETA FPD',
    'LOMA LINDA FD',
    'LOMA RICA BROWNS VALLEY CSD',
    'LOMPOC FD',
    'LONE PINE VFD',
    'LONG VALLEY FD',
    'LONG VALLEY FPD',
    'LOOKOUT FPD',
    'LOS ANGELES CITY FD',
    'LOS ANGELES CO FD',
    'LOS COYOTES RESERVATION',
    'LOS OSOS CSD',
    'LOWER SWEETWATER FPD',
    'LOYALTON FD',
    'MADELINE FPD',
    'MADERA CO FD',
    'MADERA FD',
    'MADISON FPD',
    'MAMMOTH LAKES FPD',
    'MANHATTAN BEACH FD',
    'MANTECA FD',
    'MANZANITA RESERVATION',
    'MARCH AIR RESERVE BASE FD',
    'MARIN COUNTY FD',
    'MARINA FIRE DEPT',
    'MARINWOOD FD',
    'MARIPOSA CFD',
    'MARIPOSA PUD',
    'MAXWELL FPD',
    'MAYTEN FIRE DISTRICT',
    'MCCLOUD FD',
    'MCCLOUD FIRE ZONE',
    'MEADOW VALLEY FPD',
    'MEEKS BAY FPD',
    'MENDOCINO FIRE PROTECTION DISTRICT',
    'MENLO PARK FPD',
    'MERCED CO FD',
    'MERIDIAN FD',
    'MESA GRANDE RESERVATION',
    'MI WUK SUGAR PINE FPD',
    'MID COAST FIRE BRIGADE',
    'MILFORD FIRE DISTRICT',
    'MILL VALLEY FD',
    'MILLVILLE FPD',
    'MILPITAS FD',
    'MIRANDA VFD',
    'MISSION SOLEDAD RURAL FPD',
    'MODESTO FD',
    'MOKELUMNE HILL FPD',
    'MOKELUMNE RURAL FD',
    'MONO CITY FPD',
    'MONROVIA FRE & RESCUE',
    'MONTAGUE FPD',
    'MONTCLAIR FD',
    'MONTE RIO FPD',
    'MONTEBELLO FD',
    'MONTECITO FPD',
    'MONTEREY COUNTY REGIONAL FD',
    'MONTEREY FD',
    'MONTEREY PARK FD',
    'MONTEZUMA FPD',
    'MOORPARK CITY FD',
    'MORAGA ORINDA FPD',
    'MORGAN HILL FD',
    'MORONGO RESERVATION FD',
    'MORONGO VALLEY CSD',
    'MORRO BAY FD',
    'MOSQUITO FPD',
    'MOUNT SHASTA VISTA FC',
    'MOUNTAIN GATE VFD',
    'MOUNTAIN HOUSE CSD FD',
    'MOUNTAIN VIEW FD',
    'MOUNTAIN VIEW FPD',
    'MT SHASTA CITY FD',
    'MT SHASTA FPD',
    'MURPHYS FPD',
    'MURRIETA FIRE & RESCUE',
    'MYERS FLAT FPD',
    'NAPA CITY AND COUNTY AUTO AID AGREEMENT',
    'NAPA STATE HOSPITAL FD',
    'NASA AMES RESEARCH CENTER FD',
    'NATIONAL CITY FD',
    'NATOMAS',
    'NEVADA COUNTY CONS FD',
    'NEWBERRY SPRINGS VFD',
    'NEWCASTLE FPD',
    'NEWMAN FD',
    'NEWPORT BEACH FD',
    'NILAND FD',
    'NO MANS LAND FPD',
    'NORTH AUBURN/OPHIR FIRE (CSA 28 ZONE 193)',
    'NORTH BAY FIRE',
    'NORTH CENTRAL FPD',
    'NORTH CO FIRE AUTHORITY',
    'NORTH COUNTY FPD',
    'NORTH SAN JUAN FPD',
    'NORTH SONOMA COAST FPD',
    'NORTH TAHOE FPD',
    'NORTHERN SONOMA COUNTY FPD',
    'NORTHSHORE FPD',
    'NORTHSTAR FD',
    'NOVATO FPD',
    'OAKDALE CITY FIRE DEPARTMENT',
    'OAKDALE RURAL FIRE PROTECTION DISTRICT',
    'OAKLAND FD',
    'OCCIDENTAL CSD',
    'OCEANSIDE FD',
    'OCOTILLO FD',
    'OJAI CITY FD',
    'OLANCHA‐CARTAGO FD',
    'OLIVEHURST FD',
    'OLYMPIC VALLEY FD',
    'ONTARIO FD',
    'OPHIR HILL FPD',
    'ORANGE COUNTY FIRE AUTHORITY',
    'ORANGE COVE FPD',
    'ORANGE FD',
    'ORD BEND FPD',
    'ORICK VFD',
    'ORLAND FPD',
    'OROVILLE FD',
    'OXNARD FD',
    'PACIFIC FRUITRIDGE FPD',
    'PAJARO DUNES CSA 4',
    'PAJARO VALLEY FPD',
    'PALA FD',
    'PALM SPRINGS FD',
    'PALO ALTO FD',
    'PALO VERDE',
    'PALO VERDE VFD',
    'PARADISE FIRE & RESCUE',
    'PARADISE FPD',
    'PASADENA FD',
    'PASO ROBLES DES',
    'PATTERSON CITY FD',
    'PAUMA RESERVATION FIRE',
    'PEARDALE ‐ CHICAGO PARK FPD',
    'PEBBLE BEACH CSD',
    'PECHANGA FD',
    'PECHANGA RESERVATION',
    'PENINSULA FPD',
    'PENN VALLEY FPD',
    'PENRYN FPD',
    'PETALUMA FD',
    'PETROLIA VFD',
    'PIEDMONT FD',
    'PIERCY FPD',
    'PINOLE FD',
    'PIONEER FPD',
    'PISMO BEACH FD',
    'PLACENTIA FLS',
    'PLACER COUNTY FIRE (CSA 28 ZONE 137)',
    'PLACER HILLS FPD',
    'PLEASANT VALLEY FC',
    'PLIOCENE RIDGE CSD',
    'PLUMAS BROPHY FPD',
    'PLUMAS EUREKA FD',
    'PLYMOUTH FD',
    'PORT HUENEME CITY FD',
    'PORTERVILLE FD',
    'PORTOLA FD',
    'POST MOUNTAIN VFD',
    'POTTER VALLEY CSD',
    'POWAY FD',
    'PRATTVILLE ALMANOR FD',
    'PRINCETON FPD',
    'PROTECTED BY CENTRAL CALAVERAS FIRE/RESCUE',
    'QUINCY FPD',
    'RANCHO ADOBE FPD',
    'RANCHO CUCAMONGA FD',
    'RANCHO SANTA FE FPD',
    'RED BLUFF FD',
    'REDDING FD',
    'REDLANDS FD',
    'REDONDO BEACH FD',
    'REDWAY FPD',
    'REDWOOD CITY FD',
    'REDWOOD COAST FPD',
    'REDWOOD VALLEY CALPELLA FPD',
    'REEDLEY FD',
    'RESCUE FPD',
    'RIALTO FD',
    'RICHMOND FD',
    'RINCON DEL DIABLO MWD',
    'RINCON FD',
    'RIO DELL FPD',
    'RIO VISTA FD',
    'RIPON FPD',
    'RIVER DELTA FIRE DISTRICT',
    'RIVERSIDE CO FD',
    'ROCKLIN FD',
    'RODEO HERCULES FD',
    'ROHNERT PARK DPS',
    'ROSEVILLE FD',
    'ROSS VALLEY FD',
    'ROUGH AND READY FPD',
    'RUBIDOUX COMM. SERVICE DISTRICT',
    'RUNNING SPRINGS CWD',
    'RUTH LAKE CSD',
    'S LAKE TAHOE FIRE RESCUE',
    'SACRAMENTO COUNTY AIRPORT FIRE',
    'SACRAMENTO FD',
    'SACRAMENTO METROPOLITAN FD',
    'SACRAMENTO METROPOLITAN FIRE DISTRICT',
    'SACRAMENTO RIVER FPD',
    'SALIDA FPD',
    'SALINAS FD',
    'SALMON RIVER',
    'SALTON CSD',
    'SALTON SEA BEACH',
    'SALYER VFD',
    'SAMOA PENINSULA VFD5',
    'SAN ANDREAS FPD',
    'SAN BERNARDINO COUNTY FIRE',
    'SAN BRUNO FD',
    'SAN BUENAVENTURA CITY FD',
    'SAN CARLOS/REDWOOD CITY FIRE DEPARTMENT',
    'SAN DIEGO COUNTY FPD',
    'SAN DIEGO FIRE AND RESCUE DEPT',
    'SAN FERNANDO-CITY FIRE DEPT',
    'SAN FRANCISCO FD',
    'SAN GABRIEL FD',
    'SAN JOSE FD',
    'SAN JUAN BAUTISTA/HOLLISTER FD',
    'SAN LUIS OBISPO CITY FD',
    'SAN LUIS OBISPO CO FD',
    'SAN MARCOS FD',
    'SAN MARINO-CITY FIRE DEPT',
    'SAN MATEO CO FD',
    'SAN MATEO CONSOLIDATED FD',
    'SAN MIGUEL CONSILIDATED FPD',
    'SAN MIGUEL CSD',
    'SAN PASQUAL RESERVATION FD',
    'SAN RAFAEL FD',
    'SAN RAMON VALLEY FPD',
    'SANEL VALLEY FIRE PROTECTION DISTRICT',
    'SANGER FD',
    'SANTA BARBARA CFD',
    'SANTA BARBARA CITY FD',
    'SANTA CLARA CFD',
    'SANTA CLARA FD',
    'SANTA CRUZ COUNTY CSA 48',
    'SANTA CRUZ FD',
    'SANTA FE SPRINGS FD',
    'SANTA MARGARITA VFD',
    'SANTA MARIA FD',
    'SANTA MONICA FD',
    'SANTA PAULA CITY FD',
    'SANTA ROSA FD',
    'SANTA YSABEL RESERVATION SYRFD',
    'SANTEE FD',
    'SCHELL VISTA FPD',
    'SCOTT VALLEY FPD',
    'SCOTTS VALLEY FPD',
    'SEASIDE FD',
    'SEBASTOPOL FD',
    'SEELEY',
    'SEIAD VALLEY FIRE CO',
    'SELMA FD',
    'SHASTA COLLEGE FD',
    'SHASTA FPD',
    'SHASTA LAKE FPD',
    'SHAVER LAKE VFD',
    'SHELTER COVE FD',
    'SHERIDAN FIRE (CSA 28 ZONE 6B)',
    'SIERRA ARMY DEPOT',
    'SIERRA CITY FD',
    'SIERRA CO FPD 1',
    'SIERRA MADRE FD',
    'SIERRA VALLEY FPD',
    'SIMI VALLEY CITY FD',
    'SLEEPY HOLLOW FIRE PROTECTION DISTRICT',
    'SMARTSVILLE FD',
    'SMITH RIVER FPD',
    'SOBOBA FD',
    'SOLANA BEACH FD',
    'SONOMA COUNTY FIRE DISTRICT',
    'SONOMA VALLEY FIRE DISTRICT',
    'SONORA FD',
    'SOTOYOME VFC',
    'SOUTH COAST FPD',
    'SOUTH LAKE CFPD',
    'SOUTH LASSEN',
    'SOUTH MONTEREY COUNTY FPD',
    'SOUTH PASADENA FD',
    'SOUTH PLACER FPD',
    'SOUTH SAN FRANCISCO FD',
    'SOUTH SAN JOAQUIN CFA',
    'SOUTH YREKA FPD',
    'SOUTHERN INYO FPD',
    'SOUTHERN MARIN FIRE PROTECTION DISTRICT',
    'SOUTHERN MARIN FPD',
    'SPALDING VFD',
    'SPRINGLAKE FPD',
    'ST HELENA FD',
    'STANDISH LITCHFIELD FPD',
    'STANISLAUS CONSOLIDATED FPD',
    'STINSON BEACH FPD',
    'STOCKTON FD',
    'STONES BENGARD CSD',
    'STRAWBERRY VFD',
    'SUBURBAN PINES CSD',
    'SUISUN CITY FD',
    'SUISUN FPD',
    'SUNNYVALE DPS FD',
    'SUNSET INDUSTRIAL AREA FIRE (CSA 28 ZONE 97)',
    'SUNSET/WESTERN PLACER OVERLAP',
    'SUSAN RIVER FPD',
    'SUSANVILLE FD',
    'SUTTER BASIN FPD',
    'SUTTER COUNTY FD',
    'SUTTER CREEK FD',
    'SYCUAN FD',
    'TELEGRAPH RIDGE FPD',
    'TEMPLETON FD',
    'THORNTON FPD',
    'THOUSAND OAKS CITY FD',
    'TIBURON FPD',
    'TIMBER COVE VFC',
    'TORRANCE FD',
    'TRAVIS AFB',
    'TRINIDAD VFD',
    'TRINITY CENTER FD',
    'TRUCKEE FPD',
    'TULARE FD',
    'TULE RIVER FD',
    'TULELAKE MULTI CO FD',
    'TUOLUMNE CO FD',
    'TUOLUMNE FPD',
    'TURLOCK FD',
    'TURLOCK RURAL FPD',
    'TWAIN HARTE CSD',
    'UKIAH VALLEY FIRE AUTHORITY',
    'UNKNOWN',
    'UNSERVED',
    'USCG TRAINING CENTER PETALUMA FD',
    'USFS',
    'VACAVILLE FD',
    'VACAVILLE FPD',
    'VALLEJO FD',
    'VALLEY CENTER FPD',
    'VENTURA COUNTY FPD',
    'VERNON FD',
    'VICTORVILLE FD',
    'VIEJAS FD',
    'VISALIA FD',
    'VISTA FD AND FPD',
    'WALNUT GROVE FD',
    'WATERLOO MORADA RURAL FPD',
    'WATSONVILLE FD',
    'WEAVERVILLE FIRE DISTRICT',
    'WEED VFD',
    'WEST ALMANOR CSD',
    'WEST COVINA-CITY FIRE DEPT',
    'WEST PLAINFIELD FPD',
    'WEST POINT FPD',
    'WEST SACRAMENTO FD',
    'WEST STANISLAUS FPD',
    'WESTERN PLACER FIRE (CSA 28 ZONE 76)',
    'WESTMORLAND FD',
    'WESTPORT FPD',
    'WESTPORT VFD',
    'WESTWOOD FD',
    'WHEELER CREST FPD',
    'WHITE MOUNTAIN FPD',
    'WHITETHORN VFD',
    'WILLIAMS FPA',
    'WILLOW CREEK VPD',
    'WILLOW OAK FPD',
    'WILLOW RANCH',
    'WILLOWS RURAL FPD',
    'WILTON FPD',
    'WINTERHAVEN FD',
    'WINTERS FD',
    'WINTERS FPD',
    'WOODBRIDGE FPD',
    'WOODLAKE FPD',
    'WOODLAND AVE FPD',
    'WOODLAND FD',
    'WOODSIDE FPD',
    'YERMO/CALICO FD',
    'YOLO FPD',
    'YREKA VFD',
    'YUCAIPA FD',
    'ZAMORA FPD',
    'ZAYANTE FPD',
  ],
  'Regional Resource Kit Boundaries': [
    'Central Coast Region',
    'North Coast Region',
    'Sierra Nevada Region',
    'South Coast Region',
  ],
  'BLM CA Administrative Unit Boundary Field Office Polygon': [
    'Applegate Field Office',
    'Arcata Field Office',
    'Bakersfield Field Office',
    'Barstow Field Office',
    'Bishop Field Office',
    'Central Coast Field Office',
    'Eagle Lake Field Office',
    'El Centro Field Office',
    'Mother Lode Field Office',
    'Needles Field Office',
    'Palm Springs/S. Coast Field Office',
    'Redding Field Office',
    'Ridgecrest Field Office',
    'Ukiah Field Office',
  ],
  'Administrative Forest Boundaries': [
    'Angeles National Forest',
    'Cleveland National Forest',
    'Eldorado National Forest',
    'Inyo National Forest',
    'Klamath National Forest',
    'Lake Tahoe Basin Management Unit',
    'Lassen National Forest',
    'Los Padres National Forest',
    'Mendocino National Forest',
    'Modoc National Forest',
    'Plumas National Forest',
    'San Bernardino National Forest',
    'Sequoia National Forest',
    'Shasta-Trinity National Forest',
    'Sierra National Forest',
    'Six Rivers National Forest',
    'Stanislaus National Forest',
    'Tahoe National Forest',
  ],
  'CAL FIRE Operational Units': [
    'Amador-Eldorado Unit',
    'Butte Unit',
    'Fresno-Kings Unit',
    'Humboldt-Del Norte Unit',
    'Kern County',
    'Lassen-Modoc Unit',
    'Los Angeles County',
    'Madera-Mariposa-Merced Unit',
    'Marin County',
    'Mendocino Unit',
    'Nevada-Yuba-Placer Unit',
    'Orange County',
    'Riverside Unit',
    'San Benito-Monterey Unit',
    'San Bernardino Unit',
    'San Diego Unit',
    'San Luis Obispo Unit',
    'San Mateo-Santa Cruz Unit',
    'Santa Barbara County',
    'Santa Clara Unit',
    'Shasta-Trinity Unit',
    'Siskiyou Unit',
    'Sonoma-Lake Napa Unit',
    'Tehama-Glenn Unit',
    'Tulare Unit',
    'Tuolumne-Calaveras Unit',
    'Ventura County',
  ],
  'California State Senate Districts': [
    '710TOWATER',
    'ANTIVICAL',
    'COCO',
    'COR-CAJON',
    'ECA',
    'EDENTECH',
    'EVENTSFV',
    'FRESNO-KERN',
    'IOC',
    'KINGS-KERN',
    'MCV',
    'MIDCOAST',
    'N-OC-COAST',
    'NAPABYRON',
    'NCOAST',
    'NORCA',
    'PENINSULA',
    'PLACER-ED',
    'POF',
    'SAA',
    'SACRAMENTO',
    'SANJOSE',
    'SBENFRESNO',
    'SBRC',
    'SCOAST',
    'SCSFV',
    'SD-POW-ESC',
    'SD10WE',
    'SD210',
    'SD60X605',
    'SD80CORR',
    'SDNELA',
    'SDSHORELINE',
    'SECA',
    'SF',
    'SOC-NSD',
    'SPCC',
    'SSACSTANIS',
    'SWRC',
    'WESTOF110',
  ],
  'California Assembly Districts': [
    'Assembly District 01',
    'Assembly District 02',
    'Assembly District 03',
    'Assembly District 04',
    'Assembly District 05',
    'Assembly District 06',
    'Assembly District 07',
    'Assembly District 08',
    'Assembly District 09',
    'Assembly District 10',
    'Assembly District 11',
    'Assembly District 12',
    'Assembly District 13',
    'Assembly District 14',
    'Assembly District 15',
    'Assembly District 16',
    'Assembly District 17',
    'Assembly District 18',
    'Assembly District 19',
    'Assembly District 20',
    'Assembly District 21',
    'Assembly District 22',
    'Assembly District 23',
    'Assembly District 24',
    'Assembly District 25',
    'Assembly District 26',
    'Assembly District 27',
    'Assembly District 28',
    'Assembly District 29',
    'Assembly District 30',
    'Assembly District 31',
    'Assembly District 32',
    'Assembly District 33',
    'Assembly District 34',
    'Assembly District 35',
    'Assembly District 36',
    'Assembly District 37',
    'Assembly District 38',
    'Assembly District 39',
    'Assembly District 40',
    'Assembly District 41',
    'Assembly District 42',
    'Assembly District 43',
    'Assembly District 44',
    'Assembly District 45',
    'Assembly District 46',
    'Assembly District 47',
    'Assembly District 48',
    'Assembly District 49',
    'Assembly District 50',
    'Assembly District 51',
    'Assembly District 52',
    'Assembly District 53',
    'Assembly District 54',
    'Assembly District 55',
    'Assembly District 56',
    'Assembly District 57',
    'Assembly District 58',
    'Assembly District 59',
    'Assembly District 60',
    'Assembly District 61',
    'Assembly District 62',
    'Assembly District 63',
    'Assembly District 64',
    'Assembly District 65',
    'Assembly District 66',
    'Assembly District 67',
    'Assembly District 68',
    'Assembly District 69',
    'Assembly District 70',
    'Assembly District 71',
    'Assembly District 72',
    'Assembly District 73',
    'Assembly District 74',
    'Assembly District 75',
    'Assembly District 76',
    'Assembly District 77',
    'Assembly District 78',
    'Assembly District 79',
    'Assembly District 80',
  ],
};
