import { useState, useEffect, useMemo } from 'react';
import Sparcal from '../../lib/Sparcal';
import { Form, Spinner, Alert } from 'react-bootstrap';
import DraftCard from './DraftCard';
import ResponseModal from '../../components/modal/ResponseModal';
import PermissionsHelper from '../../util/PermissionsHelper';

const DraftDataPage = (props) => {
  const toDisplay = props.display === 'draft_data';
  const [draftData, setDraftData] = useState(null);
  const [isPending, setIsPending] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState(null);
  const approverView = PermissionsHelper(props.user.roles, 'approver');
  const publisherView = PermissionsHelper(props.user.roles, 'publisher');
  const adminView = PermissionsHelper(props.user.roles, 'admin');
  const approverViewOnly = approverView && !adminView;
  const publisherViewOnly = publisherView && !adminView;
  const [selectedDrafts, setSelectedDrafts] = useState(
    approverViewOnly ? 'all' : 'user'
  );
  const [alertDisplay, setAlertDisplay] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  useMemo(() => {
    const fetchData = async () => {
      setIsPending(true);
      let draftData;
      if (selectedDrafts === 'all') {
        draftData = await Sparcal.getDraftDataset(props.user);
      } else {
        draftData = await Sparcal.getUserDraftDataset(props.user);
      }
      if (draftData.status === 200) {
        setDraftData(draftData.data.results);
      } else if (draftData.status === 401) {
        setModalShow(true);
        setModalData({
          title: 'Loading failed',
          description: 'Please relog in, access_code has expired.',
        });
      } else {
        setModalShow(true);
        setModalData({
          title: 'Loading failed',
          description: draftData.message,
        });
      }
      setIsPending(false);
    };
    fetchData();
  }, [toDisplay, selectedDrafts]);

  const handleEdit = async (draftId) => {
    onShowAlert('Successful Edit');
  };

  const handleDelete = async (draftId) => {
    await Sparcal.deleteDataset(props.user, draftId);
    setDraftData(draftData.filter((data) => data.id !== draftId));
    onShowAlert('Successful Delete');
  };

  const handleApprove = async (draftId) => {
    await Sparcal.approveDataset(props.user, draftId);
    setDraftData(draftData.filter((data) => data.id !== draftId));
    onShowAlert('Successful Approve');
  };
  const handleDeny = async (draftId) => {
    await Sparcal.denyDataset(props.user, draftId);
    setDraftData(draftData.filter((data) => data.id !== draftId));
    onShowAlert('Successful Deny');
  };
  useEffect(() => {
    if (alertDisplay) {
      window.setTimeout(() => {
        setAlertDisplay(false);
      }, 3000);
    } else {
      setAlertMessage('');
    }
  }, [alertDisplay]);
  const onShowAlert = (message) => {
    setAlertMessage(message);
    setAlertDisplay(true);
  };
  return (
    <>
      {modalShow && (
        <ResponseModal
          data={modalData}
          onHide={() => {
            setModalShow(false);
            setModalData(null);
          }}
        />
      )}

      {alertDisplay && (
        <Alert
          style={{
            position: 'fixed',
            left: '46%',
            marginTop: '8px',
            padding: '8px',
          }}
          variant="success"
        >
          <h4> {alertMessage}</h4>
        </Alert>
      )}

      {toDisplay && (
        <div
          id="draft_data"
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <div
            style={{
              backgroundSize: 'cover',
            }}
          >
            <div
              style={{
                margin: '30px 10%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <h1
                style={{
                  color: '#525335',
                  fontWeight: 'bolder',
                  fontSize: '36px',
                }}
              >
                Draft Datasets
              </h1>
              <Form.Select
                style={{ width: '200px' }}
                onChange={(value) => {
                  setSelectedDrafts(value.target.value);
                }}
                value={selectedDrafts}
                disabled={approverViewOnly || publisherViewOnly}
              >
                <option value="user">My Drafts</option>
                <option value="all">All Drafts</option>
              </Form.Select>
            </div>
          </div>
          <div
            style={{
              backgroundColor: '#EEEDE2',
              backgroundSize: 'cover',
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '35px',
            }}
          >
            {isPending ? (
              <Spinner animation="border" variant="success" />
            ) : (
              <>
                {draftData !== null ? (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {draftData?.map((draft, idx) => {
                      return (
                        <DraftCard
                          handleEdit={() => handleEdit(draft)}
                          handleDelete={() => handleDelete(draft.id)}
                          handleApprove={() => handleApprove(draft.id)}
                          handleDeny={() => handleDeny(draft.id)}
                          user={props.user}
                          key={idx}
                          dataset={draft}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <>No drafts found</>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default DraftDataPage;
