import { useContext, useState, useEffect } from 'react';
import ForestDataLogin from '../../map/ForestDataMapLogin';
import { UserContext } from '../../UserContext';
import './header.css';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Container } from 'react-bootstrap';
import PermissionsHelper from '../../util/PermissionsHelper';
import { FaHome } from 'react-icons/fa';

export default function ForestDataHubHeader(props) {
  const { user, setUser } = useContext(UserContext);
  const signedIn =
    Object.keys(user).length === 0 || user?.access_token === undefined;
  function updateUser(u) {
    setUser(u);
  }
  const onClick = (e) => {
    props.setDisplay(e);
  };

  let viewDraft;
  if (props.user) {
    viewDraft = PermissionsHelper(props.user.roles, 'any');
  }
  return (
    <Navbar
      variant="dark"
      style={{
        backgroundSize: '0',
        backgroundColor: '#525335',
        minHeight: '50px',
        padding: '0 1rem 0 1rem',
      }}
      expand="lg"
    >
      <Container style={{ margin: 0, maxWidth: '100%', padding: 0 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Nav style={{ padding: 0 }}>
            <Nav.Link
              id="responsive-navbar-nav"
              style={{
                paddingRight: '10px',
                fontSize: '22px',
                fontWeight: 'bold',
                display: 'flex',
              }}
              disabled={signedIn}
              onClick={() => {
                onClick('home');
              }}
            >
              <FaHome />
            </Nav.Link>
          </Nav>
          <Navbar.Brand style={{ fontSize: '16px', fontWeight: 'bold' }}>
            Task Force Data Hub
          </Navbar.Brand>
        </div>
        {signedIn ? (
          <ForestDataLogin
            user={user}
            updateUser={updateUser}
            getGisService={props.getGisService}
            getItemKey={props.getItemKey}
            setFilterItems={props.setFilterItems}
            setCheckedDatasets={props.setCheckedDatasets}
            opacities={props.opacities}
            setOpacities={props.setOpacities}
          ></ForestDataLogin>
        ) : (
          <>
            <Navbar.Toggle
              className="ml-auto"
              aria-controls="responsive-navbar-nav"
              onToggle={() => {
                console.log('toggled');
              }}
            />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav
                className=" justify-content-end"
                style={{ display: 'flex', flexGrow: 'inherit' }}
              >
                {viewDraft && (
                  <Nav.Link
                    style={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      width: 'auto',
                      alignSelf: 'end',
                    }}
                    onClick={() => {
                      onClick('draft_data');
                    }}
                  >
                    Draft Datasets
                  </Nav.Link>
                )}
                <Nav.Link
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    width: 'auto',
                    alignSelf: 'end',
                  }}
                  onClick={() => {
                    onClick('data_catalog_new');
                  }}
                >
                  Catalog
                </Nav.Link>
                <NavDropdown
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    width: 'auto',
                    alignSelf: 'end',
                  }}
                  title="Data Collections"
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item
                    style={{ fontSize: '14px' }}
                    onClick={() => {
                      onClick('its');
                    }}
                  >
                    Interagency Tracking System
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    style={{ fontSize: '14px' }}
                    onClick={() => {
                      onClick('clm');
                    }}
                  >
                    California Landscape Metrics
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    width: 'auto',
                    alignSelf: 'end',
                  }}
                  onClick={() => {
                    onClick('map');
                  }}
                >
                  Workspace
                </Nav.Link>
                <ForestDataLogin
                  user={user}
                  updateUser={updateUser}
                  getGisService={props.getGisService}
                  getItemKey={props.getItemKey}
                  setFilterItems={props.setFilterItems}
                  setCheckedDatasets={props.setCheckedDatasets}
                  opacities={props.opacities}
                  setOpacities={props.setOpacities}
                ></ForestDataLogin>
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Container>
    </Navbar>
  );
}
