import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Card, Collapse, Tooltip } from 'antd';
import './mini_its_dashboard.css';
import AcresByActivityCategories from './AcresByActivityCategories';
import AcresByOwner from './AcresByOwner';
import AcresByAgency from './AcresByAgency';
import { FilterOutlined, FilterFilled } from '@ant-design/icons';

const { Panel } = Collapse;

const MiniITSDashboard = (props) => {
  const [categoryData, setCategoryData] = useState([
    {
      category: 'Tree Planting',
      acres: 0,
      visible: props.categories.includes('Tree Planting'),
    },
    {
      category: 'Timber Harvest',
      acres: 0,
      visible: props.categories.includes('Timber Harvest'),
    },
    {
      category: 'Sanitation & Salvage',
      acres: 0,
      visible: props.categories.includes('Sanitation & Salvage'),
    },
    {
      category: 'Mechanical and Hand Fuels Reduction',
      acres: 0,
      visible: props.categories.includes('Mechanical and Hand Fuels Reduction'),
    },
    {
      category: 'Grazing',
      acres: 0,
      visible: props.categories.includes('Grazing'),
    },
    {
      category: 'Beneficial Fire',
      acres: 0,
      visible: props.categories.includes('Beneficial Fire'),
    },
    {
      category: 'Land Protection',
      acres: 0,
      visible: props.categories.includes('Land Protection'),
    },
    {
      category: 'Not Reported',
      acres: 0,
      visible: props.categories.includes('Not Reported'),
    },
  ]);

  // const getOwnerValue = (owner) => {
  //     if (owner === "Federal") {
  //         return "FEDERAL";
  //     } else if (owner === "Local") {
  //         return "LOCAL";
  //     } else if (owner === "NGO") {
  //         return "NGO";
  //     } else if (owner === "Private Industry") {
  //         return "PRIVATE_INDUSTRY";
  //     } else if (owner === "Private Non-Industry") {
  //         return "PRIVATE_NON-INDUSTRY";
  //     } else if (owner === "State") {
  //         return "STATE";
  //     } else if (owner === "Tribal") {
  //         return "TRIBAL";
  //     } else if(owner === 'Not Reported') {
  //         return '';
  //     }
  //     else {
  //         return null;
  //     }
  // }

  const [agencyData, setAgencyData] = useState([
    {
      agency: 'CA Environmental Protection Agency',
      acres: 0,
      visible: props.agencies.includes('CA Environmental Protection Agency'),
    },
    {
      agency: 'CA State Transportation Agency',
      acres: 0,
      visible: props.agencies.includes('CA State Transportation Agency'),
    },
    {
      agency: 'CA Natural Resources Agency',
      acres: 0,
      visible: props.agencies.includes('CA Natural Resources Agency'),
    },
    {
      agency: 'CA Air Resources Board',
      acres: 0,
      visible: props.agencies.includes('CA Air Resources Board'),
    },
    {
      agency: 'Department of Defense',
      acres: 0,
      visible: props.agencies.includes('Department of Defense'),
    },
    {
      agency: 'Department of the Interior',
      acres: 0,
      visible: props.agencies.includes('Department of the Interior'),
    },
    {
      agency: 'Department of Agriculture',
      acres: 0,
      visible: props.agencies.includes('Department of Agriculture'),
    },
    {
      agency: 'Timber Companies',
      acres: 0,
      visible: props.agencies.includes('Timber Companies'),
    },
    {
      agency: 'Other',
      acres: 0,
      visible: props.agencies.includes('Other'),
    },
    {
      agency: 'Not Reported',
      acres: 0,
      visible: props.agencies.includes('Not Reported'),
    },
  ]);

  const [ownerData, setOwnerData] = useState([
    {
      owner: 'Federal',
      acres: 0,
      visible: props.owners.includes('Federal'),
    },
    {
      owner: 'Local',
      acres: 0,
      visible: props.owners.includes('Local'),
    },
    {
      owner: 'NGO',
      acres: 0,
      visible: props.owners.includes('NGO'),
    },
    {
      owner: 'Private Industry',
      acres: 0,
      visible: props.owners.includes('Private Industry'),
    },
    {
      owner: 'Private Non-Industry',
      acres: 0,
      visible: props.owners.includes('Private Non-Industry'),
    },
    {
      owner: 'State',
      acres: 0,
      visible: props.owners.includes('State'),
    },
    {
      owner: 'Tribal',
      acres: 0,
      visible: props.owners.includes('Tribal'),
    },
    {
      owner: 'Not Reported',
      acres: 0,
      visible: props.owners.includes('Not Reported'),
    },
  ]);

  let ownerIsFiltered = ownerData.filter((e) => e.visible === false).length > 0;
  let ownerFiltered = ownerData
    .filter((e) => e.visible === true)
    .map((e) => e.owner)
    .join(', ');
  let categoryIsFiltered =
    categoryData.filter((e) => e.visible === false).length > 0;
  let categoryFiltered = categoryData
    .filter((e) => e.visible === true)
    .map((e) => e.category)
    .join(', ');
  let agencyIsFiltered =
    agencyData.filter((e) => e.visible === false).length > 0;
  let agencyFiltered = agencyData
    .filter((e) => e.visible === true)
    .map((e) => e.agency)
    .join(', ');

  return (
    <div style={{ paddingTop: '12px' }}>
      {/* <Card size="small"
                title="Acres by Activity Categories"
                headStyle={{backgroundColor: '#ededed'}}
                style={{width: 310, textAlign: 'center', marginTop: 10}}> */}
      <Collapse>
        <Panel
          header={
            <div>
              <span>Acres per Activity Categories</span>
              {categoryIsFiltered ? (
                <Tooltip title={categoryFiltered} placement="left">
                  <FilterFilled
                    style={{
                      float: 'inline-end',
                      paddingTop: '4px',
                      fontSize: '1.1em',
                    }}
                  />
                </Tooltip>
              ) : (
                <FilterOutlined
                  style={{
                    float: 'inline-end',
                    paddingTop: '4px',
                    fontSize: '1.1em',
                  }}
                />
              )}
            </div>
          }
          key="0"
          style={{ backgroundColor: 'rgb(237, 237, 237)', textAlign: 'left' }}
        >
          <AcresByActivityCategories
            valueRanges={props.valueRanges}
            categories={props.categories}
            setCategories={props.setCategories}
            getAgencyValue={props.getAgencyValue}
            getCategoryValue={props.getCategoryValue}
            getOwnerValue={props.getOwnerValue}
            categoryData={categoryData}
            setCategoryData={setCategoryData}
            agencyData={agencyData}
            ownerData={ownerData}
          />
          {/* </Card>  */}
        </Panel>
        {/* </Collapse>
            <Collapse> */}
        <Panel
          header={
            <div>
              <span>Acres per Land Owner Type</span>
              {ownerIsFiltered ? (
                <Tooltip title={ownerFiltered} placement="left">
                  <FilterFilled
                    style={{
                      float: 'inline-end',
                      paddingTop: '4px',
                      fontSize: '1.1em',
                    }}
                  />
                </Tooltip>
              ) : (
                <FilterOutlined
                  style={{
                    float: 'inline-end',
                    paddingTop: '4px',
                    fontSize: '1.1em',
                  }}
                />
              )}
            </div>
          }
          key="1"
          style={{ backgroundColor: 'rgb(237, 237, 237)', textAlign: 'left' }}
        >
          {/* <Card size="small"
                title="Acres per Land Owner Type"
                headStyle={{backgroundColor: '#ededed'}}
                style={{width: 310, textAlign: 'center', marginTop: 10}}> */}
          <AcresByOwner
            owners={props.owners}
            setOwners={props.setOwners}
            valueRanges={props.valueRanges}
            categoryData={categoryData}
            getOwnerValue={props.getOwnerValue}
            getCategoryValue={props.getCategoryValue}
            getAgencyValue={props.getAgencyValue}
            agencyData={agencyData}
            ownerData={ownerData}
            setOwnerData={setOwnerData}
          />
          {/* </Card> */}
        </Panel>
        {/* </Collapse>
            <Collapse> */}
        <Panel
          header={
            <div>
              <span>Acres Treated per Agency</span>
              {agencyIsFiltered ? (
                <Tooltip title={agencyFiltered} placement="left">
                  <FilterFilled
                    style={{
                      float: 'inline-end',
                      paddingTop: '4px',
                      fontSize: '1.1em',
                    }}
                  />
                </Tooltip>
              ) : (
                <FilterOutlined
                  style={{
                    float: 'inline-end',
                    paddingTop: '4px',
                    fontSize: '1.1em',
                  }}
                />
              )}{' '}
            </div>
          }
          key="2"
          style={{ backgroundColor: 'rgb(237, 237, 237)', textAlign: 'left' }}
        >
          {/* <Card size="small"
                title="Acres Treated per Agency"
                headStyle={{backgroundColor: '#ededed'}}
                style={{width: 310, textAlign: 'center', marginTop: 10}}> */}
          <AcresByAgency
            valueRanges={props.valueRanges}
            agencies={props.agencies}
            setAgencies={props.setAgencies}
            getAgencyValue={props.getAgencyValue}
            getCategoryValue={props.getCategoryValue}
            getOwnerValue={props.getOwnerValue}
            categoryData={categoryData}
            ownerData={ownerData}
            agencyData={agencyData}
            setAgencyData={setAgencyData}
          />
          {/* </Card> */}
        </Panel>
      </Collapse>
    </div>
  );
};

export default MiniITSDashboard;
