import React, { useState, useEffect } from 'react';
import { Typography, Table, Button, Progress, Spin } from 'antd';
import axios from 'axios';
import fetch from 'sync-fetch';
import {
  PDFDownloadLink,
  Page,
  Text as PDFText,
  View,
  Document,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import { Table as PDFTable, TR, TH, TD } from '@ag-media/react-pdf-table';
import Input from 'antd/es/input';
import TextArea from 'antd/es/input/TextArea';
import { AlignLeftOutlined } from '@ant-design/icons';

const { Text } = Typography;

const WFSDataReport = (props) => {
  const [dataContents, setDataContents] = useState([]);
  const [itsContents, setItsContent] = useState([]);
  const [reportError, setReportError] = useState(null);
  const [percent, setPercent] = useState(0);

  const [rrkTitles, setRrkTitles] = useState([]);
  const [rrkTitlesEditings, setRrkTitlesEditings] = useState([]);

  const [rrkDescs, setRrkDescs] = useState([]);
  const [rrkDescsEditings, setRrkDescsEditings] = useState([]);

  const [itsReportTitle, setItsReportTitle] = useState(
    `Assessing Forest Health and Fuels Reduction Progress in Our Region by Interagency Tracking System Flat Point Data`
  );
  const [itsReportIntro, setItsReportIntro] = useState(
    `In pursuit of fostering a resilient forest ecosystem and mitigating wildfire risks, the USDA Forest Service Pacific Southwest Region and the State of California forged a significant partnership on August 12, 2020, through the signing of a momentous Memorandum of Understanding. This landmark agreement aimed to achieve the ambitious target of implementing 1 million acres of forest health and fuels reduction treatments annually. To monitor the progress towards this goal, the California Wildfire and Forest Resilience Task Force has diligently curated a comprehensive dataset, which amalgamates wildfire fuels treatments, timber harvests, and forest resiliency projects from various reliable sources. Collaboratively developed by the Climate and Wildfire Institute alongside state and federal agencies, this dataset meticulously adheres to the California Wildfire and Forest Resilience Task Force (WFR TF) Interagency Tracking System template. In light of the shared stewardship vision, this dataset has been enriched with valuable attributes for vegetation, ownership, county, Wildland Urban Interface (WUI), and Task Force Region, from authoritative reference data. In the context of our specific region, we have meticulously extracted a subset of this data to conduct a rigorous statistical analysis focusing on the total finished acres and activity numbers associated with each region. The ensuing report endeavors to shed light on the state's progress towards forest health and fuels reduction, fostering a better understanding of our forest ecosystems and promoting sustainable management strategies for the future.`
  );

  const [itsReportTitleEditing, setItsReportTitleEditing] = useState(false);
  const [itsReportIntroEditing, setItsReportIntroEditing] = useState(false);

  const main_columns = {
    ca_counties: 'namelsad',
    rrk_boundaries: 'rrk_region',
  };

  // useEffect(() => {
  //     const interval = setInterval(() => {
  //         setPercent(percent => percent + 1);
  //     }, 3600);

  //     return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    setDataContents([]);
    setItsContent([]);
    setReportError(null);
    setPercent(0);

    // get the original GeoJSON string
    const selectedFeatureDataset = props.selectedFeatureLayer;
    const selecteFeatureGisService = props.getGisService(
      props.selectedFeatureLayer
    );

    // call WPS service gs:RasterZonalStatistics for each selected RRK dataset
    // let datasets = props.checkedDatasets.filter(dataset => dataset.data_type === 'raster');
    let datasets = props.checkedDatasets.filter(
      (dataset) => dataset.data_type === 'raster'
    );

    let newRrkTitles = [];
    let newRrkTitlesEditings = [];
    let newRrkDescs = [];
    let newRrkDescsEditings = [];
    for (let i = 0; i < datasets.length; i++) {
      let dataset = datasets[i];
      newRrkTitles.push(
        dataset.dataset_collections[0].name + ' - ' + dataset.name
      );
      newRrkTitlesEditings.push(false);
      newRrkDescs.push(
        dataset.report_description_template
          ? dataset.report_description_template
          : ''
      );
      newRrkDescsEditings.push(false);
    }
    setRrkTitles(newRrkTitles);
    setRrkTitlesEditings(newRrkTitlesEditings);
    setRrkDescs(newRrkDescs);
    setRrkDescsEditings(newRrkDescsEditings);

    let requestBodys = [];
    for (let i = 0; i < datasets.length; i++) {
      let dataset = datasets[i];
      let gis_service = props.getGisService(dataset);
      let namespace = gis_service.layer_name.split(':')[0];
      let server = 'https://sparcal.sdsc.edu/geoserver/';
      let wcsBaseUrl = gis_service.service_url.replace('wms', 'wcs');
      let url =
        wcsBaseUrl +
        '?service=WCS&version=2.0.1&request=DescribeCoverage&CoverageId=' +
        gis_service.layer_name;
      let wcsResponse = fetch(url);

      let data = wcsResponse.text();

      // Parse the XML response using an XML parser (e.g., DOMParser)
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(data, 'text/xml');

      // Extract the extent information from the parsed XML
      const lowerCorner =
        xmlDoc.getElementsByTagName('gml:lowerCorner')[0].textContent;
      const upperCorner =
        xmlDoc.getElementsByTagName('gml:upperCorner')[0].textContent;

      // Convert the extent coordinates to an array of numbers
      const [minX, minY] = lowerCorner.split(' ');
      const [maxX, maxY] = upperCorner.split(' ');

      let xmlBodyStr = `<?xml version="1.0" encoding="UTF-8"?>
<wps:Execute xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.opengis.net/wps/1.0.0" xmlns:wfs="http://www.opengis.net/wfs" xmlns:wps="http://www.opengis.net/wps/1.0.0" xmlns:ows="http://www.opengis.net/ows/1.1" xmlns:gml="http://www.opengis.nnet/gml" xmlns:ogc="http://www.opengis.net/ogc" xmlns:wcs="http://www.opengis.net/wcs/1.1.1" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0.0" service="WPS" xsi:schemaLocation="http://www.opengis.net/wps/1.0.0 http://schemas.opengis.net/wps/1.0.0/wpsAll.xsd">
    <ows:Identifier>
        gs:RasterZonalStatistics
    </ows:Identifier>
    <wps:DataInputs>
        <wps:Input>
            <ows:Identifier>data</ows:Identifier>
            <wps:Reference mimeType="image/tiff" xlink:href="http://${gis_service.service_type.toLowerCase()}/wcs" method="POST">
                <wps:Body>
                    <wcs:GetCoverage service="WCS" version="1.1.1">
                        <ows:Identifier>${gis_service.layer_name}</ows:Identifier>
                        <wcs:DomainSubset>
                            <ows:BoundingBox crs="http://www.opengis.net/gml/srs/epsg.xml#3310">
                                <ows:LowerCorner>${minX} ${minY}</ows:LowerCorner>
                                <ows:UpperCorner>${maxX} ${maxY}</ows:UpperCorner>
                            </ows:BoundingBox>
                        </wcs:DomainSubset>
                        <wcs:Output format="image/tiff"/>
                    </wcs:GetCoverage>
                </wps:Body>
            </wps:Reference>
        </wps:Input>
        <wps:Input>
            <ows:Identifier>zones</ows:Identifier>
            <wps:Reference mimeType="text/xml" xlink:href="http://${selecteFeatureGisService.service_type.toLowerCase()}/wfs" method="POST">
                <wps:Body>
                    <wfs:GetFeature service="WFS" version="1.0.0" outputFormat="GML2" xmlns:${selecteFeatureGisService.layer_name.split(':')[0]}="${selecteFeatureGisService.service_url.replace('/wms', '')}">
                        <wfs:Query typeName="${selecteFeatureGisService.layer_name}"/>
                    </wfs:GetFeature>
                </wps:Body>
            </wps:Reference>
        </wps:Input>
    </wps:DataInputs>
    <wps:ResponseForm>
        <wps:RawDataOutput mimeType="application/json">
            <ows:Identifier>statistics</ows:Identifier>
        </wps:RawDataOutput>
    </wps:ResponseForm>
</wps:Execute>`;

      requestBodys.push(xmlBodyStr);
    }

    // start loading real data
    const config = {
      headers: {
        'Content-Type': 'text/plain',
        // "Access-Control-Allow-Origin": "*",
        Accept: 'application/json',
      },
    };

    let urlPieces = selecteFeatureGisService.service_url
      .split('/')
      .slice(0, -2);
    let url = urlPieces.join('/') + '/wps';

    axios
      .all(
        requestBodys.map((request) =>
          // axios.post('https://twsa.ucsd.edu/geoserver/wps', request, config))
          axios.post(url, request, config)
        )
      )
      .then(
        axios.spread((...allData) => {
          let newDataContents = [];
          allData.forEach((response) => {
            //let data = response.data;
            // if (typeof data === 'string' || data instanceof String) {
            //     try {
            //         data = JSON.parse(data.replace(/\bNaN\b/g, '"NaN"'));
            //         // // console.log(data);
            //     } catch (err) {
            //         // try to parse it as xml
            //         const parser = new DOMParser();
            //         let xmlDoc = parser.parseFromString(data, 'text/xml');
            //         const exceptionText = xmlDoc.getElementsByTagName('ows:ExceptionText')[0].textContent;
            //         throw new Error(exceptionText);
            //     }
            // }
            // let content = data.features.map(feature => feature.properties);

            // let content = response.data;
            let content = response.data.features.map(
              (feature) => feature.properties
            );
            let prevContent = [...dataContents];

            let main_column =
              main_columns[props.selectedFeatureLayer.file_path];

            let all_main_column_attr = content.map(
              (e) => e['z_' + main_column]
            );
            let set_len_attr = new Set(all_main_column_attr).length;
            if (all_main_column_attr.length != set_len_attr) {
              let duplicates = all_main_column_attr.filter(
                (item, index) => all_main_column_attr.indexOf(item) !== index
              );
              // remove duplicates
              content = content.filter(
                (e) => duplicates.indexOf(e['z_' + main_column]) == -1
              );
            }

            let total_num_pix = 0;
            content.forEach((e) => {
              total_num_pix += parseFloat(e.count);
            });

            content.forEach((e) => {
              e['percentage'] = (
                (parseFloat(e.count) / total_num_pix) *
                100
              ).toFixed(2);
            });

            prevContent.push(content);

            setDataContents(prevContent);
            newDataContents.push(content);
          });
          setDataContents(newDataContents);
        })
      )
      .catch((error) => {
        // Modal.error({
        //     title: 'Error',
        //     content: error.message,
        // });
        setReportError(error.message);
        throw error;
      });

    //         // call WPS service for ITS points
    //         let its_datasets = props.checkedDatasets.filter(
    //             dataset => dataset.folder === '/Interagency Tracking System' &&
    //                 dataset.mapping[0].name === 'Million Acres Flat File Points');
    //         if (its_datasets.length > 0) {

    //             let xmlBodyStr = `<?xml version="1.0" encoding="UTF-8"?><wps:Execute version="1.0.0" service="WPS" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.opengis.net/wps/1.0.0" xmlns:wfs="http://www.opengis.net/wfs" xmlns:wps="http://www.opengis.net/wps/1.0.0" xmlns:ows="http://www.opengis.net/ows/1.1" xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" xmlns:wcs="http://www.opengis.net/wcs/1.1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xsi:schemaLocation="http://www.opengis.net/wps/1.0.0 http://schemas.opengis.net/wps/1.0.0/wpsAll.xsd">
    //   <ows:Identifier>gs:VectorZonalStatistics</ows:Identifier>
    //   <wps:DataInputs>
    //     <wps:Input>
    //       <ows:Identifier>data</ows:Identifier>
    //       <wps:Reference mimeType="text/xml" xlink:href="http://geoserver/wfs" method="POST">
    //         <wps:Body>
    //           <wfs:GetFeature service="WFS" version="1.0.0" outputFormat="GML2" xmlns:its="http://sparcal.org/cmwe">
    //             <wfs:Query typeName="ITS:its_points_20230822"/>
    //           </wfs:GetFeature>
    //         </wps:Body>
    //       </wps:Reference>
    //     </wps:Input>
    //     <wps:Input>
    //       <ows:Identifier>dataAttribute</ows:Identifier>
    //       <wps:Data>
    //         <wps:LiteralData>activity_quantity</wps:LiteralData>
    //       </wps:Data>
    //     </wps:Input>
    //     <wps:Input>
    //       <ows:Identifier>zones</ows:Identifier>
    //         <wps:Reference mimeType="text/xml" xlink:href="http://geoserver/wfs" method="POST">
    //           <wps:Body>
    //             <wfs:GetFeature service="WFS" version="1.0.0" outputFormat="GML2" xmlns:rrk_nv="http://www.wfr_hub.org/rrk/sierra_nevada">
    //               <wfs:Query typeName="${selectedFeatureDataset.mapping[0].layer}"/>
    //             </wfs:GetFeature>
    //           </wps:Body>
    //         </wps:Reference>
    //      </wps:Input>
    //   </wps:DataInputs>
    //   <wps:ResponseForm>
    //     <wps:RawDataOutput mimeType="application/json">
    //       <ows:Identifier>statistics</ows:Identifier>
    //     </wps:RawDataOutput>
    //   </wps:ResponseForm>
    // </wps:Execute>`;

    //             axios.post('https://sparcal.sdsc.edu/geoserver/wps', xmlBodyStr, config)
    //                 .then(response => {
    //                     let data = response.data;
    //                     if (typeof data === 'string' || data instanceof String) {
    //                         try {
    //                             data = JSON.parse(data.replace(/\bNaN\b/g, '"NaN"'));
    //                             // // console.log(data);
    //                         } catch (err) {
    //                             // try to parse it as xml
    //                             const parser = new DOMParser();
    //                             let xmlDoc = parser.parseFromString(data, 'text/xml');
    //                             const exceptionText = xmlDoc.getElementsByTagName('ows:ExceptionText')[0].textContent;
    //                             throw new Error(exceptionText);
    //                         }
    //                     }
    //                     let content = data.features.map(feature => feature.properties);
    //                     // console.log(content)
    //                     setItsContent(content);
    //                 })
    //                 .catch(error => {
    //                     setReportError(error.message);
    //                 })
    // }
  }, [
    props.selectedDatasets,
    props.selectedFeatureLayer,
    props.checkedDatasets,
  ]);

  const compareTo = (x, y) => {
    if (x === 'NaN' || x === undefined || x === null) {
      if (y === 'NaN' || y === undefined || y === null) {
        return 0;
      } else {
        return 1;
      }
    } else if (y === 'NaN' || y === undefined || y === null) {
      return -1;
    } else {
      return x - y;
    }
  };

  const getITSColumns = (data) => {
    let main_attrs = [main_columns[props.selectedFeatureLayer.file_path]];

    if (data.length > 0) {
      let columns = [];
      for (let i = 0; i < main_attrs.length; i++) {
        let attr = main_attrs[i];
        columns.push({
          title: attr,
          dataIndex: `z_${attr}`,
          key: `z_${attr}`,
          sorter: (a, b) => a[`z_${attr}`].localeCompare(b[`z_${attr}`]),
          defaultSortOrder: 'ascend',
        });
      }

      columns.push({
        title: 'Acres',
        dataIndex: 'sum',
        key: 'sum',
        sorter: (a, b) => compareTo(a.sum, b.sum),
        render: (a) => (a && a !== null && a !== 'NaN' ? a.toFixed(2) : a),
      });

      columns.push({
        title: 'Activity Number',
        dataIndex: 'count',
        key: 'count',
        sorter: (a, b) => compareTo(a.count, b.count),
      });
      return columns;
    }

    return [];
  };

  const getColumns = (data, methods) => {
    let main_column = main_columns[props.selectedFeatureLayer.file_path];
    let main_attrs = [main_column];

    if (data.length > 0) {
      // let firstRow = data[0];
      let columns = [];
      // for (let key in firstRow) {
      //     if (key.toLowerCase() === 'z_name') {
      //         columns.push({
      //             title: 'Name',
      //             dataIndex: key,
      //             key: key,
      //             sorter: (a, b) => a[key] < b[key] ? -1 : (a[key] > b[key] ? 1 : 0),
      //             defaultSortOrder: 'ascend',
      //         });
      //     }
      // }

      for (let i = 0; i < main_attrs.length; i++) {
        let attr = main_attrs[i];
        columns.push({
          title: attr,
          dataIndex: `z_${attr}`,
          key: `z_${attr}`,
          sorter: (a, b) =>
            a[`z_${attr}`] ? a[`z_${attr}`].localeCompare(b[`z_${attr}`]) : 1,
          defaultSortOrder: attr.toLowerCase() === 'name' ? 'ascend' : null,
          shouldCellUpdate: (a, b) => false,
        });
      }

      if (methods) {
        for (let i = 0; i < methods.length; i++) {
          let method = methods[i];
          if (method === 'sum') {
            columns.push({
              title: 'Total',
              dataIndex: 'sum',
              key: 'sum',
              sorter: (a, b) => compareTo(a.sum, b.sum),
              shouldCellUpdate: (a, b) => false,
            });
          } else if (method === 'count') {
            columns.push({
              title: 'Count',
              dataIndex: 'count',
              key: 'count',
              sorter: (a, b) => compareTo(a.count, b.count),
              shouldCellUpdate: (a, b) => false,
            });
          }
        }
      } else {
        columns.push({
          title: 'Average',
          dataIndex: 'avg',
          key: 'avg',
          sorter: (a, b) => compareTo(a.avg, b.avg),
          render: (a) => (a && a !== 'NaN' ? a.toFixed(4) : null),
          shouldCellUpdate: (a, b) => false,
        });

        columns.push({
          title: 'Min',
          dataIndex: 'min',
          key: 'min',
          sorter: (a, b) => compareTo(a.min, b.min),
          render: (a) => (a && a !== 'NaN' ? a.toFixed(4) : null),
          shouldCellUpdate: (a, b) => false,
        });

        columns.push({
          title: 'Max',
          dataIndex: 'max',
          key: 'max',
          sorter: (a, b) => compareTo(a.max, b.max),
          render: (a) => (a && a !== 'NaN' ? a.toFixed(4) : null),
          shouldCellUpdate: (a, b) => false,
        });

        columns.push({
          title: 'Percent Of Layer in Area',
          dataIndex: 'percentage',
          key: 'percentage',
          sorter: (a, b) => compareTo(a.percentage, b.percentage),
          shouldCellUpdate: (a, b) => false,
        });

        columns.push({
          title: 'Std Dev',
          dataIndex: 'stddev',
          key: 'stddev',
          sorter: (a, b) => compareTo(a.stddev, b.stddev),
          render: (a) => (a && a !== 'NaN' ? a.toFixed(4) : null),
          shouldCellUpdate: (a, b) => false,
        });
      }
      return columns;
    }

    return [];
  };

  Font.register({
    family: 'Times-Roman',
  });

  Font.register({
    family: 'Times-Bold',
  });

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#ffffff',
    },
    section: {
      margin: 30,
      padding: 10,
    },
    disclaimer: {
      fontSize: '12pt',
      fontWeight: 'bold',
      fontFamily: 'Times-Bold',
      color: '#AB0000',
    },
    title: {
      margin: '20 0 10 0',
      fontSize: '12pt',
      fontWeight: 'bold',
      fontFamily: 'Times-Bold',
    },
    paragraph: {
      fontSize: '10pt',
      marginBottom: '20pt',
      fontWeight: 'normal',
      fontFamily: 'Times-Roman',
      lineHeight: 1.2,
    },
    text: {
      fontSize: '10pt',
      fontWeight: 'normal',
      fontFamily: 'Times-Roman',
      lineHeight: 1.2,
    },
    table: {
      width: '100%',
      borderWidth: 2,
      display: 'flex',
      flexDirection: 'column',
      marginVertical: 12,
    },
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    cell: {
      borderWidth: 0.1,
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      textAlign: 'left',
      fontFamily: 'Times-Roman',
      fontSize: '10pt',
      flexWrap: 'wrap',
    },
  });

  const rasterDataLoaded = () => {
    let selectedRRKDatasets = props.checkedDatasets.filter(
      (dataset) => dataset.data_type === 'raster'
    );

    if (selectedRRKDatasets.length > 0) {
      if (selectedRRKDatasets.length !== dataContents.length) {
        return false;
      }
      dataContents.forEach((item, index) => {
        if (dataContents[index].length === 0) return false;
      });
    }
    return true;
  };

  const ReportInPDF = () => {
    // process RRK
    let rrk_datasets = props.checkedDatasets.filter(
      (dataset) => dataset.data_type === 'raster'
    );

    let columns_list = [];
    let weightings_list = [];

    if (rrk_datasets.length > 0 && rasterDataLoaded()) {
      rrk_datasets.forEach((dataset, index) => {
        columns_list.push(
          getColumns(
            dataContents[index],
            dataset.report && dataset.report.method
              ? dataset.report.method
              : null
          )
        );
      });

      columns_list.forEach((columns) => {
        let weightings = [];
        let stats = columns
          .filter((column) =>
            ['avg', 'min', 'max', 'count', 'sum', 'stddev'].includes(
              column.dataIndex
            )
          )
          .map((column) => column.dataIndex);
        let others = columns.length - stats.length;
        let total = columns.length;
        if (total <= 6) {
          let left = 1 - stats.length * 0.15;
          let other_weighting = Math.floor((left / others) * 100) / 100;
          for (let i = 0; i < others; i++) {
            weightings.push(other_weighting);
          }
          stats.forEach((stat) => {
            weightings.push(0.15);
          });
        } else {
          let weighting = Math.floor((1 / others.length) * 100) / 100;
          columns.forEach((column) => {
            weightings.push(weighting);
          });
        }
        weightings_list.push(weightings);
      });
    }

    // process ITS
    let its_columns = getITSColumns(itsContents);
    let its_datasets = props.checkedDatasets.filter(
      (dataset) =>
        dataset.folder === '/Interagency Tracking System' &&
        dataset.mapping[0].name === 'Million Acres Flat File Points'
    );

    return (
      <Document>
        <Page size="A4" style={styles.page} wrap={false}>
          {/* <View key="disclaimer" style={styles.disclaimer}>
                        
                    </View> */}

          <View style={styles.section}>
            <PDFText style={styles.disclaimer}>
              Disclaimer: This report was created by the WLR Data Hub prototype
              for the purpose of gathering user feedback. The information
              contained in the report should not be used for any other purpose.
            </PDFText>
            {rrk_datasets.map((dataset, index) => (
              <View key={'dataset_' + index}>
                <PDFText style={styles.title}>{rrkTitles[index]}</PDFText>
                <PDFText style={styles.paragraph}>{rrkDescs[index]}</PDFText>
                {dataContents[index] && dataContents[index].length > 0 ? (
                  <PDFTable
                    style={{ border: '1px solid green' }}
                    tdStyle={{ padding: '2px' }}
                    weightings={weightings_list[index]}
                  >
                    <TH style={{ fontSize: 10 }}>
                      {columns_list[index].map((column) => (
                        <TD>{column.title}</TD>
                      ))}
                    </TH>
                    {dataContents[index].map((row, row_index) => (
                      <TR
                        key={'dataset_' + index + '_row_' + row_index}
                        style={{
                          fontSize: 8,
                          backgroundColor:
                            row_index % 2 === 0 ? '#ddd' : undefined,
                        }}
                      >
                        {columns_list[index].map((column) =>
                          column.dataIndex === 'avg' ||
                          column.dataIndex === 'min' ||
                          column.dataIndex === 'max' ||
                          column.dataIndex === 'stddev' ? (
                            <TD
                              key={
                                'dataset_' +
                                index +
                                '_row_' +
                                row_index +
                                '_' +
                                column.dataIndex
                              }
                            >
                              {row[column.dataIndex] === 'NaN' ||
                              row[column.dataIndex] === undefined ||
                              row[column.dataIndex] === null
                                ? // row[column.dataIndex]
                                  null
                                : row[column.dataIndex].toFixed(4)}
                            </TD>
                          ) : (
                            <TD
                              key={
                                'dataset_' +
                                index +
                                '_row_' +
                                row_index +
                                '_' +
                                column.dataIndex
                              }
                            >
                              {row[column.dataIndex]}
                            </TD>
                          )
                        )}
                      </TR>
                    ))}
                  </PDFTable>
                ) : null}
              </View>
            ))}
            {its_datasets.length > 0 && itsContents.length > 0 ? (
              <View>
                <PDFText style={styles.title}>{itsReportTitle}</PDFText>
                <PDFText style={styles.paragraph}>{itsReportIntro}</PDFText>
                <span style={{ fontSize: 'small' }}>
                  *Title and description have been created by conversational AI
                  and can be editted.
                </span>
                {itsContents.length > 0 ? (
                  <PDFTable
                    style={{ border: '1px solid green' }}
                    tdStyle={{ padding: '2px' }}
                    // weightings={weightings_list[index]}
                  >
                    <TH style={{ fontSize: 10 }}>
                      {its_columns.map((column) => (
                        <TD>{column.title}</TD>
                      ))}
                    </TH>
                    {itsContents.map((row, row_index) => (
                      <TR
                        key={'its_row_' + row_index}
                        style={{
                          fontSize: 8,
                          backgroundColor:
                            row_index % 2 === 0 ? '#ddd' : undefined,
                        }}
                      >
                        {its_columns.map((column) =>
                          column.dataIndex === 'sum' ? (
                            <TD
                              key={
                                'its_row_' + row_index + '_' + column.dataIndex
                              }
                            >
                              {row[column.dataIndex] === undefined ||
                              row[column.dataIndex] === 'NaN' ||
                              row[column.dataIndex] === null
                                ? // row[column.dataIndex]
                                  null
                                : row[column.dataIndex].toFixed(4)}
                            </TD>
                          ) : (
                            <TD
                              key={
                                'its_row_' + row_index + '_' + column.dataIndex
                              }
                            >
                              {row[column.dataIndex]}
                            </TD>
                          )
                        )}
                      </TR>
                    ))}
                  </PDFTable>
                ) : null}
              </View>
            ) : null}
          </View>
        </Page>
      </Document>
    );
  };

  return (
    <div>
      {props.checkedDatasets
        .filter((dataset) => dataset.data_type === 'raster')
        .map((dataset, index) => (
          <div
            key={props.getGisService(dataset).layer_name}
            style={{ marginTop: index > 0 ? 30 : 0 }}
          >
            {rrkTitlesEditings[index] ? (
              <Input
                value={rrkTitles[index]}
                onChange={(e) => {
                  let newTitles = [...rrkTitles];
                  newTitles[index] = e.target.value;
                  setRrkTitles(newTitles);
                }}
                onBlur={() => {
                  let newRrkTitlesEditings = [...rrkTitlesEditings];
                  newRrkTitlesEditings[index] = false;
                  setRrkTitlesEditings(newRrkTitlesEditings);
                }}
              />
            ) : (
              <Text
                strong
                onClick={() => {
                  let newRrkTitlesEditings = [...rrkTitlesEditings];
                  newRrkTitlesEditings[index] = true;
                  setRrkTitlesEditings(newRrkTitlesEditings);
                }}
              >
                {rrkTitles[index]}
              </Text>
            )}
            {rrkDescsEditings[index] ? (
              <TextArea
                value={rrkDescs[index]}
                style={{ margin: '10pt 0pt 20pt 0pt' }}
                autoSize={{ minRows: 10, maxRows: 50 }}
                onChange={(e) => {
                  let newDescs = [...rrkDescs];
                  newDescs[index] = e.target.value;
                  setRrkDescs(newDescs);
                }}
                onBlur={() => {
                  let newEditings = [...rrkDescsEditings];
                  newEditings[index] = false;
                  setRrkDescsEditings(newEditings);
                }}
              />
            ) : (
              <div
                style={{ margin: '10pt 0pt 20pt 0pt' }}
                onClick={() => {
                  let newEditings = [...rrkDescsEditings];
                  newEditings[index] = true;
                  setRrkDescsEditings(newEditings);
                }}
              >
                {rrkDescs[index]}
              </div>
            )}
            <div style={{ margin: '10pt' }}>
              {dataContents[index] && dataContents[index].length > 0 ? (
                <Table
                  virtualized={true}
                  dataSource={dataContents[index]}
                  columns={getColumns(
                    dataContents[index],
                    dataset.report && dataset.report.method
                      ? dataset.report.method
                      : null
                  )}
                  rowKey={`z_${main_columns[props.selectedFeatureLayer.file_path]}`}
                  pagination={false}
                />
              ) : reportError ? (
                <div style={{ fontWeight: 'bold', color: 'red' }}>
                  Encounter the error "{reportError}" when creating the report.
                </div>
              ) : (
                <>
                  <Spin size="small" />
                  {/* <Progress type="circle" percent={percent} width={80}/> */}
                </>
              )}
            </div>
          </div>
        ))}
      {props.checkedDatasets.filter((dataset) => dataset.data_type === 'vector')
        .length > 0 && itsContents.length > 0 ? (
        <div
          key={'its'}
          style={{
            marginTop:
              props.checkedDatasets.filter(
                (dataset) => dataset.data_type === 'raster'
              ).length > 0
                ? 30
                : 0,
          }}
        >
          {itsReportTitleEditing ? (
            <Input
              value={itsReportTitle}
              onChange={(e) => {
                setItsReportTitle(e.target.value);
              }}
              onBlur={() => {
                setItsReportTitleEditing(false);
              }}
            />
          ) : (
            <Text
              strong
              onClick={() => {
                setItsReportTitleEditing(true);
              }}
            >
              {itsReportTitle}
            </Text>
          )}
          {itsReportIntroEditing ? (
            <TextArea
              value={itsReportIntro}
              onChange={(e) => {
                setItsReportIntro(e.target.value);
              }}
              onBlur={() => {
                setItsReportIntroEditing(false);
              }}
              autoSize={{ minRows: 10, maxRows: 50 }}
              style={{ margin: '10pt 0pt 20pt 0pt' }}
            />
          ) : (
            <div
              style={{ margin: '10pt 0pt 20pt 0pt' }}
              onClick={() => {
                setItsReportIntroEditing(true);
              }}
            >
              {itsReportIntro}
            </div>
          )}
          <div style={{ margin: '10pt 10pt 20pt 10pt' }}>
            {itsContents.length === 0 ? (
              <>
                <Spin size="small" />
                {/* <Progress type="circle" percent={percent} width={80}/> */}
              </>
            ) : (
              <Table
                dataSource={itsContents}
                columns={getITSColumns(itsContents)}
                rowKey={`z_${main_columns[props.selectedFeatureLayer.file_path]}`}
                pagination={false}
              />
            )}
          </div>
        </div>
      ) : null}
      {(dataContents.length > 0 && rasterDataLoaded()) ||
      itsContents.length > 0 ? (
        <div className="App" style={{ marginBottom: 20 }}>
          <PDFDownloadLink
            document={<ReportInPDF />}
            fileName={`wfr_data_report_${new Date().getTime()}.pdf`}
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                'Loading document...'
              ) : (
                <Button
                  key={new Date().getTime()}
                  type="primary"
                  style={{
                    backgroundColor: '#1F7D1F',
                    borderColor: 'white',
                    marginTop: 10,
                    marginLeft: 20,
                  }}
                >
                  Download Report in PDF
                </Button>
              )
            }
          </PDFDownloadLink>
        </div>
      ) : null}
    </div>
  );
};

export default WFSDataReport;
