import { Modal, Button } from 'react-bootstrap';

const DeleteModal = (props) => {
  const { onHide, data, onDelete } = props;

  return (
    <Modal
      show={true}
      onHide={onHide}
      size="xl"
      aria-labelledby="view_more_modal"
      centered
    >
      <Modal.Header closeButton>
        <h4>Delete {data.name}?</h4>
      </Modal.Header>
      <Modal.Footer>
        <Button
          style={{
            backgroundColor: '#a64500',
            marginRight: '4px',
            border: 'none',
          }}
          onClick={() => {
            onDelete();
            onHide();
          }}
        >
          Delete
        </Button>
        <Button variant="secondary" onClick={() => onHide()}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default DeleteModal;
