import * as React from 'react';
import OlMap from 'ol/Map';
import OlView from 'ol/View';
import GeoJSON from 'ol/format/GeoJSON';
import FeatureFormat from 'ol/format/Feature';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import OlLayerTile from 'ol/layer/Tile';
import OlSourceTileWMS from 'ol/source/TileWMS';
import ScaleLine from 'ol/control/ScaleLine';
import VectorSource from 'ol/source/Vector';
import { Vector as VectorLayer } from 'ol/layer';
import EsriJSON from 'ol/format/EsriJSON';
import { tile as tileStrategy } from 'ol/loadingstrategy';
import { createXYZ } from 'ol/tilegrid';
import config from './config';
import ForestDataMapPopover from './ForestDataMapPopover';
import ForestDataMapLayerTree from './ForestDataMapLayerTree';
import ForestDataMapQueryTool from './ForestDataMapQueryTool';
import ForestDataMapIntersect from './ForestDataMapIntersect';
import ForestDataMapReset from './ForestDataMapReset';

import { getMinMax, getSLDForLayer } from './utils';
import ForestDataMapSaveWorkspace from './ForestDataMapSaveWorkspace';
import DragDropSupport from './DragDropSupport';
import { UserContext } from '../UserContext';
import DatasetCards from '../components/DatasetCard';
import { useEffect } from 'react';
import { Modal } from 'antd';
import ForestDataMapLayer from './ForestDataMapLayer';

export default class ForestDataMap extends React.Component {
  constructor(props) {
    super(props);
    this.mapDivId = `map-${Math.random()}`;

    this.state = {
      queryLayerInfo: [],
      checkedQuerysets: {},
      queryLayers: [],
      layersByUser: [],
      reminder_visible: false,
      loading_datasets: {},
      filter_options: {},
      categories: [
        // "Watershed & Habitat Improvement",
        'Tree Planting',
        'Timber Harvest',
        'Sanitation & Salvage',
        'Mechanical and Hand Fuels Reduction',
        'Grazing',
        'Beneficial Fire',
        'Land Protection',
        'Not Defined',
        'Not Reported',
      ],
      owners: [
        'Federal',
        'Local',
        'NGO',
        'Private Industry',
        'Private Non-Industry',
        'State',
        'Tribal',
        'Not Reported',
      ],
      agencies: [
        'CA Environmental Protection Agency',
        'CA State Transportation Agency',
        'CA Natural Resources Agency',
        'Department of Defense',
        'Department of the Interior',
        'Department of Agriculture',
        'CA Air Resources Board',
        'Timber Companies',
        'Other',
        'Not Reported',
      ],
    };

    this.setCheckedQuerysets = this.setCheckedQuerysets.bind(this);
    this.setQueryLayers = this.setQueryLayers.bind(this);
    this.setQueryLayerInfo = this.setQueryLayerInfo.bind(this);
    this.getQueryData = this.getQueryData.bind(this);
    this.addLayerByUser = this.addLayerByUser.bind(this);
    this.setCategories = this.setCategories.bind(this);
    this.setAgencies = this.setAgencies.bind(this);
    this.setOwners = this.setOwners.bind(this);
    this.setReminderVisible = this.setReminderVisible.bind(this);
    this.setLoadingDatasets = this.setLoadingDatasets.bind(this);
    this.setFilterOptions = this.setFilterOptions.bind(this);

    // this is a backup base map layer for Google Terrain Map
    /*
        this.backgroundLayer = new TileLayer({
            source: new Stamen({
                crossOrigin: "anonymous",
                layer: 'terrain'
            })
        });
        */

    this.backgroundLayer = new TileLayer({
      source: new XYZ({
        crossOrigin: 'anonymous',
        url: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',
      }),
    });

    this.layers = [];
    for (var i = 0; i < this.props.selectedDatasets.length; i++) {
      let item = this.props.selectedDatasets[i];
      let gis_service = props.getGisService(item);
      let key = props.getItemKey(item);
      if (item.data_type === 'raster') {
        let min = item.dataset_metadata.filter((e) => e.name === 'min_value')[0]
          .float_value;
        let max = item.dataset_metadata.filter((e) => e.name === 'max_value')[0]
          .float_value;

        this.layers.push(
          new OlLayerTile({
            visible: false,
            opacity: this.props.opacities[key] ?? 0.7,
            type: 'WMS',
            source: new OlSourceTileWMS({
              url: gis_service.service_url,
              crossOrigin: 'anonymous',
              params: {
                LAYERS: gis_service.layer_name,
                STYLES: undefined,
                SLD_BODY: getSLDForLayer(
                  item.gis_services[0].layer_name,
                  gis_service.color_map.color_map_entries.length > 0
                    ? gis_service.color_map.color_map_entries[0].color
                    : '#D6B2FF',
                  gis_service.color_map.color_map_entries.length > 1
                    ? gis_service.color_map.color_map_entries[1].color
                    : '#3B0875',
                  max,
                  min,
                  max,
                  min
                ),
                TILED: true,
              },
              serverType: 'geoserver',
            }),
          })
        );
      } else {
        let service_info = this.props.getGisService(item);
        if (service_info.service_type === 'ArcGIS') {
        } else {
          this.layers.push(
            new OlLayerTile({
              visible: true, //i==0,
              opacity: this.props.opacities[key] ?? 0.7,
              type: 'WMS',
              zIndex: 100 - i,
              // type: 'WMSTime',
              // timeFormat: 'YYYY-MM-DD',
              // roundToFullHours: false,
              source: new OlSourceTileWMS({
                url: service_info.service_url,
                crossOrigin: 'anonymous',
                params: {
                  LAYERS: service_info.layer_name,
                  TILED: true,
                  // 'cql_filter': item.cql_filter
                },
              }),
            })
          );
        }
      }
    }

    this.map = new OlMap({
      layers: [
        this.backgroundLayer,
        // this.rrkBoundariesLayer,
        ...this.layers,
        ...this.state.queryLayers,
      ],
      view: new OlView({
        projection: 'EPSG:4326',
        center: [-119.4179, 36.7783], //[-118.4194, 33.9592],
        zoom: 6.2, // 7.8
      }),
    });

    this.map.on('loadstart', function () {
      document.getElementById('map').classList.add('spinner');
    });

    this.map.on('loadend', function () {
      document.getElementById('map').classList.remove('spinner');
    });

    this.map.addControl(
      new ScaleLine({
        units: 'us',
      })
    );
  }

  async setCategories(categories) {
    this.setState({ categories: categories });
  }

  async setAgencies(agencies) {
    this.setState({ agencies: agencies });
  }

  async setOwners(owners) {
    this.setState({ owners: owners });
  }

  async setReminderVisible(value) {
    this.setState({ reminder_visible: value });
  }

  static contextType = UserContext;

  componentDidMount() {
    this.map.setTarget(this.mapDivId);
    this.setState({
      valueRanges: getMinMax(),
    });

    // load data from indexedDB
    let openRequest = indexedDB.open('WFR_DB', 1);
    openRequest.onsuccess = function () {
      let db = openRequest.result;
      let transaction = db.transaction('data', 'readwrite');
      let objectStore = transaction.objectStore('data');
      let request = objectStore.getAll();
      request.onerror = function (event) {};

      request.onsuccess = function (event) {
        if (request.result) {
          for (let i = 0; i < request.result.length; i++) {
            let fileContent = request.result[i];
            fileContent.definition = JSON.parse(fileContent.definition);
          }
        } else {
        }
      };
    };

    this.setReminderVisible(true);
  }

  componentDidUpdate(prevProps) {
    document.getElementById('map_container').style.display = 'Block';

    for (var i = 0; i < this.props.selectedDatasets.length; i++) {
      let item = this.props.selectedDatasets[i];
      let gis_services = this.props.getGisService(item);
      let found = false;
      for (var j = 0; j < this.layers.length; j++) {
        if (
          gis_services.service_url ===
            this.layers[j].getProperties().service_url &&
          gis_services.layer_name === this.layers[j].getProperties().layer_name
        ) {
          found = true;
          break;
        }
      }

      let itemKey = this.props.getItemKey(item);

      if (!found) {
        let newLoading = { ...this.state.loading_datasets };
        newLoading[itemKey] = true;
        const setLoading = async (newLoading) => {
          await this.setLoadingDatasets(newLoading);
        };

        let is_visible =
          itemKey in this.props.checkedDatasets
            ? this.props.checkedDatasets[itemKey]
            : true;
        setLoading(newLoading);

        let layer = null;
        if (item.type) {
          // New CLM
          layer = new OlLayerTile({
            visible: is_visible,
            opacity: this.props.opacities[itemKey] ?? 0.6,
            type: 'WMS',
            layer_name: gis_services.layer_name,
            service_url: gis_services.service_url,
            source: new OlSourceTileWMS({
              url: gis_services.service_url,
              crossOrigin: 'anonymous',
              params: {
                LAYERS: gis_services.layer_name,
                STYLES: undefined,
                TILED: true,
              },
              serverType: 'geoserver',
            }),
          });
        } else if (
          item.data_type === 'raster' ||
          (item.data_type === 'vector' &&
            item.gis_services.filter((e) => e.service_type === 'ArcGIS')
              .length === 0)
        ) {
          // Old CLM
          layer = new OlLayerTile({
            visible: is_visible,
            opacity: this.props.opacities[itemKey] ?? 0.7,
            type: 'WMS',
            layer_name: gis_services.layer_name,
            service_url: gis_services.service_url,
            source: new OlSourceTileWMS({
              url: gis_services.service_url,
              crossOrigin: 'anonymous',
              params: {
                LAYERS: gis_services.layer_name,
                STYLES: undefined,
                TILED: true,
              },
              serverType: 'geoserver',
            }),
          });
        } else {
          const gis_service = this.props.getGisService(item);
          const serviceUrl = gis_service.service_url;
          const layer_name = gis_service.layer_name;

          const vectorSource = new VectorSource({
            format: new EsriJSON(),
            url: function (extent, resolution, projection) {
              // ArcGIS Server only wants the numeric portion of the projection ID.
              const srid = projection
                .getCode()
                .split(/:(?=\d+$)/)
                .pop();

              const url =
                serviceUrl +
                '/query/?f=json&' +
                'returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=' +
                encodeURIComponent(
                  '{"xmin":' +
                    extent[0] +
                    ',"ymin":' +
                    extent[1] +
                    ',"xmax":' +
                    extent[2] +
                    ',"ymax":' +
                    extent[3] +
                    ',"spatialReference":{"wkid":' +
                    srid +
                    '}}'
                ) +
                '&geometryType=esriGeometryEnvelope&inSR=' +
                srid +
                '&outFields=*' +
                '&outSR=' +
                srid;

              return url;
            },
            strategy: tileStrategy(
              createXYZ({
                tileSize: 512,
              })
            ),
          });
          // Forest & Wildland Stewardship Interagency Tracking System
          layer = new VectorLayer({
            visible: is_visible,
            source: vectorSource,
            layer_name: layer_name,
            service_url: serviceUrl,
            opacity: this.props.opacities[itemKey] ?? 0.7,
          });
        }

        this.map.addLayer(layer);
        this.layers.push(layer);

        if (itemKey in this.props.checkedDatasets === false) {
          this.props.checkedDatasets[
            layer.getProperties().service_url +
              '/' +
              layer.getProperties().layer_name
          ] = true;
        }

        newLoading = { ...this.state.loading_datasets };
        newLoading[itemKey] = false;

        setLoading(newLoading);
      }
    }

    // this.map.getLayers().forEach(layer => {
    this.layers.forEach((layer) => {
      let found = false;

      let layer_layer_name = layer.getProperties().layer_name;
      let layer_serviceUrl = layer.getProperties().service_url;

      for (i = 0; i < this.props.selectedDatasets.length; i++) {
        let item = this.props.selectedDatasets[i];
        let gis_services = this.props.getGisService(item);
        let url = gis_services.service_url;
        let layer_name = gis_services.layer_name;

        if (
          layer &&
          url === layer_serviceUrl &&
          layer_name === layer_layer_name
        ) {
          found = true;
          break;
        }
      }

      if (!found) {
        for (i = 0; i < this.state.queryLayers.length; i++) {
          let itemLayerId =
            this.state.queryLayers[i].getProperties()['layerId'];

          if (
            layer &&
            layer.getProperties &&
            itemLayerId === layer.getProperties()['layerId']
          ) {
            found = true;
            break;
          }
        }
      }

      if (!found && layer) {
        this.map.removeLayer(layer);
      }
    });

    let tmp = [];
    for (j = 0; j < this.layers.length; j++) {
      let layer = this.layers[j];
      let found = false;
      for (i = 0; i < this.props.selectedDatasets.length; i++) {
        let item = this.props.selectedDatasets[i];
        let gis_services = this.props.getGisService(item);
        let url = gis_services.service_url;
        let layer_name = gis_services.layer_name;

        if (
          layer &&
          url === layer.getProperties().service_url &&
          layer_name === layer.getProperties().layer_name
        ) {
          found = true;
          break;
        }
      }

      if (!found) {
        // pass
      } else {
        tmp.push(layer);
      }
    }

    this.layers = tmp;

    // make sure layers are appropriately visible
    this.layers.forEach((e) => {
      let key =
        e.getProperties().service_url + '/' + e.getProperties().layer_name;
      if (
        key in this.props.checkedDatasets &&
        e.getVisible() !== this.props.checkedDatasets[key]
      ) {
        e.setVisible(this.props.checkedDatasets[key]);
      }
    });

    this.map.getLayers().forEach((e) => {
      let key =
        e.getProperties().service_url + '/' + e.getProperties().layer_name;
      if (key in this.props.checkedDatasets) {
        e.setVisible(this.props.checkedDatasets[key]);
      }
    });
  }

  setFilterOptions(options) {
    this.setState({ filter_options: options });
  }

  setCheckedQuerysets(checkedQuerysets) {
    this.setState({ checkedQuerysets });
  }

  setQueryLayers(queryLayers) {
    this.setState({ queryLayers: queryLayers });
  }

  setQueryLayerInfo(queryLayerInfo) {
    this.setState(queryLayerInfo);
  }

  setLoadingDatasets(loading_datasets) {
    this.setState({ loading_datasets: loading_datasets });
  }

  getQueryData() {
    let datasetInfo = {};
    this.props.selectedDatasets.forEach((layer) => {
      let key = this.props.getItemKey(layer);
      if (
        layer &&
        this.props.checkedDatasets[key] !== undefined &&
        this.props.checkedDatasets[key]
      ) {
        if (this.props.filterItems[key] !== undefined) {
          datasetInfo[key] = this.props.filterItems[key];
        }
      }
    });

    return datasetInfo;
  }

  addLayerByUser = (layer) => {
    let newLayersByUser = [...this.state.layersByUser];
    newLayersByUser.push(layer);
    this.setState({
      layersByUser: newLayersByUser,
    });
  };

  render() {
    return (
      <div style={{ display: this.props.display === 'map' ? 'block' : 'none' }}>
        <div
          id="map_container"
          style={{ height: 'calc(100vh - 45px)', position: 'relative' }}
        >
          <div
            id={this.mapDivId}
            style={{ height: 'calc(100vh - 45px)', marginRight: '350px' }}
          />
          <ForestDataMapPopover
            map={this.map}
            layers={this.layers}
            valueRanges={this.props.valueRanges}
            selectedDatasets={this.props.selectedDatasets}
            getGisService={this.props.getGisService}
            getItemKey={this.props.getItemKey}
          />
          {/* <ForestDataMapQueryTool map={this.map} layers={this.layers}
                                        queryLayers={this.state.queryLayers}
                                        setQueryLayers={this.setQueryLayers}
                                        setQueryLayerInfo={this.setQueryLayerInfo}
                                        getQueryData={this.getQueryData}   
                                        queryLayerInfo={this.state.queryLayerInfo}
                                        checkedQuerysets={this.state.checkedQuerysets}                                  
                                        setCheckedQuerysets={this.setCheckedQuerysets}    
                                        access_token={this.context.user.access_token}                               
                />     */}
          {/* <ForestDataMapIntersect map={this.map} layers={this.layers}
                                        queryLayers={this.state.queryLayers}
                                        setQueryLayers={this.setQueryLayers}
                                        setQueryLayerInfo={this.setQueryLayerInfo}
                                        getQueryData={this.getQueryData}   
                                        queryLayerInfo={this.state.queryLayerInfo}
                                        checkedQuerysets={this.state.checkedQuerysets}                                  
                                        setCheckedQuerysets={this.setCheckedQuerysets}  /> */}

          {/* <ForestDataMapDownload map={this.map} layers={this.layers} 
                                       checkedDatasets={this.props.checkedDatasets}
                                       selectedDatasets={this.props.selectedDatasets}
                                       valueRanges={this.props.valueRanges}
                                       filterItems={this.props.filterItems}  
                                       getQueryData={this.getQueryData}    
                                       access_token={this.context.user.access_token}  
                                       /> */}
          <ForestDataMapReset map={this.map} layers={this.layers} />
          <ForestDataMapLayer map={this.map} layers={this.layers} />
          <ForestDataMapLayerTree
            map={this.map}
            layers={this.layers}
            layersByUser={this.state.layersByUser}
            setValueRanges={this.props.setValueRanges}
            setQueryLayers={this.setQueryLayers}
            setDisplay={this.props.setDisplay}
            setCategory={this.props.setCategory}
            // setSelectedKeys={this.props.setSelectedKeys}
            // setDefaultKeys={this.props.setDefaultKeys}
            filter_options={this.state.filter_options}
            setFilterOptions={this.setFilterOptions}
            queryLayers={this.state.queryLayers}
            valueRanges={this.props.valueRanges}
            checkedDatasets={this.props.checkedDatasets}
            checkedQuerysets={this.state.checkedQuerysets}
            setCheckedQuerysets={this.setCheckedQuerysets}
            setCheckedDatasets={this.props.setCheckedDatasets}
            queryLayerInfo={this.state.queryLayerInfo}
            setQueryLayerInfo={this.setQueryLayerInfo}
            agencies={this.state.agencies}
            owners={this.state.owners}
            categories={this.state.categories}
            setAgencies={this.setAgencies}
            setCategories={this.setCategories}
            setOwners={this.setOwners}
            getGisService={this.props.getGisService}
            getItemKey={this.props.getItemKey}
            filtersDatasets={this.props.filtersDatasets}
            setFilterDatasets={this.props.setFilterDatasets}
            loading_datasets={this.state.loading_datasets}
            setLoadingDatasets={this.setLoadingDatasets}
            filterItems={this.props.filterItems}
            setFilterItems={this.props.setFilterItems}
            opacities={this.props.opacities}
            setOpacities={this.props.setOpacities}
            access_token={this.context.user.access_token}
          />
          {/* <ForestDataMapSaveWorkspace map={this.map} layer={this.layers}
                                        selectedDatasets={this.props.selectedDatasets}
                                        checkedDatasets={this.props.checkedDatasets}    
                                        access_token={this.context.user.access_token}  
                                        jwtToken={this.context.user.jwtToken} 
                                        valueRanges={this.props.valueRanges}    
                                        filterItems={this.props.filterItems}  
                                        getItemKey={this.props.getItemKey}
                                        opacities={this.props.opacities}
                                        setOpacities={this.props.setOpacities}                                                           
                /> */}
          <DragDropSupport
            map={this.map}
            addLayerByUser={this.addLayerByUser}
          />
        </div>
        {/* <Modal 
                title="Reminder"
                open={this.state.reminder_visible}
                onOk={() => this.setReminderVisible(false)}
                okText="I understand"
                cancelButtonProps={{
                   disabled: true ,
                  }}
                style={{
                    minWidth:'100px',
                    zIndex: -1
                }}
                >                            
                <div>
                    This is a prototype and data available here is only provided for the purpose of gathering user feedback. Please acknowledge that you understand and will not use this data. 
                </div>
            </Modal> */}
      </div>
    );
  }
}
