import React from 'react';
import {
  Modal,
  Alert,
  Avatar,
  Form,
  Input,
  Row,
  Col,
  notification,
  Typography,
  Spin,
  Space,
} from 'antd';
import 'antd/dist/antd.css';
import './query.css';
import { Button, Dropdown } from 'antd';
import {
  LoginOutlined,
  UserAddOutlined,
  UserOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import ForestDataMapWorkspaces from './ForestDataMapWorkspaces';
import ForestDataMapFeedback from './ForestDataMapFeedback';
import Sparcal from '../lib/Sparcal';

// const {Text} = Typography;

//create table script
// CREATE TABLE fdh.useraccount
// (
// 	user_id serial PRIMARY KEY,
// 	username VARCHAR UNIQUE NOT NULL,
// 	password_hash VARCHAR NOT NULL,
// 	created_on TIMESTAMP NOT NULL,
// 	last_login TIMESTAMP
// )

// let error = false
// let errorDescription = ''

class ForestDataLogin extends React.Component {
  static iconStyle = { paddingRight: '5px' };

  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      createVisible: false,
      loginVisible: false,
      error: false,
      errorDescription: null,
      user: {},
      itemsLoggedIn: [],
      iconStyle: this.iconStyle,
      button_disabed: false,
      passwordReset: false,
      responseData: null,
    };

    this.toggleHover = this.toggleHover.bind(this);
    this.showCreateGUI = this.showCreateGUI.bind(this);
    this.showLoginGUI = this.showLoginGUI.bind(this);
    this.hideCreateGUI = this.hideCreateGUI.bind(this);
    this.hideLoginGUI = this.hideLoginGUI.bind(this);
    this.logout = this.logout.bind(this);

    // error handling doesnt display properly
    this.setErrorState = this.setErrorState.bind(this);
    this.setErrorDescriptionState = this.setErrorDescriptionState.bind(this);
  }

  toggleHover() {
    this.setState({ hover: !this.state.hover });
  }
  togglePasswordReset = () => {
    this.setState({ passwordReset: !this.state.passwordReset });
  };

  getMenu = () => {
    return this.state.menu;
  };

  setMenu() {
    if (this.state.user != null) {
      this.setState({ menu: ForestDataLogin.tmpitems });
    } else {
      this.setState({ menu: ForestDataLogin.noItems });
    }
  }

  showLoginGUI() {
    this.setState({
      loginVisible: true,
    });
  }

  showCreateGUI() {
    this.setState({
      createVisible: true,
    });
  }

  hideLoginGUI() {
    this.setState({
      loginVisible: false,
      error: false,
    });
  }

  hideCreateGUI() {
    this.setState({
      createVisible: false,
      error: false,
    });
  }

  onFinishFailed = (errorInfo) => {
    this.setState({ error: true });
  };

  setErrorState(error) {
    this.setState({ error: error });
  }

  setErrorDescriptionState(description) {
    this.setState({ errorDescription: description });
  }

  logout() {
    this.props.updateUser({});
    sessionStorage.removeItem('user');
    window.location.reload();
  }

  onLoginFinish = async (values) => {
    // TODO: fix so un/pw are encrypted
    let base_url = process.env.REACT_APP_API_URL;
    let formData = new FormData();
    formData.append('username', values.username);
    formData.append('password', values.password);

    const response = await fetch(`${base_url}/Auth/login`, {
      method: 'POST',
      body: formData,
      // headers: {
      // 	'Accept': 'application/json',
      // 	'Content-Type': 'application/json',
      //     'mode': 'no-cors'
      // },
      // body: JSON.stringify({
      // 	"username": values['username'],
      // 	"password": values['password']
      // })
    })
      .then((response) => response.json())
      .then((responseData) => {
        return responseData;
      })
      .catch((error) => {});

    if (response.access_token != null) {
      this.setMenu();
      this.props.updateUser(response);
      this.setState({ menu: ForestDataLogin.noItems });
      this.hideLoginGUI();
      sessionStorage.setItem('user', JSON.stringify(response));
    } else {
      this.setState({
        errorDescription: response.detail,
        error: true,
      });
    }
  };

  onResetFinish = async (email) => {
    const response = await Sparcal.postPasswordReset(email);
    this.setState({ responseData: response });
  };

  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  openNotification(placement, message) {
    notification.info({
      message: message,
      icon: <CheckCircleOutlined />,
      style: { color: 'green' },
      placement,
    });
  }

  onCreateAccount = async (values) => {
    // if password and confirm password do not match, return error
    if (values['password'] !== values['confirm_password']) {
      this.setState({
        errorDescription:
          'Your password and confirmation password do not match',
        error: true,
      });
      return;
    }

    this.setState({ button_disabed: true });

    let base_url = process.env.REACT_APP_API_URL;
    let formData = new FormData();
    formData.append('username', values['username']);
    formData.append('password', values['password']);
    formData.append('firstname', values['first_name']);
    formData.append('lastname', values['last_name']);
    formData.append('email', values['email']);
    formData.append('agency', values['agency']);

    const response = await fetch(`${base_url}/Auth/user`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: values['username'],
        password: values['password'],
        firstname: values['first_name'],
        lastname: values['last_name'],
        email: values['email'],
        agency: values['agency'],
      }),
    })
      .then((response) => response.json())
      .then((responseData) => {
        return responseData;
      })
      .catch((error) => error);

    if (response['detail']) {
      this.setState({
        errorDescription: response['detail'],
        error: true,
      });
    } else {
      this.openNotification('top', response['message']);
      this.hideCreateGUI();
    }

    this.setState({ button_disabed: false });
  };

  render() {
    let items = [];
    if (
      Object.keys(this.props.user).length === 0 ||
      this.props.user?.access_token === undefined
    ) {
      items = [
        {
          key: '1',
          label: (
            <a onClick={this.showLoginGUI}>
              <LoginOutlined style={this.iconStyle} /> Login
            </a>
          ),
        },
        {
          key: '2',
          label: (
            <a onClick={this.showCreateGUI}>
              <UserAddOutlined style={this.iconStyle} /> Create Account
            </a>
          ),
        },
      ];
    } else {
      let firstLetter = this.props.user.name_first;

      items = [
        // {
        // 	key: '1',
        // 	label: <><UserOutlined /> Your Info</>,
        // 	disabled: true,
        // // },
        // {
        //     key:'2',
        //     label: (
        //         <ForestDataMapWorkspaces access_token={this.props.user.access_token}  getGisService={this.props.getGisService}
        //         getItemKey={this.props.getItemKey} setFilterItems={this.props.setFilterItems}
        //         setCheckedDatasets={this.props.setCheckedDatasets}
        //         opacities={this.props.opacities}
        //         setOpacities={this.props.setOpacities}
        //         />
        //     )
        // },
        {
          key: '3',
          label: (
            <ForestDataMapFeedback
              access_token={this.props.user.access_token}
            />
          ),
        },
        {
          key: '4',
          label: (
            <a onClick={this.logout}>
              <LoginOutlined style={this.iconStyle} /> Logout
            </a>
          ),
        },
      ];
    }

    const onResetFinish = this.onResetFinish;
    const onLoginFinish = this.onLoginFinish;
    const onFinishFailed = this.onFinishFailed;
    const onCreateAccount = this.onCreateAccount;

    return (
      <div style={{ alignSelf: 'end', margin: '5px' }}>
        <Dropdown menu={{ items }} trigger={['click']} placement="bottomRight">
          {Object.keys(this.props.user).length === 0 ||
          this.props.user?.username === undefined ? (
            <Avatar
              // style={{ backgroundColor: '#737149'}}
              style={{ backgroundColor: '#eeede2' }}
              icon={<UserOutlined style={{ color: '#525335' }} />}
              size={'small'}
            />
          ) : (
            <Avatar style={{ backgroundColor: '#eeede2' }} size={'small'}>
              {this.props.user.username.charAt(0).toUpperCase()}
            </Avatar>
          )}
        </Dropdown>

        <Modal
          title="Login to Task Force Data Hub Website"
          open={this.state.loginVisible}
          onCancel={this.hideLoginGUI}
          destroyOnClose={true}
          footer={null}
          width={700}
        >
          <div>
            {this.state.error ? (
              <Alert
                style={{ marginBottom: '15px' }}
                message={
                  this.state.errorDescription ??
                  'Please fill in all required fields.'
                }
                type="error"
                closable
                showIcon
              />
            ) : null}
            <Form
              name="login"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 24,
              }}
              layout="vertical"
              onFinish={
                !this.state.passwordReset ? onLoginFinish : onResetFinish
              }
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              scrollToFirstError="true"
            >
              {!this.state.passwordReset ? (
                <>
                  <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your username',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password',
                      },
                    ]}
                    style={{ marginBottom: '4px' }}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item>
                    <Button type="link" onClick={this.togglePasswordReset}>
                      Forgot Password?
                    </Button>
                  </Form.Item>
                  <Form.Item style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit">
                      Login
                    </Button>
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item style={{ textAlign: 'center' }}>
                    <Space>
                      {' '}
                      <Button type="primary" htmlType="submit">
                        Reset Password
                      </Button>
                      <Button onClick={this.togglePasswordReset}>
                        Return to login
                      </Button>
                    </Space>
                  </Form.Item>
                  {this.state.responseData &&
                    (this.state.responseData.status === 200 ? (
                      <p>{this.state.responseData.message}</p>
                    ) : (
                      <p>Email not found</p>
                    ))}
                </>
              )}
            </Form>
          </div>
        </Modal>

        <Modal
          title="Create Account for Task Force Data Hub"
          open={this.state.createVisible}
          onCancel={this.hideCreateGUI}
          destroyOnClose={true}
          footer={null}
          width={700}
        >
          <div>
            {this.state.error ? (
              <Alert
                style={{ marginBottom: '15px' }}
                message={
                  this.state.errorDescription ??
                  'Please fill in all required fields.'
                }
                type="error"
                closable
                showIcon
              />
            ) : null}
            <Form
              name="create_account"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 24,
              }}
              layout="vertical"
              onFinish={onCreateAccount}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your First Name',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Last Name"
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Last Name',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Agency"
                    name="agency"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your agency',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please input your username',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirm_password"
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item style={{ textAlign: 'center' }}>
                {this.state.button_disabed ? (
                  <Button type="primary" htmlType="submit" disabled>
                    <Spin />
                  </Button>
                ) : (
                  <Button type="primary" htmlType="submit">
                    Create Account
                  </Button>
                )}
                {/* <Button type="primary" htmlType="submit" >
                            Create Account
                        </Button> */}
              </Form.Item>
              <div style={{ textAlign: 'center' }}>
                <div>An email will be sent to the provided address. </div>If you
                don’t see it in your inbox, please check your spam folder.
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ForestDataLogin;
