import React, { useEffect, useState } from 'react';

import { Typography, Button } from 'antd';
import { Routes, Route, useParams } from 'react-router-dom';

const { Title } = Typography;
// TODO fix function and file name
function VerifyAccount() {
  const { username, access_token } = useParams();
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const verify = async () => {
      let base_url = process.env.REACT_APP_API_URL;

      const response = await fetch(
        `${base_url}/Auth/verify_account/${username}/${access_token}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      )
        .then((response) => response.json())
        .then((responseData) => {
          return responseData;
        })
        .catch((error) => {});

      let result = response;

      setMessage(result.message);

      return result;
    };

    verify().catch(console.error);
  }, []);

  return (
    <div>
      <Title level={3} style={{ textAlign: 'center', marginTop: '1em' }}>
        {message}
      </Title>
      <div style={{ textAlign: 'center' }}>
        <Button type="primary" href="/">
          Return to Home Page
        </Button>
      </div>
    </div>
  );
}

export default VerifyAccount;
