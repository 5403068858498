const PermissionsHelper = (userRoles, permision) => {
  if (!userRoles) return false;
  if (permision === 'any') {
    return userRoles.length > 0;
  } else if (permision === 'approver') {
    return userRoles.includes('approver') || userRoles.includes('admin');
  } else if (permision === 'publisher') {
    return userRoles.includes('publisher') || userRoles.includes('admin');
  } else if (permision === 'admin') {
    return userRoles.includes('admin');
  } else {
    return false;
  }
};
export default PermissionsHelper;
