import React from 'react';
import { useState, useEffect } from 'react';
import { FilterOutlined } from '@ant-design/icons';
import { Modal, Typography, Alert, Avatar, Form, Input } from 'antd';
import 'antd/dist/antd.css';
import './query.css';
import { Tooltip, Button, notification, Dropdown, Space } from 'antd';
import {
  LoginOutlined,
  CheckCircleOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { UserContext } from '../UserContext';

const { Text } = Typography;

class ForestDataMapSaveWorkspace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      visible: false,
    };

    this.toggleHover = this.toggleHover.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.toggleHover = this.toggleHover.bind(this);
  }

  static contextType = UserContext;

  toggleHover() {
    this.setState({ hover: !this.state.hover });
  }

  showModal() {
    this.setState({ visible: true });
  }

  hideModal() {
    this.setState({ visible: false });
  }

  openNotification(placement, workspaceName) {
    notification.info({
      message: workspaceName + ' Workspace Saved',
      icon: <CheckCircleOutlined />,
      style: { color: 'green' },
      placement,
    });
  }

  async saveWorkspace(values, props, thisVals, context) {
    let selectedDatasets = [];

    props['selectedDatasets'].forEach((e) => {
      selectedDatasets.push({
        collection_id: e.dataset_collections[0].data_collection_id,
        dataset_id: e.dataset_id,
        filters: props.filterItems[props.getItemKey(e)],
        is_checked: props['checkedDatasets'][props.getItemKey(e)],
        opacity: props['opacities'][props.getItemKey(e)],
      });
    });

    let workspace = {
      datasetInformation: selectedDatasets,
      access_token: context.user.access_token,
      workspaceName: values['Name'],
    };
    let base_url = process.env.REACT_APP_API_URL;

    const response = await fetch(
      `${base_url}/workspace/workspace?data=${JSON.stringify(workspace)}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + props.access_token,
        },
      }
    )
      .then((response) => response.json())
      .then((responseData) => {
        return responseData;
      })
      .catch((error) => console.warn(error));

    this.hideModal();

    this.openNotification('top', values['Name']);
  }

  render() {
    let rootStyle = this.props.style;
    if (rootStyle === undefined) {
      rootStyle = {
        position: 'absolute',
        top: '129px',
        left: '7px',
        padding: '1px 4px 1px 4px',
        borderRadius: '6px',
        backgroundColor: this.state.hover
          ? 'rgba(0, 60, 136, .8)'
          : 'rgba(0, 60, 136, .5)',
        border: '3px solid rgba(255, 255, 255, 0.72)',
      };
    }

    return this.context.user != null ? (
      <div>
        <div
          style={rootStyle}
          onMouseEnter={this.toggleHover}
          onMouseLeave={this.toggleHover}
        >
          <Tooltip title={'Save Workspace'} placement="right">
            <SaveOutlined onClick={this.showModal} style={{ color: 'white' }} />
          </Tooltip>
        </div>
        <Modal
          title="Save Workspace"
          open={this.state.visible}
          onCancel={this.hideModal}
          destroyOnClose={true}
          footer={null}
          width={700}
          bodyStyle={{
            maxHeight: 350,
          }}
        >
          <div>
            <Form
              name="save_workspace"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 24,
              }}
              layout="vertical"
              onFinish={(values) =>
                this.saveWorkspace(values, this.props, this, this.context)
              }
              autoComplete="off"
              scrollToFirstError="true"
            >
              <Form.Item
                style={{ marginBottom: '4px' }}
                label="Workspace Name"
                name="Name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Workspace Name',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <div>
                <span style={{ fontSize: '10px' }}>
                  * User Provided Data not included in workspace
                </span>
              </div>
              <Form.Item style={{ textAlign: 'center' }}>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </div>
    ) : null;
  }
}

export default ForestDataMapSaveWorkspace;
