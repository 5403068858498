import { HomeOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useState, useEffect } from 'react';
import { FaLayerGroup } from 'react-icons/fa6';
import { Modal } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom';
import OlOverlay from 'ol/Overlay';
import './forest_data_map_layer_popover.css';
import OlLayerTile from 'ol/layer/Tile';
import { Group } from 'ol/layer';

// import { Modal } from 'react-bootstrap';

const ForestDataMapLayer = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [hover, setHover] = useState(false);

  const toggleHover = () => {
    setHover(!hover);
  };
  const showModal = () => {
    setOpenModal(true);
  };
  useEffect(() => {
    let map = props.map;
    if (map === undefined) {
      map = props.mapRef.current.getMap();
    }

    let layers = props.layers;
    if (layers === undefined) {
      layers = props.mapRef.current.getLayers();
    }

    layers.forEach((layer) => {
      const layer_name = layer.getSource().getParams().LAYERS;
      if (layer_name.search('ITS:') != -1) {
        layer.setZIndex(9999);
      } else if (layer_name.search('boundary:') != -1) {
        layer.setZIndex(500);
      } else {
        layer.setZIndex(0);
      }
    });
  });

  let rootStyle = props.style;
  if (rootStyle === undefined) {
    rootStyle = {
      position: 'absolute',
      top: '98px',
      left: '7px',
      padding: '1px 4px 1px 4px',
      borderRadius: '6px',
      backgroundColor: hover ? 'rgba(0, 60, 136, .8)' : 'rgba(0, 60, 136, .5)',
      border: '3px solid rgba(255, 255, 255, 0.72)',
    };
  }

  return (
    <div>
      {/* <div
        style={rootStyle}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
      >
        <Tooltip title={'Reorder Map'} placement="right">
          <FaLayerGroup onClick={showModal} style={{ color: 'white' }} />
        </Tooltip>
      </div>
      <Modal title="Layer Organizer" open={openModal}></Modal> */}
    </div>
  );
};
export default ForestDataMapLayer;
