import { Button, Spinner } from 'react-bootstrap';
import TextTruncate from 'react-text-truncate';
import placeholder from '../images/placeholder.png';
import DataDownload from './DataDownload';
import PermissionsHelper from '../util/PermissionsHelper';
import CLM from '../images/CLM-DataCollection.svg';
import ITS from '../images/treatmentTrackerIcon2.webp';
import { useMemo, useState } from 'react';
import CKAN from '../lib/CKAN';

const ListCard = (props) => {
  const {
    dataset,
    inWorkspace: checkInWorkspace,
    addToWorkspace,
    removeFromWorkspace,
    user,
  } = props;
  const [loading, setLoading] = useState(false);
  const inWorkspace = checkInWorkspace();
  const adminOnly = PermissionsHelper(user.roles, 'admin');
  const itemGis = props.getGisService(dataset);
  const isBoundary =
    itemGis.layer_name.search('boundary') === -1 ? false : true;
  const isITS = itemGis.layer_name.search('ITS:') === -1 ? false : true;

  const handleView = () => {
    props.setModalData(dataset);
    props.setModalShow(true);
  };
  const handleDownload = async () => {
    setLoading(true);
    await DataDownload(dataset);
    setLoading(false);
  };

  const handleApisView = () => {
    props.setAPIsModalData(dataset);
    props.setAPIsModalShow(true);
  };

  return (
    <div
      style={{
        width: '1151px',
        height: '192px',
        borderWidth: '2px',
        borderColor: '#525335',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        padding: '20px',
        marginBottom: '14px',
      }}
    >
      <div
        style={{
          height: '143px',
          minWidth: '160px',
          marginRight: '15px',
          backgroundColor: '#9C9D86',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'space-between',
        }}
      >
        {isITS ? (
          <img
            height={'123px'}
            width={'100px'}
            style={{ borderRadius: '5px' }}
            src={ITS}
          ></img>
        ) : !isBoundary ? (
          <img
            height={'143px'}
            width={'140px'}
            style={{ borderRadius: '5px' }}
            src={CLM}
          ></img>
        ) : (
          <img
            height={'143px'}
            width={'140px'}
            style={{ borderRadius: '5px' }}
            src={
              `https://sparcal.sdsc.edu/api/v1/Utility/wms_thumbnail?wms_url=` +
              itemGis.service_url +
              `&layer_name=` +
              itemGis.layer_name.split(':')[1] +
              `&width=200&height=200`
            }
          ></img>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          marginBottom: '1rem',
        }}
      >
        <p
          style={{
            fontSize: '16px',
            fontWeight: 'bolder',
            marginBottom: '0',
          }}
        >
          {dataset.title}
        </p>
        <p style={{ fontSize: '12px', marginBottom: '4px' }}>
          By {dataset.org_title}
        </p>
        <TextTruncate
          line={2}
          element="span"
          truncateText="…"
          text={dataset.notes}
          style={{ fontSize: '13px', marginBottom: '4px' }}
        />

        <div style={{ marginBottom: '2px' }}>
          <Button
            style={{
              backgroundColor: '#61633f',
              marginRight: '4px',
              border: 'none',
            }}
            onClick={handleView}
          >
            View More
          </Button>

          <Button
            style={{
              backgroundColor: inWorkspace ? '#7d5332' : '#CA7E15',
              marginRight: '4px',
              border: 'none',
            }}
            onClick={inWorkspace ? removeFromWorkspace : addToWorkspace}
          >
            {inWorkspace ? 'Remove from Workspace' : 'Add to Workspace'}
          </Button>

          <Button
            style={{
              backgroundColor: '#823F20',
              marginRight: '4px',
              border: 'none',
            }}
            onClick={handleApisView}
          >
            APIs
          </Button>
          {/* <Button
            style={{
              backgroundColor: '#468373',
              marginRight: '4px',
              border: 'none',
            }}
          >
            Edit Metadata
          </Button> */}
          <Button
            style={{
              backgroundColor: '#a64500',
              marginRight: '4px',
              border: 'none',
            }}
            onClick={handleDownload}
            disabled={loading}
          >
            {loading && (
              <>
                <Spinner animation="border" size="sm" />{' '}
              </>
            )}
            Download{isBoundary ? ' GeoJSON' : isITS ? ' GDB' : ' Zip'}
          </Button>
          {adminOnly && (
            <Button
              style={{
                backgroundColor: 'black',
                marginRight: '4px',
                border: 'none',
              }}
              onClick={() => {
                console.log('dataset', dataset);
                props.setDeleteData();
              }}
            >
              Delete
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
export default ListCard;
