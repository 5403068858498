import axios from 'axios';

const Sparcal = {
  postPasswordReset: async (data) => {
    const headers = {
      'Content-Type': 'application/json',
    };
    const dataset = await axios
      .post(
        `${process.env.REACT_APP_API_URL_Prod}/Auth/forget_password?email=${data.email}`,
        headers
      )
      .then((resp) => {
        return { status: resp.status, message: resp.data.message };
      })
      .catch((err) => {
        return { status: err.status, message: err.message };
      });
    return dataset;
  },
  getDraftDataset: async (user) => {
    const headers = {
      accept: 'application/json',
      Authorization: 'Bearer ' + user.access_token,
    };
    const dataset = await axios
      .get(
        `${process.env.REACT_APP_API_URL_Prod}/ckan/draft-packages?rows=1000`,
        {
          headers: headers,
        }
      )
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return { status: err.status, message: err.message };
      });
    return dataset;
  },
  getUserDraftDataset: async (user) => {
    const headers = {
      accept: 'application/json',
      Authorization: 'Bearer ' + user.access_token,
    };
    const dataset = await axios
      .get(
        `${process.env.REACT_APP_API_URL_Prod}/ckan/my-draft-packages?&rows=1000`,
        {
          headers: headers,
        }
      )
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return { status: err.status, message: err.message };
      });
    return dataset;
  },
  createDataset: async (user, data) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + user.access_token,
    };
    try {
      const dataset = await axios
        .post(`${process.env.REACT_APP_API_URL_Prod}/ckan/package`, data, {
          headers: headers,
        })
        .then((resp) => {
          return resp;
        })
        .catch((err) => {
          return { status: err.status, message: err.message };
        });

      return dataset;
    } catch (e) {
      console.error(e, 'Error creating dataset');
    }
  },
  deleteDataset: async (user, package_id) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + user.access_token,
    };
    try {
      const dataset = await axios
        .delete(
          `${process.env.REACT_APP_API_URL_Prod}/ckan/package/${package_id}`,
          {
            headers: headers,
          }
        )
        .then((resp) => {
          return { status: resp.status, message: resp.data.message };
        })
        .catch((err) => {
          return { status: err.status, message: err.message };
        });

      return dataset;
    } catch (e) {
      console.error(e, 'Error deleting dataset');
    }
  },
  approveDataset: async (user, package_id) => {
    const headers = {
      accept: 'application/json',
      Authorization: 'Bearer ' + user.access_token,
    };
    try {
      const dataset = await axios
        .post(
          `${process.env.REACT_APP_API_URL_Prod}/ckan/approve-draft/${package_id}`,
          {},
          {
            headers: headers,
          }
        )
        .then((resp) => {
          return { status: resp.status, message: resp.data.message };
        })
        .catch((err) => {
          return { status: err.status, message: err.message };
        });

      return dataset;
    } catch (e) {
      console.error(e, 'Error deleting dataset');
    }
  },
  denyDataset: async (user, package_id) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + user.access_token,
    };
    try {
      const dataset = await axios
        .delete(
          `${process.env.REACT_APP_API_URL_Prod}/ckan/deny-draft/${package_id}`,
          {
            headers: headers,
          }
        )
        .then((resp) => {
          return { status: resp.status, message: resp.data.message };
        })
        .catch((err) => {
          return { status: err.status, message: err.message };
        });

      return dataset;
    } catch (e) {
      console.error(e, 'Error deleting dataset');
    }
  },
};
export default Sparcal;
