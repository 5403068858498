import { useState } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { ArrowLeftOutlined } from '@ant-design/icons';
import * as formik from 'formik';
import * as yup from 'yup';
import AddEditDataForm from './AddEditDataForm';
// import DataForm from './DataForm';

const AddEditDataPage = (props) => {
  const toDisplay = props.display === 'add_data';

  return (
    <>
      {toDisplay && (
        <div
          id="add_data"
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <div
            style={{
              backgroundSize: 'cover',
            }}
          >
            <div
              style={{
                margin: '30px 10%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Button
                variant="secondary"
                size="sm"
                style={{
                  backgroundColor: '#EEEDE2',
                  fontWeight: 'bolder',
                  fontSize: '15px',
                  width: '200px',
                  color: '#CA7E15',
                  marginBottom: '20px',
                }}
                onClick={() => props.setDisplay('data_catalog_new')}
              >
                <ArrowLeftOutlined style={{ fontSize: '15px' }} /> Back to Data
                Catalog
              </Button>
              <h1
                style={{
                  color: '#525335',
                  fontWeight: 'bolder',
                  fontSize: '36px',
                }}
              >
                Data Ingestion Form
              </h1>
            </div>
          </div>
          <AddEditDataForm user={props.user} />
        </div>
      )}
    </>
  );
};
export default AddEditDataPage;
