import { Modal, Button } from 'react-bootstrap';

const ResponseModal = (props) => {
  const { onHide, data } = props;
  return (
    <Modal
      show={true}
      onHide={onHide}
      size="xl"
      aria-labelledby="view_more_modal"
      centered
    >
      <Modal.Header closeButton>
        <h4>{data.title} </h4>
      </Modal.Header>
      <Modal.Body>
        <p>{data.description}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onHide()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ResponseModal;
