const CarouselContent = [
  {
    type: 'meeting',
    month: 'Mar',
    dates: '27 & 28',
    fullDate: ' March 27 & 28, 2025',
    location: 'Marin County',
    link: 'https://wildfiretaskforce.org/meetings/',
    org: 'California Wildfire & Forest Resilience Task Force',
  },
  {
    type: 'meeting',
    month: 'June',
    dates: '6',
    fullDate: 'June 6, 2025',
    location: 'CNRA Auditorium, Sacramento',
    link: 'https://wildfiretaskforce.org/meetings/',
    org: 'California Wildfire & Forest Resilience Task Force',
  },
  {
    type: 'update',
    org: 'California Wildfire & Forest Resilience Task Force',
    archiveLink: 'https://wildfiretaskforce.org/governors-monthly-update/',
  },
];
export default CarouselContent;
