import CKAN from '../lib/CKAN';

const DataDownload = async (dataset) => {
  let geoTiffIndex = null;
  let gdbIndex = null;
  let shpIndex = null;
  let wfsIndex = null;
  dataset.resources.forEach((data, i) => {
    console.log('data', data);
    if (data.format === 'GeoTiff') {
      geoTiffIndex = i;
    } else if (data.format === 'GDB') {
      gdbIndex = i;
    } else if (data.format === 'SHP') {
      shpIndex = i;
    } else if (data.format === 'WFS') {
      wfsIndex = i;
    }
  });
  if (geoTiffIndex) {
    const link = document.createElement('a');
    link.href = dataset.resources[geoTiffIndex].url;
    link.download = dataset.resources[geoTiffIndex].name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else if (gdbIndex !== null) {
    const link = document.createElement('a');
    link.href = dataset.resources[gdbIndex].url;
    link.download = dataset.resources[gdbIndex].name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else if (shpIndex !== null) {
    const link = document.createElement('a');
    link.href = dataset.resources[shpIndex].url;
    link.download = dataset.resources[shpIndex].name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // For boundary data
  } else if (wfsIndex) {
    const wfsData = await CKAN.getWfsBoundaryData(
      dataset.resources[wfsIndex].url,
      dataset.resources[wfsIndex].wfs_feature_id
    );
    if (wfsData.status === 200) {
      const zipStream = new CompressionStream('gzip');
      const blob = new Blob([wfsData.data], { type: 'application/json' });
      const compressedStream = blob.stream().pipeThrough(zipStream);
      const compressedResponse = new Response(compressedStream);
      const zipBlob = await compressedResponse.blob();

      const a = document.createElement('a');
      a.href = URL.createObjectURL(zipBlob);
      a.download = `${dataset.resources[wfsIndex].wfs_feature_id}.geojson.gz`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      console.error('Failed to fetch data', wfsData.status);
    }
  } else {
    console.error('Failed to download');
  }
};
export default DataDownload;
