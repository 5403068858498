import { default as BasicCarousel } from 'react-bootstrap/Carousel';
import { CalendarOutlined, EnvironmentFilled } from '@ant-design/icons';
import { Button } from 'react-bootstrap';
import CarouselContent from './CarouselContent';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';

function Carousel() {
  return (
    <BasicCarousel style={{ maxWidth: '100%' }}>
      {CarouselContent.map((slide, i) => (
        <BasicCarousel.Item
          key={'image' + i}
          style={{
            width: '735px',
            height: '352px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '20px 50px',
              // height: '100%',
            }}
          >
            {slide.type === 'meeting' ? (
              <BasicCarousel.Caption
                style={{ paddingTop: '0', height: '45%', paddingBottom: '0' }}
              >
                <div
                  style={{
                    width: '200px',
                    height: '55px',
                    background: '#525335',
                    marginBottom: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '14px',
                    justifyContent: 'center',
                  }}
                >
                  <h3
                    style={{
                      color: '#E9E5C3',
                      fontSize: '24px',
                      fontWeight: 'bold',
                      marginBottom: 0,
                    }}
                  >
                    {slide.month} {slide.dates}
                  </h3>
                </div>
                <h3
                  style={{
                    color: '#A15C00',
                    fontWeight: 'bolder',
                    fontSize: '18px',
                  }}
                >
                  UPCOMING MEETING
                </h3>
                <h3
                  style={{
                    color: '#383C15',
                    fontSize: '28px',
                    fontWeight: 'bolder',
                    marginBottom: 0,
                  }}
                >
                  {slide.org}
                  <hr style={{ margin: '4px 0 0 0', color: '#c2c0b4' }} />
                </h3>
                <p
                  style={{
                    color: '#737144',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    font: 'Inter',
                    margin: ' 0',
                    marginBottom: 0,
                    height: '24px',
                  }}
                >
                  <CalendarOutlined /> Date: {slide.fullDate}
                </p>
                <p
                  style={{
                    color: '#737144',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    font: 'Inter',
                    margin: ' 0',
                    marginBottom: '4px',
                    height: '24px',
                  }}
                >
                  <EnvironmentFilled /> Location: {slide.location}
                </p>
                <Button
                  style={{
                    backgroundColor: '#A15C00',
                    borderWidth: '0',
                    width: '136px',
                    height: '30px',
                    borderRadius: '3px',
                    padding: '3px',
                    fontWeight: 'bold',
                  }}
                  target="_blank"
                  href={slide.link}
                >
                  Learn More
                </Button>
              </BasicCarousel.Caption>
            ) : (
              <BasicCarousel.Caption style={{ paddingTop: '0' }}>
                <div>
                  <div
                    style={{
                      backgroundColor: '#525335',
                      width: '365px',
                      height: '55px',
                      margin: '0px 0px 15px 0px',
                      fontSize: '24px',
                      fontWeight: 'bolder',
                      color: '#E9E5C3',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    Monthly Updates
                  </div>
                  <h3
                    style={{
                      color: '#383C15',
                      fontSize: '28px',
                      fontWeight: 'bolder',
                    }}
                  >
                    {slide.org}
                    <hr style={{ margin: '4px 0', color: '#c2c0b4' }} />
                  </h3>
                  <p
                    style={{
                      color: '#737144',
                      fontSize: '18px',
                      font: 'Inter',
                      margin: ' 0',
                      lineHeight: '24px',
                      marginBottom: '24px',
                    }}
                  >
                    Each month the Task Force provides highlights, relevant
                    press, and updates on collaborations, legislation,
                    publications and funding opportunities
                    <br />
                  </p>
                  <div style={{ display: 'flex' }}>
                    <Button
                      style={{
                        backgroundColor: '#A15C00',
                        borderWidth: '0',
                        width: '168px',
                        height: '30px',
                        borderRadius: '3px',
                        padding: '3px',
                        fontWeight: 'bold',
                      }}
                      target="_blank"
                      href={slide.archiveLink}
                    >
                      Updates Archive
                    </Button>
                  </div>
                </div>
              </BasicCarousel.Caption>
            )}
          </div>
        </BasicCarousel.Item>
      ))}

      {/* <Carousel.Item style={{ width: '735px', height: '302px' }}>
        <img src={slides[1].img} />
        <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item style={{ width: '735px', height: '302px' }}>
        <img src={slides[2].img} />
        <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item> */}
    </BasicCarousel>
  );
}

export default Carousel;
