import React, { useEffect, useState, useContext } from 'react';
import { Layout, Row, Col, Card, Typography } from 'antd';
import cKanImage from '../../src/images/ckan.png';
import cwiImage from '../../src/images/cwilogo.jpeg';
import sigImage from '../../src/images/siglogo.png';
import { ExportOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { Meta } = Card;

const DatasetCard = (props) => {
  let cardsBoarder = {
    paddingTop: '20px',
    // marginRight: '350px'
  };

  let aTag = {
    display: 'inline',
    marginLeft: '15px',
  };

  let h4style = {
    display: 'inline',
  };

  let contentPadding = {
    paddingTop: '15px',
  };

  return (
    <div style={cardsBoarder}>
      <Row justify="space-evenly">
        <Col span={7}>
          <Card>
            <Meta
              avatar={
                <img alt="CKAN" src={cKanImage} style={{ height: '20.65px' }} />
              }
              title={
                <div>
                  <h4 style={h4style}>
                    Wildfire and Landscape Resilience Catalog
                  </h4>
                  <a
                    style={aTag}
                    target="_blank"
                    href="http://twsa.ucsd.edu:5000/"
                  >
                    <ExportOutlined />
                  </a>
                </div>
              }
            />
            <div style={contentPadding}>
              CKAN Organizations are used to create, manage and publish
              collections of datasets. Users can have different roles within an
              Organization, depending on their level of authorisation to create,
              edit and publish.
            </div>
          </Card>
        </Col>
        <Col span={7}>
          <Card>
            <Meta
              avatar={
                <img alt="RRK" src={cwiImage} style={{ height: '20.65px' }} />
              }
              title={
                <div>
                  <h4 style={h4style}>Regional Resource Kits</h4>
                  <a style={aTag} target="_blank" href="https://rrk.sdsc.edu/">
                    <ExportOutlined />
                  </a>
                </div>
              }
            />
            <div style={contentPadding}>
              Regional Resource Kits (RRK) are sets of data created to
              accelerate the work by regional partners and collaboratives to
              reduce wildfire hazard and improve the conditions of forested and
              shrub landscapes. The kits include, among other elements, a
              database of management-relevant metrics that have been vetted by
              federal, state, and academic scientists and spatially explicit
              assessments of current conditions for key resources in the region.
              The Resource Kit will be revised based on user feedback and
              updated as new science and technologies are developed. The data in
              the kits can be used with a conventional GIS and/or with
              specialized Decision Support Tools created to analyze spatial
              data.{' '}
            </div>
          </Card>
        </Col>
        <Col span={7}>
          <Card>
            <Meta
              avatar={
                <img alt="ITS" src={sigImage} style={{ height: '20.65px' }} />
              }
              title={
                <div>
                  <h4 style={h4style}>Interagency Tracking System</h4>
                  <a
                    style={aTag}
                    target="_blank"
                    href="https://gsal.sig-gis.com/portal/home/item.html?id=170b854893474c68b01bff385b160bd6"
                  >
                    <ExportOutlined />
                  </a>
                </div>
              }
            />
            <div style={contentPadding}>
              Wildfire fuels treatments, timber harvests, and forest resiliency
              projects data were drawn from multiple sources. After formatting
              into the California Wildfire & Forest Resilience Task Force
              template, the data sets were enriched with attributes for
              vegetation, ownership, county, WUI, and Task Force Region from
              reference data.
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DatasetCard;
