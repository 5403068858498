import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Divider,
  Checkbox,
  Collapse,
  Space,
  Slider,
  Row,
  Col,
  Typography,
  Modal,
  Input,
  Spin,
} from 'antd';
import InfoCircleTwoTone from '@ant-design/icons/es/icons/InfoCircleTwoTone';
import 'antd/dist/antd.css';
import { getSLDForLayer } from './utils';
import MiniITSDashboard from './ITSDashboard/MiniITSDashboard';
import Button from 'antd/es/button';
import WorkspaceContext from '../WorkspaceContext';
import {
  CloseOutlined,
  InfoCircleOutlined,
  UndoOutlined,
  FilterOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import UserDataReport from '../report/UserDataReport';
import WFSDataReport from '../report/WFSDataReport';
import { debounce, filter } from 'lodash';
import FilterModal from './FilterModal';
import VectorSource from 'ol/source/Vector';
import { Vector as VectorLayer } from 'ol/layer';
import EsriJSON from 'ol/format/EsriJSON';
import { tile as tileStrategy } from 'ol/loadingstrategy';
import { createXYZ } from 'ol/tilegrid';
import ForestDataMapDownload from './ForestDataMapDownload';
import { UserContext } from '../UserContext';
import CKAN from '../lib/CKAN';
import ForestDataMapITSReport from './ForestDataMapITSReport';
import 'antd/dist/antd.css';
import './index.css';
// const fetch = require('sync-fetch');

const { Panel } = Collapse;
const { Text } = Typography;

const ForestDataMapLayerTree = (props) => {
  const {
    selectedDatasets,
    setRegionReq,
    setDefaultKeys,
    setSelectedKeys,
    setSelectedDatasets,
  } = useContext(WorkspaceContext);
  const [layers, setLayers] = useState(props.layers);
  const [queryLayers, setQueryLayers] = useState(props.queryLayers);
  const [checkedValues, setCheckedValues] = useState({});
  const [unitsForModal, setUnits] = useState(null);
  const [unitModalTitle, setUnitModalTitle] = useState(null);
  const [openUnitsModal, setOpenUnitsModal] = useState(false);

  const base_url = process.env.REACT_APP_API_URL_Prod;

  const fetchDataForInit = async (dataset_id) => {
    let dataset = await fetch(
      `${base_url}/Dataset/${dataset_id.toString()}`
    ).then((response) => {
      return response.json();
    });
    return dataset;
  };
  const [value, setValue] = React.useState([0, 10, 20]);
  const start = value[0] / 100;
  const end = value[value.length - 1] / 100;
  function getGradientColor(percentage) {
    const startColor = [135, 208, 104];
    const endColor = [255, 204, 199];
    const midColor = startColor.map((start, i) => {
      const end = endColor[i];
      const delta = end - start;
      return (start + delta * percentage).toFixed(0);
    });
    return `rgb(${midColor.join(',')})`;
  }
  useEffect(() => {
    const fetchData = async () => {
      // CLM boundary data
      const defaultData = [
        'clm-wildlife-species-richness',
        // 'fdh-regional-resource-kit-boundaries',
        // 'fdh-calfire-administrative-unit-boundaries',
        'fdh-california-county-boundaries',
        'its-interagency-treatment-dashboard-activity-report-version-111',
      ];

      const foundData = await defaultData.map(async (data) => {
        let dataset = await CKAN.getSpecificDatasetByName(data);
        if (dataset?.status) return null;
        console.log('dataset', dataset[0]);
        if (dataset.length == 1) {
          return dataset[0];
        }
      });
      const results = await Promise.all(foundData);
      // Removes datasets if nothing was returned
      const trimmed = results.filter((dataset) => dataset);

      setSelectedDatasets((selectedDatasets) => {
        return selectedDatasets.concat(trimmed);
      });
    };
    fetchData();
  }, []);

  useEffect(() => {
    let values = {};
    let ops = {};

    for (var i = 0; i < props.layers.length; i++) {
      let layer = props.layers[i];
      let key =
        layer.getProperties().service_url +
        '/' +
        layer.getProperties().layer_name;
      values[key] = layer.getVisible();
      ops[key] = layer.getOpacity();
    }

    let newValueRanges = { ...props.valueRanges };
    let changed = false;

    for (var j = 0; j < selectedDatasets.length; j++) {
      let dataset = selectedDatasets[j];
      let gis_services = props.getGisService(dataset);
      let found = false;
      for (i = 0; i < props.layers.length; i++) {
        let layer = props.layers[i];
        if (
          gis_services.service_url === layer.getProperties().service_url &&
          gis_services.layer_name === layer.getProperties().layer_name
        ) {
          found = true;
          break;
        }
      }

      let key = props.getItemKey(dataset);

      if (!found) {
        values[key] = true;
        ops[key] = props.opacities[key] ?? 0.7;
      }

      if (
        newValueRanges[key] === undefined &&
        dataset.data_type === 'raster' &&
        dataset.dataset_metadata.filter((e) => e.name === 'min_value')[0] !==
          undefined &&
        dataset.dataset_metadata.filter((e) => e.name === 'max_value')[0] !==
          undefined
      ) {
        let min = dataset.dataset_metadata.filter(
          (e) => e.name === 'min_value'
        )[0].float_value;
        let max = dataset.dataset_metadata.filter(
          (e) => e.name === 'max_value'
        )[0].float_value;
        newValueRanges[key] = [min, max];
        changed = true;
      }
    }

    // below loops are to check for proper settings when loading workspaces

    // if new dataset added, check with checked datasets to make sure the checked settings are correct
    Object.keys(props.checkedDatasets).forEach((key) => {
      values[key] = props.checkedDatasets[key];
    });

    // make sure checked datasets that are checked are visible
    Object.keys(values)
      .filter(function (key) {
        return values[key];
      })
      .forEach((key) => {
        for (i = 0; i < props.layers.length; i++) {
          let layer = props.layers[i];
          if (
            key ===
            layer.getProperties().service_url +
              '/' +
              layer.getProperties().layer_name
          ) {
            layer.setVisible(values[key]);
          }
        }
      });

    props.setCheckedDatasets(values);
    props.setOpacities(ops);
    if (changed) {
      props.setValueRanges(newValueRanges);
    }
    setLayers(props.layers);
  }, [selectedDatasets, props.valueRanges]);

  useEffect(() => {
    let checkedQueries = {};
    props.queryLayers.forEach((layer) => {
      let isVisible = layer.getVisible();
      checkedQueries[layer.getProperties()['layerId']] = isVisible;
    });

    for (var i = 0; i < props.queryLayerInfo.length; i++) {
      let layerId = props.queryLayerInfo[i].mapping[0].layerId;
      let found = false;
      props.queryLayers.forEach((layer) => {
        if (layer.getProperties()['layerId'] === layerId) {
          found = true;
          return;
        }
      });

      if (!found) {
        let layerInfo = props.queryLayerInfo;
        layerInfo.splice(i, 1);
        props.setQueryLayerInfo(layerInfo);
      }
    }

    props.setCheckedQuerysets(checkedQueries);
  }, [props.queryLayers]);

  const arcGISValueChange = async (filterValues, item) => {
    try {
      let gis_service = props.getGisService(item);
      let key = props.getItemKey(item);

      let newValueRanges = { ...props.valueRanges };

      newValueRanges[key] = filterValues;
      props.setValueRanges(newValueRanges);

      let layer_info = await fetch(`${gis_service.service_url}?f=pjson`).then(
        (response) => {
          return response.json();
        }
      );

      let sql = [];

      Object.keys(filterValues)
        .filter(
          (key) =>
            (filterValues[key] !== undefined && filterValues[key] !== null) ||
            (Array.isArray(filterValues[key]) &&
              filterValues[key][0] !== undefined &&
              filterValues[key][0] !== null)
        )
        .forEach((key) => {
          let value = filterValues[key];

          let field_info = layer_info.fields.filter(
            (e) => e.modelName === key
          )[0];

          if (
            field_info.type === 'esriFieldTypeString' &&
            Array.isArray(value)
          ) {
            let string_values = "'" + value.join("', '") + "'";
            sql.push(`${field_info.modelName} IN (${string_values})`);
          } else if (field_info.type === 'esriFieldTypeString') {
            sql.push(`${field_info.modelName} = '${value}'`);
          } else if (field_info.type === 'esriFieldTypeDate') {
            let min = value[0].format('YYYY-MM-DD');
            let max = value[1].format('YYYY-MM-DD');

            sql.push(
              `${field_info.modelName} >= '${min}' AND ${field_info.modelName} <= '${max}'`
            );
          } else if (field_info.type === 'esriFieldTypeDouble') {
            sql.push(
              `${field_info.modelName} >= ${value[0]} AND ${field_info.modelName} <= ${value[1]}`
            );
          }
        });

      let final_sql = sql.join(' AND ');
      final_sql.replaceAll(' ', '+');

      const vectorSource = new VectorSource({
        format: new EsriJSON(),
        url: function (extent, resolution, projection) {
          // ArcGIS Server only wants the numeric portion of the projection ID.
          const srid = projection
            .getCode()
            .split(/:(?=\d+$)/)
            .pop();

          const url =
            gis_service.service_url +
            '/query?where=' +
            encodeURI(final_sql) +
            '&f=json&' +
            'returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=' +
            encodeURIComponent(
              '{"xmin":' +
                extent[0] +
                ',"ymin":' +
                extent[1] +
                ',"xmax":' +
                extent[2] +
                ',"ymax":' +
                extent[3] +
                ',"spatialReference":{"wkid":' +
                srid +
                '}}'
            ) +
            '&geometryType=esriGeometryEnvelope&inSR=' +
            srid +
            '&outFields=*' +
            '&outSR=' +
            srid;

          return url;
        },
        strategy: tileStrategy(
          createXYZ({
            tileSize: 512,
          })
        ),
      });

      let newLayer = new VectorLayer({
        visible: true,
        source: vectorSource,
        layer_name: gis_service.layer_name,
        service_url: gis_service.service_url,

        // style: function (feature) {
        //     const classify = feature.get('LU_2014');
        //     const color = fillColors[classify] || [0, 0, 0, 0];
        //     style.getFill().setColor(color);
        //     return style;
        // },
        opacity: props.opacities[key] ?? 0.7,
      });

      await props.map.addLayer(newLayer);
      await props.layers.push(newLayer);

      props.checkedDatasets[key] = true;
    } catch (e) {}
  };

  const vectorValueChange = (values, item) => {
    let sql = [];

    let itemKey = props.getItemKey(item);

    Object.keys(values)
      .filter((key) => values[key] !== undefined && values[key].length > 0)
      .forEach((key) => {
        let value = values[key];
        let data_type = props.filter_options[itemKey].filter(
          (e) => e.label == key
        );
        if (data_type.length > 0) {
          data_type = data_type[0].type;
        }

        if (data_type == 'string') {
          let string_values = '';
          if (typeof value === 'string') {
            string_values = "'" + value.split(',').join("', '") + "'";
          } else {
            string_values = "'" + value.join("', '") + "'";
          }

          sql.push(`${key} IN (${string_values})`);
        } else if (data_type == 'number') {
          sql.push(`${key} >= ${value[0]} AND ${key} <= ${value[1]}`);
        }
      });

    let final_sql = sql.join(' AND ');
    final_sql.replaceAll(' ', '+');
    let layer = getLayer(item);
    if (layer) {
      layer.getSource().updateParams({ cql_filter: final_sql });

      let newValueRanges = { ...props.valueRanges };
      let key = props.getItemKey(item);

      newValueRanges[key] = final_sql;
      props.setValueRanges(newValueRanges);
    }
  };

  const rasterValueChange = (values, item) => {
    let layer = getLayer(item);
    if (layer) {
      let newValueRanges = { ...props.valueRanges };
      let key = props.getItemKey(item);

      newValueRanges[key] = values;
      props.setValueRanges(newValueRanges);
      let min;
      let max;
      let gis_service = props.getGisService(item);
      if (item.dataset_metadata) {
        min = item.dataset_metadata.filter((e) => e.name === 'min_value')[0]
          .float_value;
        max = item.dataset_metadata.filter((e) => e.name === 'max_value')[0]
          .float_value;
      } else {
        min = gis_service.dataset_collections[0].minimum_value;
        max = gis_service.dataset_collections[0].maximum_value;
      }
      let sld = getSLDForLayer(
        gis_service.layer_name,
        gis_service.color_map.color_map_entries.length > 0
          ? gis_service.color_map.color_map_entries[0].color
          : '#D6B2FF',
        gis_service.color_map.color_map_entries.length > 1
          ? gis_service.color_map.color_map_entries[1].color
          : '#3B0875',
        max,
        min,
        values[1],
        values[0]
      );
      layer.getSource().updateParams({ STYLES: undefined });
      layer.getSource().updateParams({ SLD_BODY: sld });
    }
  };

  useEffect(() => {
    // Update when filter is submitted
    if (Object.keys(props.filterItems).length > 0) {
      Object.keys(props.filterItems).forEach((filterKey) => {
        let newLoading = { ...props.loading_datasets };
        newLoading[filterKey] = true;
        const setLoading = async (newLoading) => {
          await props.setLoadingDatasets(newLoading);
        };

        setLoading(newLoading);

        let newValue = props.filterItems[filterKey];
        let newFilterDatasets = { ...props.filtersDatasets };
        newFilterDatasets[filterKey] = newValue;
        props.setFilterDatasets(newFilterDatasets);

        let item = selectedDatasets.filter((e) => {
          return props.getItemKey(e) === filterKey;
        })[0];

        let gis_service = props.getGisService(item);
        if (gis_service.service_type === 'ArcGIS') {
          const removeLayer = async (layer) => {
            const index = props.layers.indexOf(layer);
            if (index > -1) {
              await props.layers.splice(index, 1);
            }

            let mapLayerToRemove = null;
            await props.map.getLayers().forEach((e) => {
              if (
                e.getProperties().service_url ===
                  layer.getProperties().service_url &&
                e.getProperties().layer_name ===
                  layer.getProperties().layer_name
              ) {
                mapLayerToRemove = e;
              }
            });

            props.map.removeLayer(mapLayerToRemove);

            props.map.getLayers().forEach((e) => {});
          };

          let layer = getLayer(item);
          removeLayer(layer);

          const setArcMap = async (newValue, item) => {
            // await arcGISValueChange(newValue, item);
          };

          setArcMap(newValue, item);
        } else if (
          item.data_type === 'vector' ||
          gis_service.data_type === 'vector'
        ) {
          vectorValueChange(newValue, item);
        } else {
          rasterValueChange(newValue['values'], item);
        }

        newLoading = { ...props.loading_datasets };
        newLoading[filterKey] = false;
        setLoading(newLoading);
      });
    }
  }, [props.filterItems]);

  const handleCheckboxChange = (event, item) => {
    let layer = getLayer(item);
    let key = props.getItemKey(item);

    props.map.getLayers().forEach((e) => {});
    if (layer) {
      layer.setVisible(event.target.checked);
      let newCheckedValues = { ...props.checkedDatasets };
      newCheckedValues[key] = event.target.checked;
      props.setCheckedDatasets(newCheckedValues);

      layer.getSource().refresh();
      setTimeout(() => layer.getSource().refresh(), 1000);
    }
  };

  const handleQueryCheckboxChange = (event, item) => {
    let layer = getQueryLayer(item);
    if (layer) {
      layer.setVisible(event.target.checked);
      let newCheckedQuerysets = { ...props.checkedQuerysets };
      newCheckedQuerysets[item.layerId] = event.target.checked;
      props.setCheckedQuerysets(newCheckedQuerysets);
    }
  };

  const onSliderChange = (value, item) => {
    let layer = getLayer(item);
    if (layer) {
      layer.setOpacity(value);
      let newOps = { ...props.opacities };
      let key = props.getItemKey(item);
      newOps[key] = value;
      props.setOpacities(newOps);
    }
  };

  const getLayer = (item) => {
    if (props.layers) {
      let gis_services = props.getGisService(item);
      for (var i = 0; i < props.layers.length; i++) {
        var layer = props.layers[i];
        let url = layer.getProperties().service_url;
        let layer_name = layer.getProperties().layer_name;
        if (
          url + '/' + layer_name ===
          gis_services.service_url + '/' + gis_services.layer_name
        ) {
          return layer;
        }
      }
    }
    return null;
  };

  const getQueryLayer = (item) => {
    let layer_found = null;
    if (props.map.getLayers() && item.layerId !== undefined) {
      props.map.getLayers().forEach((layer) => {
        if (layer_found == null) {
          if (layer.getProperties) {
            if (item.layerId === layer.getProperties()['layerId']) {
              layer_found = layer;
            }
          }
        }
      });
    }

    return layer_found;
  };

  const date_formatter = (value) => {
    return `${new Date(value * 100000).toISOString().substring(0, 10)}`;
  };

  const [openModal, setOpenModal] = useState(false);
  const [modalTitle] = useState('Data Report');

  const [selectedUserLayer, setSelectedUserLayer] = useState();
  const [userLayerChecked, setUserLayerChecked] = useState(
    props.layersByUser.map((item) => true)
  );

  const [selectedBoundaryDataset, setSelectedBoundaryDataset] = useState();
  const [vector_tables, setVectorTables] = useState();

  useEffect(() => {
    setUserLayerChecked(props.layersByUser.map((layer) => layer.getVisible()));
  }, [props.layersByUser]);

  const hideModal = () => {
    setOpenModal(false);
  };

  // move the scroll bar to the top for opening a popup by clicking on the map
  useEffect(() => {
    let div = document.getElementById('info');
    if (div) {
      setTimeout(() => {
        div.scrollTop = 0;
      }, 500);
    }
  }, [modalTitle]);

  useEffect(() => {
    const fetch_vector_table = async () => {
      let base_url = process.env.REACT_APP_API_URL_DEV;

      const response = await fetch(`${base_url}/download/get_vectors`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;  charset=utf-8',
          Authorization: 'Bearer ' + props.access_token,
        },
      }).then((response) => {
        return response.json();
      });

      // const vector_layers = response;
      setVectorTables(response);
    };

    fetch_vector_table();
  }, []);

  const getCheckedDatasetsForReport = () => {
    let result = [];
    for (let i = 0; i < selectedDatasets.length; i++) {
      let dataset = selectedDatasets[i];

      let key = props.getItemKey(dataset);
      if (props.checkedDatasets[key]) {
        // if (dataset.data_type === 'vector') {
        result.push(dataset);
        // }
      }
    }

    return result;
  };

  const removeDatasetFromWorkspace = async (event, item) => {
    let keyToDelete = props.getItemKey(item);

    if (event) {
      for (let i = 0; i < selectedDatasets.length; i++) {
        let datasetKey = props.getItemKey(selectedDatasets[i]);
        if (keyToDelete === datasetKey) {
          let newSelectedDatasets = [...selectedDatasets];
          newSelectedDatasets.splice(i, 1);
          await setSelectedDatasets(newSelectedDatasets);

          let filterItems = { ...props.filterItems };
          delete filterItems[keyToDelete];
          await props.setFilterItems(filterItems);

          let checkedDatasets = { ...props.checkedDatasets };
          delete checkedDatasets[keyToDelete];
          await props.setCheckedDatasets(checkedDatasets);

          let opacities = { ...props.opacities };
          delete opacities[keyToDelete];
          await props.setOpacities(opacities);

          let valueRanges = { ...props.valueRanges };
          delete valueRanges[keyToDelete];
          await props.setValueRanges(opacities);

          break;
        }
      }
    }
  };
  const findCollectionName = (item) => {
    if (item.extras) {
      const obj = item.extras.find((obj) => {
        if (obj.key === 'collection_name') {
          return obj.value;
        }
      });
      return obj ? obj.value : null;
    } else {
      return item.dataset_collections[0].name;
    }
  };
  const getDataSources = () => {
    let dataList = selectedDatasets
      .filter((item) => {
        return findCollectionName(item);
      })
      .map((item) => findCollectionName(item));

    let data_sources = [];
    dataList.forEach((data) => {
      if (!data_sources.includes(data)) {
        data_sources.push(data);
      }
    });
    return data_sources.sort();
  };

  const removeQueryFromWorkspace = async (event, item) => {
    event.target.checked = false;
    await handleQueryCheckboxChange(event, item.mapping[0]);

    if (event) {
      let keyToDelete = item.mapping[0].layerId;

      for (let i = 0; i < props.queryLayers.length; i++) {
        let datasetKey = props.queryLayers[i].getProperties()['layerId'];
        if (keyToDelete === datasetKey) {
          let queryLayers = [...props.queryLayers];
          queryLayers.splice(i, 1);
          await props.setQueryLayers(queryLayers);
          break;
        }
      }

      let checkedQueries = { ...props.checkedQuerysets };
      checkedQueries[keyToDelete] = false;
      await props.setCheckedQuerysets(checkedQueries);

      let layer = getQueryLayer(item.mapping[0]);
      layer.setVisible(false);
      props.map.removeLayer(layer);

      for (let i = 0; i < props.queryLayerInfo.length; i++) {
        let datasetKey = props.queryLayerInfo[i].mapping[0].layerId;
        if (keyToDelete === datasetKey) {
          let queryLayerInfos = [...props.queryLayerInfo];
          queryLayerInfos.splice(i, 1);
          await props.setQueryLayerInfo(queryLayerInfos);
          break;
        }
      }
    }
  };

  const openUnitModal = (units, title) => {
    units = units.replace(/[^\x00-\x7F]/g, '');
    setUnits(units);
    setUnitModalTitle(title);
    setOpenUnitsModal(true);
  };

  const isFilterActive = () => {
    return props.queryLayerInfo.length > 0;
  };

  const resetFilter = async (item) => {
    let itemKey = props.getItemKey(item);
    let filterItems_new = { ...props.filterItems };
    let valueranges_new = { ...props.valueRanges };

    let min_max = null;

    let gis_service = props.getGisService(item);

    if (item.data_type === 'vector' || gis_service.data_type === 'vector') {
      let prevFilter = filterItems_new[itemKey];

      if (prevFilter !== undefined && prevFilter !== null) {
        Object.keys(prevFilter).forEach((key) => {
          prevFilter[key] = undefined;
        });
      }

      filterItems_new[itemKey] = prevFilter;
      valueranges_new[itemKey] = prevFilter;
    } else {
      let color_map = gis_service.color_map;
      if (color_map !== undefined) {
        let color_entries = color_map.color_map_entries.map(
          (e) => e.float_value
        );
        min_max = {
          values: [Math.min(...color_entries), Math.max(...color_entries)],
        };
      }

      if (min_max !== null) {
        filterItems_new[itemKey] = min_max;
        valueranges_new[itemKey] = min_max['values'];
      }
    }

    props.setFilterItems(filterItems_new);
    props.setValueRanges(valueranges_new);
  };

  const createFilterTooltip = (item) => {
    let key = props.getItemKey(item);
    let filter_val = props.filterItems[key];

    if (filter_val != undefined) {
      if (item.data_type == 'raster') {
        filter_val = filter_val.values;
        return filter_val[0].toString() + ' - ' + filter_val[1].toString();
      } else {
        let filter_text = [];
        Object.keys(filter_val)
          .filter(
            (key) => filter_val[key] != undefined && filter_val[key].length > 0
          )
          .forEach((key) => {
            let val = filter_val[key];

            if (Array.isArray(val) && typeof val[0] != 'string') {
              filter_text.push(
                val[0].toString() + ' <= ' + key + ' >= ' + val[1].toString()
              );
            } else if (Array.isArray(val)) {
              filter_text.push(key + ' IN ' + val.join(', '));
            } else {
              filter_text.push(key + ' = ' + val);
            }
          });

        let final_text = filter_text.join(', ');
        return final_text;
      }
    }
  };

  let rootStyle = {
    position: 'absolute',
    type: 'text',
    value: 'small',
    borderRadius: '6px',
  };

  let resetStyle = {
    position: 'absolute',
    left: '35px',
    padding: '1px 4px 1px 4px',
    borderRadius: '6px',
    color: '#B8B8B8',
    border: '3px solid rgba(255, 255, 255, 0.72)',
  };

  return (
    <div
      style={{
        color: '#000000a6',
        align: 'center',
        height: 'calc(100vh - 45px)',
        backgroundColor: '#E5E4E2',
        width: '350px',
        position: 'absolute',
        top: 0,
        right: 0,
        padding: '5px 1px 1px 4px',
        overflow: 'auto',
      }}
    >
      <Space direction="vertical" style={{ marginBottom: '10px' }}>
        {/* ******************** Intersection ******************* */}
        {isFilterActive() ? (
          <Collapse collapsible="icon" defaultActiveKey={['1']}>
            <Panel
              header="Intersection Layers"
              key="1"
              style={{ fontWeight: 'bold', backgroundColor: '#EEEDE3' }}
            >
              {props.queryLayerInfo.map((item, i) => {
                return (
                  item.mapping[0].type === 'vector' &&
                  item.mapping[0].layerId && (
                    <div key={'filter' + i} style={{ fontWeight: 'normal' }}>
                      <Row>
                        <Col span={21}>
                          <Checkbox
                            style={{
                              '--background-color': '#0014EC',
                              '--border-color': '#0014EC',
                            }}
                            checked={
                              props.checkedQuerysets[item.mapping[0].layerId]
                            }
                            onChange={(event) =>
                              handleQueryCheckboxChange(event, item.mapping[0])
                            }
                          >
                            <Text
                              strong
                              style={{ fontSize: 13, paddingRight: 5 }}
                            >
                              {item.mapping[0].name}
                            </Text>
                            <Tooltip
                              title={item.mapping[0].tooltip}
                              placement="top"
                            >
                              <InfoCircleOutlined />
                            </Tooltip>
                          </Checkbox>
                        </Col>
                        <Col span={3}>
                          <div style={rootStyle}>
                            <Tooltip
                              title={'Remove From \n Workspace'}
                              placement="right"
                            >
                              <Button
                                icon={<CloseOutlined />}
                                size={'small'}
                                onClick={(event) =>
                                  removeQueryFromWorkspace(event, item)
                                }
                              ></Button>
                            </Tooltip>
                          </div>
                        </Col>
                      </Row>

                      {i < props.queryLayerInfo.length - 1 ? (
                        <Divider
                          style={{
                            margin: '5px 0px 10px 0px',
                            borderTop: '1px solid lightgray',
                          }}
                        />
                      ) : null}
                    </div>
                  )
                );
              })}
            </Panel>
          </Collapse>
        ) : null}

        {/* ******************** All ******************* */}

        {selectedDatasets.length === 0 ? (
          <Collapse collapsible="header" defaultActiveKey={['1']}>
            <Panel
              header="Data Sources"
              key="1"
              style={{
                fontWeight: 'bold',
                width: 340,
                backgroundColor: '#EEEDE3',
              }}
            >
              <div>
                <span>Go to the Data Catalog to add data </span>
              </div>
            </Panel>
          </Collapse>
        ) : (
          getDataSources().map((data_source, source_index) => {
            return (
              <Collapse
                collapsible="header"
                defaultActiveKey={['1']}
                key={source_index}
              >
                <Panel
                  header={data_source}
                  key="1"
                  style={{
                    fontWeight: 'bold',
                    width: 340,
                    backgroundColor: '#EEEDE3',
                    marginBottom: 10,
                  }}
                >
                  <div>
                    {selectedDatasets
                      .filter((e) => {
                        return findCollectionName(e) === data_source;
                      })
                      .map((item, i) => {
                        const itemGis = props.getGisService(item);
                        const isBoundary =
                          itemGis.layer_name.search('boundary') === -1
                            ? false
                            : true;
                        const isITS =
                          itemGis.service_url.search('ITS') === -1
                            ? false
                            : true;
                        return (
                          <div
                            key={'raster_' + i}
                            style={{ fontWeight: 'normal' }}
                          >
                            <Row>
                              <Col span={21}>
                                <Checkbox
                                  style={{
                                    '--background-color': '#61633f',
                                    '--border-color': '#9C9D86',
                                    borderColor: '#9C9D86',
                                  }}
                                  checked={
                                    props.checkedDatasets[
                                      props.getItemKey(item)
                                    ]
                                  }
                                  onChange={(event) =>
                                    handleCheckboxChange(event, item)
                                  }
                                >
                                  <Text strong style={{ fontSize: 13 }}>
                                    {item.title ? item.title : item.name}
                                  </Text>
                                </Checkbox>
                              </Col>
                              <Col span={3}>
                                <div style={rootStyle}>
                                  <Tooltip
                                    title={'Remove From \n Workspace'}
                                    placement="right"
                                  >
                                    <Button
                                      icon={<CloseOutlined />}
                                      size={'small'}
                                      onClick={(event) =>
                                        removeDatasetFromWorkspace(event, item)
                                      }
                                    ></Button>
                                  </Tooltip>
                                </div>
                              </Col>
                            </Row>
                            {Object.keys(props.loading_datasets).filter(
                              (e) =>
                                e === props.getItemKey(item) &&
                                props.loading_datasets[e]
                            ).length > 0 ? (
                              <Spin></Spin>
                            ) : (
                              <div style={{ paddingLeft: '25px' }}>
                                <Row
                                  align="middle"
                                  style={{ marginBottom: -6 }}
                                >
                                  <Col span={10}>
                                    <Text style={{ fontSize: 13 }}>
                                      Opacity
                                    </Text>
                                  </Col>
                                  <Col span={14}>
                                    <Slider
                                      style={{ width: '130px' }}
                                      trackStyle={{
                                        backgroundColor: '#9d9f69',
                                        color: 'orange',
                                      }}
                                      min={0}
                                      max={1}
                                      step={0.01}
                                      value={
                                        props.opacities[props.getItemKey(item)]
                                      }
                                      onChange={(value) =>
                                        onSliderChange(value, item)
                                      }
                                      disabled={
                                        !props.checkedDatasets[
                                          props.getItemKey(item)
                                        ]
                                      }
                                    />
                                  </Col>
                                </Row>
                                {(isBoundary || isITS) && (
                                  <Row style={{ marginBottom: 10 }}>
                                    <Col span={8}>
                                      <Text
                                        style={{
                                          fontSize: 13,
                                          paddingRight: 5,
                                        }}
                                      >
                                        Filter
                                      </Text>
                                      <Tooltip
                                        title={createFilterTooltip(item)}
                                        placement="top"
                                        data-html="true"
                                      >
                                        <InfoCircleOutlined></InfoCircleOutlined>
                                      </Tooltip>
                                    </Col>
                                    <Col span={16}>
                                      <span>
                                        <FilterModal
                                          item={item}
                                          getGisService={props.getGisService}
                                          getItemKey={props.getItemKey}
                                          filtersDatasets={
                                            props.filtersDatasets
                                          }
                                          setFilterDatasets={
                                            props.setFilterDatasets
                                          }
                                          filterItems={props.filterItems}
                                          setFilterItems={props.setFilterItems}
                                          filter_options={props.filter_options}
                                          setFilterOptions={
                                            props.setFilterOptions
                                          }
                                          layers={props.layers}
                                        ></FilterModal>
                                      </span>
                                      <span>
                                        <Tooltip
                                          title="Reset Filter"
                                          placement="top"
                                        >
                                          <Button
                                            style={resetStyle}
                                            icon={<UndoOutlined />}
                                            disabled={
                                              !props.filterItems[
                                                props.getItemKey(item)
                                              ]
                                            }
                                            onClick={() => resetFilter(item)}
                                          ></Button>
                                        </Tooltip>
                                      </span>
                                    </Col>
                                  </Row>
                                )}
                                {!isBoundary ? (
                                  <div style={{ marginBottom: 10 }}>
                                    <Row>
                                      <Col span={8}>
                                        <Text
                                          style={{
                                            fontSize: 13,
                                            paddingRight: 5,
                                          }}
                                        >
                                          Download
                                        </Text>
                                      </Col>
                                      <Col span={16}>
                                        <ForestDataMapDownload
                                          item={item}
                                          valueRanges={props.valueRanges}
                                          filterItems={props.filterItems}
                                          access_token={props.access_token}
                                          vector_tables={vector_tables}
                                          itemGis={itemGis}
                                          getGisService={props.getGisService}
                                        />
                                      </Col>
                                    </Row>

                                    {isITS ? (
                                      <div style={{ marginTop: 10 }}>
                                        <Row>
                                          <Col span={8}>
                                            <Text
                                              style={{
                                                fontSize: 13,
                                                paddingRight: 5,
                                              }}
                                            >
                                              Report
                                            </Text>
                                          </Col>
                                          <Col span={16}>
                                            <ForestDataMapITSReport />
                                          </Col>
                                        </Row>
                                      </div>
                                    ) : null}
                                  </div>
                                ) : null}
                                {/* TODO fix up unites */}
                                {/* {item.dataset_metadata.filter((e) =>
                                e.name.toLowerCase().includes('units')
                              ).length > 0 ? (
                                <Row>
                                  <Col span={24}>
                                    <Text
                                      style={{
                                        fontSize: 13,
                                        paddingRight: 15,
                                        color: 'grey',
                                      }}
                                    >
                                      Units:
                                      {item.dataset_metadata.filter((e) =>
                                        e.name.toLowerCase().includes('units')
                                      )[0].text_value.length > 150 ? (
                                        <span>
                                          <span style={{ paddingLeft: 5 }}>
                                            <InfoCircleTwoTone
                                              twoToneColor="green"
                                              onClick={() =>
                                                openUnitModal(
                                                  item.dataset_metadata.filter(
                                                    (e) =>
                                                      e.name
                                                        .toLowerCase()
                                                        .includes('units')
                                                  )[0].text_value,
                                                  item.name
                                                )
                                              }
                                            />
                                          </span>
                                        </span>
                                      ) : (
                                        <span
                                          style={{ paddingLeft: 5 }}
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              item.dataset_metadata.filter(
                                                (e) =>
                                                  e.name
                                                    .toLowerCase()
                                                    .includes('units')
                                              )[0].text_value,
                                          }}
                                        ></span>
                                      )}
                                    </Text>
                                  </Col>
                                </Row>
                              ) : (
                                <div style={{ marginTop: 20 }}></div>
                              )} */}
                              </div>
                            )}
                            {getCheckedDatasetsForReport().length > 0 &&
                            props
                              .getGisService(item)
                              .layer_name.startsWith('boundary:') ? (
                              <div
                                style={{ width: '100%', textAlign: 'center' }}
                              >
                                {/* TODO add Create report back */}
                                {/* <Button
                                type="primary"
                                style={{
                                  backgroundColor: props.checkedDatasets[
                                    props.getItemKey(item)
                                  ]
                                    ? '#1F7D1F'
                                    : 'lightgray',
                                  borderColor: 'white',
                                  marginTop: 10,
                                  marginLeft: 20,
                                }}
                                onClick={() => {
                                  setSelectedBoundaryDataset(item);
                                  setSelectedUserLayer(null);
                                  setOpenModal(true);
                                }}
                                disabled={
                                  !props.checkedDatasets[props.getItemKey(item)]
                                }
                              >
                                Create Report
                              </Button> */}
                              </div>
                            ) : null}
                            {i <
                            selectedDatasets.filter((e) => {
                              return (
                                findCollectionName(e) === data_source.value
                              );
                            }).length -
                              1 ? (
                              <Divider
                                style={{
                                  margin: '5px 0px 10px 0px',
                                  borderTop: '1px solid lightgray',
                                }}
                              />
                            ) : null}
                          </div>
                        );
                      })}
                  </div>
                </Panel>
              </Collapse>
            );
          })
        )}
      </Space>
      <Modal
        title={modalTitle}
        open={openModal}
        onCancel={hideModal}
        okText="OK"
        cancelText="Cancel"
        width={900}
        footer={
          <div textAlign="left">
            * All text in this report has been created by conversational AI. You
            can edit the data title and description.
          </div>
        }
      >
        <div
          id="info"
          style={{
            maxHeight: '50vh',
            minHeight: 300,
            overflow: 'auto',
            marginTop: -10,
          }}
        >
          {selectedUserLayer ? (
            <UserDataReport
              selectedUserLayer={selectedUserLayer}
              checkedDatasets={getCheckedDatasetsForReport()}
              layers={props.layers}
            />
          ) : null}
          {selectedBoundaryDataset ? (
            <WFSDataReport
              selectedFeatureLayer={selectedBoundaryDataset}
              checkedDatasets={getCheckedDatasetsForReport()}
              layers={props.layers}
              getGisService={props.getGisService}
            />
          ) : null}
        </div>
      </Modal>
      <Modal
        title={unitModalTitle + ' Units'}
        open={openUnitsModal}
        onCancel={() => setOpenUnitsModal(false)}
        footer={null}
      >
        <div>
          <span dangerouslySetInnerHTML={{ __html: unitsForModal }}></span>
        </div>
      </Modal>
    </div>
  );
};

export default ForestDataMapLayerTree;
