import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import {
  DownloadOutlined,
  LoadingOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import {
  Tooltip,
  notification,
  Modal,
  Select,
  Form,
  Row,
  Col,
  Input,
  Spin,
  Dropdown,
} from 'antd';
import './forest_data_map_layer_popover.css';

import WPS from '../lib/WPS';
import { BoundaryData } from './BoundaryData';
import { Button } from 'react-bootstrap';

const boundariesMap = new Map([
  [
    'BLM CA Administrative Unit Boundary Field Office Polygon',
    {
      name: 'boundary:blm_ca_administrative_unit_boundary_field_office_polygon',
      label: 'admu_name',
    },
  ],
  [
    'Administrative Forest Boundaries',
    { name: 'boundary:forest_administrative_boundaries', label: 'forestname' },
  ],
  [
    'CAL FIRE Operational Units',
    { name: 'boundary:cal_fire_operational_units', label: 'unit' },
  ],
  [
    'California State Senate Districts',
    {
      name: 'boundary:california_state_senate_districts_map_2020',
      label: 'name',
    },
  ],
  [
    'California Assembly Districts',
    { name: 'boundary:assembly_districts', label: 'assemblydi' },
  ],
  [
    'Regional Resource Kit Boundaries',
    { name: 'boundary:rrk_boundaries', label: 'rrk_region' },
  ],
  [
    'California Local Fire Districts',
    {
      name: 'boundary:california_local_fire_districts',
      label: 'name',
    },
  ],
  ['California Counties', { name: 'boundary:ca_counties', label: 'namelsad' }],
]);

class ForestDataMapDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feature_attributes: [],
      hover: false,
      visible: false,
      download_loading: false,
      error: undefined,
      vector_features: [],
      chosen_layer: undefined,
      chosen_location: undefined,
    };
    this.onDownload = this.onDownload.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toggleHover = this.toggleHover.bind(this);
    this.setVectorFeature = this.setVectorFeature.bind(this);
    this.setLocation = this.setLocation.bind(this);
  }

  toggleHover() {
    this.setState({ hover: !this.state.hover });
  }

  setVectorFeature = (key) => {
    this.setState({ vector_features: BoundaryData[key] });
    this.setState({ chosen_layer: key });
  };

  setLocation = (key) => {
    this.setState({ chosen_location: key });
  };

  handleClose(event) {
    this.popup.setPosition(undefined);
    event.target.blur();
    return false;
  }

  openModal() {
    this.setState({ visible: true });
  }

  closeModal() {
    this.setState({ chosen_location: undefined });
    this.setState({ visible: false });
    this.setState({ download_loading: false });
  }

  // makeRequest(o){
  //   var xhr = new XMLHttpRequest(),
  //       h = new WebView.Handler(),
  //       path, type, req, binary;

  //   if(typeof o === 'string') {
  //       path = o;
  //   } else {
  //       path = o.path;
  //       type = o.type;
  //       req = o.args;
  //       binary = o.binary;
  //   }

  //   if(!type) {
  //       type = 'GET';
  //   }

  //   xhr.onreadystatechange = function() {
  //       var d;
  //       if(xhr.readyState === 4) {
  //           if(xhr.status === 200) {
  //               h._handleAlways();
  //               if(binary) {
  //                   d = xhr.response;
  //               } else {
  //                   d = xhr.responseText ? JSON.parse(xhr.responseText) : null;
  //               }
  //               h._handleDone(d);
  //           } else if(xhr.status !== 0 && xhr.status !== 200) {
  //               h._handleAlways();
  //               if(xhr.responseText.indexOf("{") == 0) {
  //                   d = xhr.responseText ? JSON.parse(xhr.responseText) : null;
  //                   if(d && d.error) {
  //                       h._handleFail(d.error);
  //                   } else {
  //                       h._handleFail(null);
  //                   }
  //               } else {
  //                   h._handleFail(xhr.responseText || xhr.statusText);
  //               }
  //           }
  //       }
  //   };

  //   // NOTE: firefox will not send the request if to a different
  //   // origin (CORS) and user/passwd specified in open().
  //   //xhr.open(type, this._url + '/' + path, true, this._username, this._password);
  //   xhr.open(type, this._url + '/' + path, true);

  //   // check for network errors.
  //   xhr.onerror = function() {
  //       h._handleAlways();
  //       h._handleFail('Network error contacting server.');
  //   };

  //   xhr.withCredentials = true;

  //   if(binary) {
  //       xhr.responseType = 'blob';
  //   }

  //   // use custom Authorization scheme to prevent browsers (e.g., Chrome) from opening auth dialog.
  //   xhr.setRequestHeader('Authorization', 'WebView ' + btoa(this._username + ':' + this._password));
  //   xhr.send(JSON.stringify(req));
  //   return h;
  // }

  async onDownload(values) {
    this.setState({ download_loading: true });
    if (values.fileName.includes('.')) {
      this.setState({
        error:
          'The file name has an extention or a period. Please remove the period in your filename.',
      });
      this.setState({ download_loading: false });
    } else if (values.fileName.includes(' ')) {
      this.setState({
        error:
          'The file name has a space(s) in it. Please remove spaces from your filename.',
      });
      this.setState({ download_loading: false });
    } else {
      let layer_name;
      if (this.props.item.gis_services) {
        let service_url = this.props.item.gis_services[0].service_url;
        layer_name = this.props.item.gis_services[0].layer_name;
      } else {
        layer_name = this.props.itemGis.layer_name;
      }
      // let full_layer_url = service_url + '/' + layer_name;
      //
      // let filter_ranges;

      // if (Object.keys(this.props.valueRanges).includes(full_layer_url)) {
      //   filter_ranges = this.props.valueRanges[full_layer_url];
      //   let default_range = [
      //     this.props.item['dataset_metadata'].filter(
      //       (e) => e.name === 'min_value'
      //     )[0]['float_value'],
      //     this.props.item['dataset_metadata'].filter(
      //       (e) => e.name === 'max_value'
      //     )[0]['float_value'],
      //   ];
      //   if (
      //     filter_ranges[0] == default_range[0] &&
      //     filter_ranges[1] == default_range[1]
      //   ) {
      //     filter_ranges = 'undefined';
      //   }
      // }
      let base_url = process.env.REACT_APP_API_URL_DEV;
      let access_token = this.props.access_token;

      let vector_layer_name =
        values.layer_name === undefined ? null : values.layer_name;
      let feature_value =
        values.feature_value === undefined ? null : values.feature_value;
      if (this.props.itemGis.data_type === 'raster') {
        let url =
          feature_value === null
            ? `${base_url}/download/download_layer/${encodeURI(layer_name)}/${values.fileName}`
            : `${base_url}/download/download_layer/${encodeURI(layer_name)}/${values.fileName}?vector_layer_name=${encodeURI(vector_layer_name)}&vector_column_filter=${encodeURI(feature_value)}`;
        // TODO dynamic
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + access_token,
          },
        })
          .then(async (response) => {
            return await response.blob();
          })
          .then((blob) => {
            var blobURL = URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blobURL;
            anchor.download = values.fileName + '.zip';
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
          });

        this.setState({ download_loading: false });
      } else {
        const boudnaryData = boundariesMap.get(vector_layer_name);

        await WPS.getDownload(
          values.fileName,
          this.props.itemGis,
          boudnaryData,
          feature_value
        );
      }
      this.setState({ download_loading: false });
      this.closeModal();
    }
  }

  render() {
    let rootStyle = this.props.style;
    if (rootStyle === undefined) {
      rootStyle = {
        ...rootStyle,
        position: 'absolute',
        // top: '96px',
        // left: '7px',
        padding: '1px 4px 1px 4px',
        borderRadius: '6px',
        backgroundColor: this.state.hover ? '#61633f' : '#9C9D86',
        border: '3px solid rgba(255, 255, 255, 0.72)',
      };
    }
    const rasterData = this.props.itemGis.data_type !== 'raster';
    const filterData = rasterData && !this.state.chosen_location;
    return (
      <span>
        <span
          style={rootStyle}
          onMouseEnter={this.toggleHover}
          onMouseLeave={this.toggleHover}
        >
          <Tooltip title={'Download'} placement="bottom">
            <DownloadOutlined
              onClick={this.openModal}
              style={{ color: 'white' }}
            />
          </Tooltip>
        </span>
        <Modal
          title={`Subset and Download ${this.props.item.title} Data`}
          open={this.state.visible}
          onCancel={this.closeModal}
          destroyOnClose={true}
          // onOk={this.onDownload}
          confirmLoading={this.state.download_loading}
          footer={
            this.state.download_loading ? (
              <Button
                form="download_data"
                type="primary"
                htmlType="submit"
                disabled
              >
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
                />
                <span style={{ paddingLeft: 10 }}>Download</span>
              </Button>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  justifyContent: 'end',
                }}
              >
                {rasterData && (
                  <p style={{ margin: '0px 10px 0px 0px' }}>
                    <strong>Warning:</strong> The dataset is too large to
                    download. Please select a specific region to crop.
                  </p>
                )}
                <Button
                  form="download_data"
                  type="primary"
                  htmlType="submit"
                  disabled={filterData}
                  style={{
                    backgroundColor: '#61633f',
                    border: 'none',
                  }}
                >
                  Download
                </Button>
              </div>
            )
          }
          width={700}
          bodyStyle={{
            maxHeight: 350,
          }}
        >
          <div>
            {this.state.error ? (
              <div style={{ color: 'red', marginBottom: '10px' }}>
                {this.state.error}
              </div>
            ) : null}
            <Form
              name="download_data"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 24,
              }}
              layout="vertical"
              autoComplete="off"
              onFinish={(values) => this.onDownload(values)}
              scrollToFirstError="true"
            >
              <Form.Item
                style={{ marginBottom: '4px' }}
                label="File Name of Download"
                name="fileName"
                rules={[
                  {
                    required: true,
                    message: 'Please input your preferred file name',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <div>
                <span style={{ fontSize: '10px' }}>
                  * Do not add an extention to your file name
                </span>
              </div>
              <br></br>
              {BoundaryData ? (
                <>
                  <span>Subset and Download to a Location</span>
                  <Row>
                    <Col span={11}>
                      <span>Select Boundary Dataset</span>
                      <Form.Item name="layer_name">
                        <Select
                          placeholder="Select a Dataset"
                          onChange={this.setVectorFeature}
                          options={Object.keys(BoundaryData).map((e) => {
                            return {
                              value: e,
                              label: e,
                            };
                          })}
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col span={12} style={{ marginLeft: '10px' }}>
                      <span>Select Location from Dataset</span>
                      <Form.Item
                        name="feature_value"
                        disabled={this.state.chosen_layer != undefined}
                      >
                        <Select
                          showSearch
                          placeholder="Select a Location"
                          onChange={this.setLocation}
                          options={
                            this.state.vector_features &&
                            this.state.vector_features.length > 0
                              ? this.state.vector_features.map((e) => {
                                  return {
                                    value: e,
                                    text: e,
                                  };
                                })
                              : []
                          }
                        ></Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ) : (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Spin
                    style={{}}
                    indicator={
                      <LoadingOutlined style={{ fontSize: 20 }} spin />
                    }
                  />
                </div>
              )}
            </Form>
          </div>
        </Modal>
      </span>
    );
  }
}

export default ForestDataMapDownload;
