import { Modal, Button, Table } from 'react-bootstrap';
const DataModal = (props) => {
  const { show, onHide, data } = props;

  const itemGis = props.getGisService(data);

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  return (
    <Modal
      show={true}
      onHide={onHide}
      size="xl"
      aria-labelledby="view_more_modal"
      centered
    >
      <Modal.Header closeButton>
        <h4>{data.title} </h4>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: 'flex' }}>
          <p>{data.notes}</p>{' '}
          <div style={{ border: '2px' }}>
            {
              <img
                height={'200px'}
                width={'200px'}
                style={{
                  borderWidth: '0px',
                  borderRadius: '5px',
                  border: '5px solid',
                  borderColor: '#525335',
                }}
                src={
                  `https://sparcal.sdsc.edu/api/v1/Utility/wms_thumbnail?wms_url=` +
                  itemGis.service_url +
                  `&layer_name=` +
                  itemGis.layer_name.split(':')[1] +
                  `&width=200&height=200`
                }
              ></img>
            }
          </div>
        </div>
        {data.extras && (
          <>
            <h4>Additional Information </h4>
            <Table>
              <tbody>
                {data.extras.map((data, i) => {
                  return (
                    <tr key={'extra' + i}>
                      <td width={200}>
                        {capitalize(data.key.replace(/_/g, ' '))}
                      </td>
                      <td>{data.value}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onHide()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default DataModal;
