import { useContext, useState, useEffect, useRef, useMemo } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { usePagination } from '@ajna/pagination';
import { ArrowRightOutlined } from '@ant-design/icons';
import WorkspaceContext from '../WorkspaceContext';
import SearchBarHeader from '../components/searchBar/SearchBarHeader';
import ListCard from '../components/ListCard';
import { Pagination } from 'antd';
import CKAN from '../lib/CKAN';
import DataModal from '../components/modal/DataModal';
import APIsModal from '../components/modal/APIsModal';
import PermissionsHelper from '../util/PermissionsHelper';
import Sparcal from '../lib/Sparcal';
import DeleteModal from '../components/modal/DeleteModal';

const DataCatalogNew = (props) => {
  const toDisplay = props.display === 'data_catalog_new';
  // const [category, setCategory] = useState([]);
  // const [region, setRegion] = useState(props.regionReq);
  const [ckanData, setCkanData] = useState(null);
  const [collectionNames, setCollectionNames] = useState('');
  const [pillar, setPillar] = useState('');
  const [element, setElement] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [modalImage, setModalImage] = useState(null);
  const [apisModalShow, setAPIsModalShow] = useState(false);
  const [apisModalData, setAPIsModalData] = useState(null);
  const [deleteData, setDeleteData] = useState(null);

  const {
    selectedDatasets,
    setSelectedDatasets,
    // setRegionReq,
    // setDefaultKeys,
    // setSelectedKeys,
    // defaultKeys,
    // selectedKeys,
  } = useContext(WorkspaceContext);

  const [isLoading, setIsLoading] = useState(false);
  const [currentDatasets, setCurrentDatasets] = useState([]); // per page
  const [numDatasets, setNumDatasets] = useState(0); // total datasets to display in pages
  const [searchResults, setSearchResults] = useState([]); // total datasets upon searching
  const [searchQuery, setSearchQuery] = useState('');
  const [searchFlag, setSearchFlag] = useState(false);
  const [searchType, setSearchType] = useState('substring_search');
  const [spatialQuery, setSpatialQuery] = useState('');
  const [bbox, setBbox] = useState(false);
  const viewCreateButton = PermissionsHelper(props.user.roles, 'publisher');

  const { currentPage, setCurrentPage, pageSize, offset } = usePagination({
    total: numDatasets,
    initialState: {
      pageSize: 20,
      currentPage: 1,
      isDisabled: false,
    },
  });

  // Header count
  useMemo(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const ckanDatasets = await CKAN.getDatasets();
      setCkanData(ckanDatasets);
      setIsLoading(false);
    };
    if (toDisplay) {
      fetchData();
    }
  }, [toDisplay]);

  useEffect(() => {
    setIsLoading(true);
    if (ckanData) {
      if (!searchFlag) {
        // without search

        const data = ckanData.slice(offset, offset + pageSize);
        setCurrentDatasets(data);
        setNumDatasets(ckanData.length);
        setIsLoading(false);
      } else {
        // on search
        // for substring_search
        const dataToDisplay = searchResults.slice(offset, offset + pageSize); // offset is the start index based on current page number
        setCurrentDatasets(dataToDisplay);
        setNumDatasets(searchResults.length);
        setIsLoading(false);
      }
    }
  }, [currentPage, pageSize, offset, searchResults, ckanData]);

  useEffect(() => {
    handleSearchClick();
  }, [collectionNames, pillar, element]);
  const handlePageChange = (nextPage) => {
    // -> request new data using the page number
    setCurrentPage(nextPage);
    //
  };

  // Search data

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
    // setSearchFlag(false)
  };

  const handleSearchClick = async () => {
    var query = searchQuery;
    if (
      (collectionNames && query) ||
      searchType === 'substring_search' ||
      spatialQuery
    ) {
      setIsLoading(true);
      const results = await CKAN.searchDatasets({
        searchString: query,
        collectionNames: collectionNames,
        pillar: pillar,
        element: element,
      });
      setSearchFlag(true);
      setSearchResults(results);
      setIsLoading(false);
    } else {
      setSearchFlag(false);
      setSearchQuery('');
      setSearchResults([]);
      // setSelectedOrg('');
      // setSelectedTag('');
      setCollectionNames('');
      setPillar('');
      setElement('');
      setCurrentPage(1);
      setSpatialQuery('');
    }
  };
  // TODO implement clear button
  //   const handleClearClick = () => {
  //     setSearchFlag(false);
  //     setSearchQuery('');
  //     setSearchResults([]);
  //     setSelectedOrg('');
  //     setCurrentPage(1);
  //     setSpatialQuery('');
  //     if (bbox) {
  //       setBbox(false);
  //       if (rectangleRef.current && mapRef.current) {
  //         mapRef.current.removeLayer(rectangleRef.current);
  //       }
  //       // setMap()
  //     }
  //   };

  const inWorkspace = (dataset) => {
    if (!dataset) return false;
    for (let i = 0; i < selectedDatasets.length; i++) {
      if (selectedDatasets[i].id === dataset.id) {
        return true;
      }
    }

    return false;
  };
  const addToWorkspace = (dataset) => {
    if (!inWorkspace()) {
      let newSelectedDatasets = [...selectedDatasets];
      newSelectedDatasets.push(dataset);
      setSelectedDatasets(newSelectedDatasets);
    }
  };

  const removeFromWorkspace = (dataset) => {
    for (let i = 0; i < selectedDatasets.length; i++) {
      if (selectedDatasets[i].id === dataset.id) {
        let newSelectedDatasets = [...selectedDatasets];
        newSelectedDatasets.splice(i, 1);
        setSelectedDatasets(newSelectedDatasets);
        break;
      }
    }
  };
  // Map
  let mapRef = useRef(null); // Ref to keep track of map instance
  let rectangleRef = useRef(null);
  useEffect(() => {
    // setMap();
  }, []);

  // TODO support map search
  //   const setMap = () => {
  //     mapRef.current?.remove();
  //     mapRef.current = null;
  //     if (typeof window !== 'undefined') {
  //       const L = require('leaflet'); // Dynamically require leaflet
  //       require('leaflet-draw'); // Dynamically require leaflet-draw

  //       if (!mapRef.current) {
  //         // Initialize the map centered on Yosemite National Park
  //         mapRef.current = L.map('map').setView([37.0902, -95.7129], 3);

  //         // // Add OpenStreetMap tiles
  //         // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
  //         //   attribution: '&copy; OpenStreetMap contributors',
  //         // }).addTo(mapRef.current)

  //         L.tileLayer(
  //           'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
  //           {
  //             attribution: 'Tiles &copy; Esri',
  //           }
  //         ).addTo(mapRef.current);

  //         // Initialize the drawing tool
  //         const drawControl = new L.Control.Draw({
  //           draw: {
  //             polygon: false,
  //             polyline: false,
  //             circle: false,
  //             marker: false,
  //             circlemarker: false,
  //             rectangle: true, // Enable rectangle drawing (bounding box)
  //           },
  //         });
  //         mapRef.current?.addControl(drawControl);

  //         // Handle the "draw:created" event when a rectangle is drawn
  //         mapRef.current?.on(L.Draw.Event.CREATED, (event) => {
  //           const layer = event.layer;
  //           if (rectangleRef.current && mapRef.current) {
  //             mapRef.current.removeLayer(rectangleRef.current);
  //           }
  //           rectangleRef.current = layer;
  //           layer.addTo(mapRef.current);
  //           setBbox(true);

  //           // Get the bounding box coordinates
  //           const bounds = layer.getBounds();
  //           const southWest = bounds.getSouthWest();
  //           const northEast = bounds.getNorthEast();

  //           const bbox = [
  //             southWest.lng, // min longitude
  //             southWest.lat, // min latitude
  //             northEast.lng, // max longitude
  //             northEast.lat, // max latitude
  //           ];

  //           const spatialQ = `ext_bbox=${bbox.join(',')}`;
  //           // console.log(spatialQ)
  //           setSpatialQuery(spatialQ);
  //           console.log('toast3');
  //           //   toast({
  //           //     title: 'Bounding box is selected.',
  //           //     description: 'Click on search to execute!',
  //           //     status: 'info',
  //           //     variant: 'left-accent',
  //           //     duration: 2000,
  //           //     isClosable: true,
  //           //     position: 'top-right',
  //           //   });
  //         });
  //       }
  //     }
  //   };

  return (
    <>
      {toDisplay && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            height: '100vh',
          }}
        >
          {modalShow && (
            <DataModal
              getGisService={props.getGisService}
              data={modalData}
              image={modalImage}
              onHide={() => {
                setModalShow(false);
                setModalData(null);
                setModalImage(null);
              }}
            />
          )}
          {deleteData && (
            <DeleteModal
              data={deleteData}
              user={props.user}
              onDelete={async () => {
                await Sparcal.deleteDataset(props.user, deleteData.id);
                setCkanData(
                  ckanData.filter((data) => data.id !== deleteData.id)
                );
              }}
              onHide={() => {
                setDeleteData(null);
              }}
            />
          )}
          {apisModalShow && (
            <APIsModal
              data={apisModalData}
              onHide={() => {
                setAPIsModalShow(false);
                setAPIsModalData(null);
              }}
            />
          )}
          <div
            style={{
              backgroundSize: 'cover',
              margin: '60px 10%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '8px',
              }}
            >
              <h1
                style={{
                  color: '#525335',
                  fontWeight: 'bolder',
                  fontSize: '36px',
                  marginBottom: '0px',
                }}
              >
                Data Catalog
              </h1>
              {viewCreateButton && (
                <Button
                  variant="primary"
                  style={{
                    backgroundColor: '#CA7E15',
                    fontWeight: 'bolder',
                    fontSize: '14px',
                  }}
                  onClick={() => props.setDisplay('add_data')}
                >
                  Register or Edit (Data Ingestion){' '}
                  <ArrowRightOutlined style={{ fontSize: '15px' }} />
                </Button>
              )}
            </div>
            <SearchBarHeader
              searchQuery={searchQuery}
              handleSearchClick={handleSearchClick}
              handleChange={handleChange}
              collectionNames={collectionNames}
              setCollectionNames={setCollectionNames}
              pillar={pillar}
              setPillar={setPillar}
              element={element}
              setElement={setElement}
            ></SearchBarHeader>
          </div>
          <div
            style={{
              backgroundColor: '#EEEDE2',
              backgroundSize: 'cover',
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '35px',
            }}
          >
            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <Spinner animation="border" variant="success" />
              </div>
            ) : null}
            <br />
            {numDatasets > pageSize ? (
              <Pagination
                defaultPageSize={pageSize}
                defaultCurrent={1}
                onChange={handlePageChange}
                total={numDatasets}
              />
            ) : null}

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {currentDatasets.length > 0 ? (
                currentDatasets.map((dataset, idx) => {
                  return (
                    <ListCard
                      key={dataset.name + idx}
                      dataset={dataset}
                      setModalData={setModalData}
                      setModalShow={setModalShow}
                      setModalImage={setModalImage}
                      setAPIsModalData={setAPIsModalData}
                      setAPIsModalShow={setAPIsModalShow}
                      setDeleteData={() => {
                        setDeleteData(dataset);
                      }}
                      addToWorkspace={() => addToWorkspace(dataset)}
                      removeFromWorkspace={() => removeFromWorkspace(dataset)}
                      inWorkspace={() => inWorkspace(dataset)}
                      user={props.user}
                      getGisService={props.getGisService}
                    />
                  );
                })
              ) : (
                <div>{!isLoading && <h2>No datasets found</h2>}</div>
              )}
            </div>
            {numDatasets > pageSize ? (
              <Pagination
                defaultPageSize={pageSize}
                defaultCurrent={1}
                onChange={handlePageChange}
                total={numDatasets}
              />
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};
export default DataCatalogNew;
