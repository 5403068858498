import { setIn } from 'formik/dist';
import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap';

const AddEditCustomFields = (props) => {
  const { values, setFieldValue } = props;
  //   const [values, setvalues] = useState(values);

  // Setup for initial state
  useEffect(() => {
    if (values.length < 3) {
      console.log('useEffect');
      const difference = 3 - values.length;
      let update = values;
      for (let i = 0; i <= difference; i++) {
        setFieldValue('customFields', [...update, '']);
      }
    }
  }, []);

  const handleKeyChange = (key, index) => {
    let update = values.slice();
    update[index] = { key: key, value: values[index]?.value || '' };
    setFieldValue('customFields', update);
  };
  const handleValueChange = (value, index) => {
    let update = values.slice();
    update[index] = { key: values[index]?.key || '', value: value };
    setFieldValue('customFields', update);
  };
  const handleAddField = () => {
    let update = values;

    setFieldValue('customFields', [...update, '']);
    console.log('inivalue', values);
  };
  const handleDeleteField = (index) => {
    console.log('delete');
    let removal = values;
    removal.splice(index, 1);
    setFieldValue('customFields', [...removal]);
  };

  // const initialState = value.
  return (
    <React.StrictMode>
      {values?.map((value, index) => {
        return (
          <Row style={{ paddingBottom: '8px' }}>
            <Form.Group as={Col}>
              <InputGroup>
                <InputGroup.Text>Key:</InputGroup.Text>
                <Form.Control
                  defaultValue={value?.key || ''}
                  onChange={(event) => {
                    handleKeyChange(event.target.value, index);
                  }}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <InputGroup>
                <InputGroup.Text>Value:</InputGroup.Text>
                <Form.Control
                  defaultValue={value?.value || ''}
                  onChange={(event) => {
                    handleValueChange(event.target.value, index);
                  }}
                />
              </InputGroup>
            </Form.Group>
            <Button
              style={{
                backgroundColor: '#a64500',
                fontWeight: 'bold',
                border: 'none',
                width: '37px',
                height: '37px',
                padding: '6px',
              }}
              onClick={() => handleDeleteField(index)}
            >
              X
            </Button>
          </Row>
        );
      })}
      <Button style={{ width: '150px' }} onClick={handleAddField}>
        Add custom field
      </Button>
    </React.StrictMode>
  );
};
export default AddEditCustomFields;
