import React from 'react';
import { Typography, Row, Col, Space } from 'antd';
import forest_image from './images/home_forest.jpeg';
import cwi_image from './images/cwi_full_logo.png';
import interagency_treatment from './images/interagency_treatment.png';
import cwi_small_logo from './images/cropped-CWFRTF_logo.png';
import Carousel from './components/carousel/Carousel';

const { Title } = Typography;
const Home = () => {
  return (
    <body style={{ backgroundColor: '#FDFEF5', height: '95%' }}>
      <div style={{ display: 'inline-flex', width: '100%', height: '100%' }}>
        <div
          style={{
            textAlign: 'left',
            // padding: 80,
            backgroundImage: `url(${forest_image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            width: '100%',
            height: '60%',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              padding: '3% 5% ',
            }}
          >
            <div style={{ marginRight: '5%', flex: 1 }}>
              <h1
                style={{
                  color: '#1F4E11',
                  textAlign: 'left',
                  fontWeight: 'normal',
                  fontSize: 28,
                  paddingTop: '7%',
                }}
              >
                A federated platform that integrates, manages, and catalogs
                diverse data to advance California's Million Acre Strategy with
                actionable insights and comprehensive reporting.
              </h1>
            </div>
            <div style={{ flex: 1.1 }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  height: '100%',
                  width: '100%',
                  border: '20px solid #EEEDE2',
                  backgroundColor: '#E9E5C3',
                  borderRadius: '23px',
                  overflow: 'hidden',
                }}
              >
                <Carousel />
              </div>
            </div>
            {/* <img style={{borderRadius:4,boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"}} width="350" src="https://i.ibb.co/3FccPFS/frame-generic-light-1.png"></img> */}
            {/* <input style={{backgroundColor: "rgba(255, 255, 255, 0.9)", width: "70%", padding: 10, margin: 10, borderRadius:20, border: "1px solid #7EC06C"}} type="text" placeholder="Search our super cool forest data.."></input>
							<button style={{marginLeft: 16,fontSize: 16,letterSpacing: "-0.1px",fontWeight: 500,textDecoration: "none !important",backgroundColor: "#141516",color: "#fff", border: "none",cursor: "pointer",justifyContent: "center",padding: "12px 36px",height: 48,textAlign: "center",whiteSpace: "nowrap",borderRadius: 25, background: "linear-gradient(65deg, #D1E667 0, #77BD6A 100%)"}} type="submit">Search</button><br/><br/> */}
            {/* <p>[search bar]</p> */}
            {/* <ul style={{color: "#1F4E11",fontWeight: "bold"}}>
								<li>Comprehensive search and filter</li>
								<li>Combine and visualize data on map interface</li>
								<li>Extensive geographical and environmental data</li>
								<li>Organize your user dashboard</li>
							</ul> */}

            {/* <a style={{marginTop: 16,display: "inline-flex",fontSize: 16,letterSpacing: "-0.1px",fontWeight: 500,textDecoration: "none !important",backgroundColor: "#141516",color: "#fff", border: "none",cursor: "pointer",justifyContent: "center",padding: "12px 36px",height: 48,textAlign: "center",whiteSpace: "nowrap",borderRadius: 4, background: "linear-gradient(65deg, #D1E667 0, #77BD6A 100%)"}} href="#">Explore Map link?</a> */}
          </div>
          <div
            style={{
              display: 'flex',

              padding: 0,
            }}
          >
            <div style={{ width: 400, padding: '16px 10px' }}>
              <div
                style={{
                  position: 'relative',
                  backgroundColor: '#ffffff',
                  padding: '10px 20px',
                  boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
                  borderRadius: 4,
                  border: '1px solid #7EC06C',
                  height: 300,
                }}
              >
                <div style={{ display: 'block', alignItems: 'center' }}>
                  {/* <div style={{alignItems: "center", marginBottom: 0, marginRight:16}}>
											<img
											height={60}
											src={cwi_image}
											/>
										</div>  */}
                  <h4
                    style={{
                      color: '#000000',
                      fontSize: 16,
                      marginTop: 10,
                      fontWeight: 'bold',
                    }}
                  >
                    Data Collections
                  </h4>
                  <br></br>
                </div>

                <p style={{ marginTop: -10, fontSize: 13, paddingBottom: 20 }}>
                  Dedicated, interactive dashboards and web pages present
                  diverse data collections, providing tailored insights into
                  wildfire treatments, resilience metrics, and forest health.
                  Users can easily sort and filter data by region, county, land
                  ownership, and more for customized analysis. Featured
                  collections include the Interagency Treatment Tracking Data
                  Collection and the CA Landscape Metrics Data Collection.
                </p>
                <div style={{ position: 'absolute', bottom: 16, right: 16 }}>
                  <img height={20} src={cwi_small_logo}></img>
                </div>
              </div>
            </div>

            <div style={{ width: 400, padding: '16px 10px' }}>
              <div
                style={{
                  position: 'relative',
                  backgroundColor: '#ffffff',
                  padding: '10px 20px',
                  boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
                  borderRadius: 4,
                  border: '1px solid #7EC06C',
                  height: 300,
                }}
              >
                <div style={{ display: 'block', alignItems: 'center' }}>
                  {/* <div style={{alignItems: "center", marginBottom: 10, marginRight:16}}>
												<img
												height={40}												
												src={interagency_treatment}
												style={{backgroundColor: '#1E1A10', borderRadius: '3px'}}
												/> 
											</div> */}
                  <h4
                    style={{
                      color: '#000000',
                      fontSize: 16,
                      marginTop: 10,
                      fontWeight: 'bold',
                    }}
                  >
                    Data Catalog
                  </h4>
                  <br></br>
                </div>

                <p style={{ marginTop: -10, fontSize: 13, paddingBottom: 20 }}>
                  A comprehensive catalog of datasets from diverse sources,
                  accessible through an intuitive search interface. Users can
                  easily explore and retrieve data using keywords, data types,
                  organizations, and other metadata. The catalog also offers API
                  endpoints for downloading and clipping data, enabling seamless
                  integration into other applications.
                </p>
                <div style={{ position: 'absolute', bottom: 16, right: 16 }}>
                  <img height={20} src={cwi_small_logo}></img>
                </div>
              </div>
            </div>
            <div style={{ width: 400, padding: '16px 10px' }}>
              <div
                style={{
                  position: 'relative',
                  backgroundColor: '#ffffff',
                  padding: '10px 20px',
                  boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
                  borderRadius: 4,
                  border: '1px solid #7EC06C',
                  height: 300,
                }}
              >
                <div style={{ display: 'block', alignItems: 'center' }}>
                  {/* <div style={{alignItems: "center", marginBottom: 10, marginRight:16}}>
												<img
												height={35}
												src={wifire_image_2} style={{borderRadius: '3px'}}/>
											</div>  */}
                  <h4
                    style={{
                      color: '#000000',
                      fontSize: 16,
                      marginTop: 10,
                      fontWeight: 'bold',
                    }}
                  >
                    Workspace
                  </h4>
                  <br></br>
                </div>

                <p style={{ marginTop: -10, fontSize: 13, paddingBottom: 20 }}>
                  An intuitive map-based interface for powerful analytics and
                  visualizations. Users can add and overlay data layers, apply
                  filters, define boundaries, and perform in-depth analyses.
                  Generate detailed reports effortlessly, ensuring transparency
                  and trust with clear, traceable data insights.
                </p>
                <div style={{ position: 'absolute', bottom: 16, right: 16 }}>
                  <img height={20} src={cwi_small_logo}></img>
                </div>
              </div>
            </div>
            {/* <div style={{ width: 325, padding: "16px 10px"}}>
								<div style={{backgroundColor: "#ffffff", padding: "10px 20px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", borderRadius: 4, border: "1px solid #7EC06C", height: 300}}>
									<a target="_blank" href="http://twsa.ucsd.edu:5000/">
										<div style={{ display: "block", alignItems: "center"}}>
											<div style={{alignItems: "center", marginBottom: 10, marginRight:16}}>
												<img
												height={35}
												src={wifire_image_2} style={{borderRadius: '3px'}}/>
											</div> 
											<h4 style={{marginLeft: 10, fontSize: 14, fontWeight: "bold"}}>Data Catalog</h4><br></br>
										</div>
									</a>
									<p style={{marginTop: -10,fontSize:13}}>
									CKAN Organizations are used to create, manage and publish collections of datasets. Users can have different roles within an Organization, depending on their level of authorization to create, edit and publish.
									</p>
								</div>
							</div> */}
          </div>
        </div>
        {/* <div style={{textAlign: 'left', marginTop: '1em', padding: 0, backgroundColor:"#ffffff", height: "80%"}}>
						<img height="100%" src="https://149835821.v2.pressablecdn.com/wp-content/uploads/2022/11/1-2.png"></img>
					</div> */}
      </div>
    </body>
  );
};

export default Home;
