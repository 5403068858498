import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { StarFilled } from '@ant-design/icons';
import { Checkbox, Typography, Row, Col } from 'antd';
import { debounce } from 'lodash';
import axios, * as others from 'axios';

const { Text } = Typography;

// watershed improvement is not apart of Million Acres per Carl email
const categoryColorMap = {
  // "Watershed & Habitat Improvement": '#4294b4',
  'Tree Planting': '#6bc9b5',
  'Timber Harvest': '#a8ce85',
  'Sanitation & Salvage': '#b98140',
  'Mechanical and Hand Fuels Reduction': '#9f57ae',
  Grazing: '#fae15e',
  'Beneficial Fire': '#c83d3a',
  'Land Protection': '#de89e6',
  'Not Reported': '#A5A5A5',
};

// const getCategoryValue = (category) => {
//     if (category === "Grazing") {
//         return "GRAZING";
//     } else if (category === "Not Defined") {
//         return "NOT_DEFINED";
//     } else if (category === "Sanitation & Salvage") {
//         return "SANI_SALVG";
//     } else if (category === "Mechanical and Hand Fuels Reduction") {
//         return "MECH_HFR";
//     } else if (category === "Timber Harvest") {
//         return "TIMB_HARV";
//     } else if (category === "Land Protection") {
//         return "LAND_PROTEC";
//     // } else if (category === "Watershed & Habitat Improvement") {
//     //     return "WATSHD_IMPRV";
//     } else if (category === "Beneficial Fire") {
//         return "BENEFICIAL_FIRE";
//     } else if (category === "Tree Planting") {
//         return "TREE_PLNTING";
//     } else if(category === 'Not Reported'){
//         return '';
//     }else {
//         return null;
//     }
// }

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: 'white',
          border: '1px solid lightgray',
          fontSize: '12px',
          fontWeight: 'normal',
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
          padding: 6,
        }}
      >
        {`${payload[0].payload.category} : ${payload[0].payload.acres}`}
      </div>
    );
  }

  return null;
};

export default class AcresByActivityCategories extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  getAcres(category, data_list) {
    for (let i = 0; i < data_list.length; i++) {
      if (this.props.getCategoryValue(category) === data_list[i][0]) {
        return data_list[i][1];
      }
    }
    return 0;
  }

  handleClick(data, index) {
    // let newData = [...this.state.data];
    let newData = [...this.props.categoryData];

    // let allSelected = newData.any(e => e.visible === false) === false
    newData = newData.map((item) => {
      if (item.category === data.category) {
        return {
          ...item,
          visible: !item.visible,
          acres: !item.visible ? item.acres : 0,
        };
      } else {
        return item;
      }
    });

    this.props.setCategoryData(newData);

    this.props.setCategories(
      newData
        .filter((item) => item.visible === true)
        .map((item) => item.category)
    );
  }

  // prevent too many requests generated by the sliders
  debounceLog = debounce(async (valueRanges) => {
    this.setState({ loading: true });

    let visible_cat = this.props.categoryData
      .filter((e) => e.visible)
      .map((e) => this.props.getCategoryValue(e.category));
    let visible_cat_str = "'" + visible_cat.join("', '") + "'";

    let visible_owner = this.props.ownerData
      .filter((e) => e.visible)
      .map((e) => this.props.getOwnerValue(e.owner));
    let visible_owner_str = "'" + visible_owner.join("', '") + "'";

    let visible_agency = this.props.agencyData
      .filter((e) => e.visible)
      .map((e) => this.props.getAgencyValue(e.agency));
    let visible_agency_str = "'" + visible_agency.join("', '") + "'";

    let data_collections = [];
    let returned_categories = [];
    var count = 0;
    for (const wms_url in valueRanges) {
      let elements = wms_url.split('/');
      let wfs_name = elements[elements.length - 1];

      let min = valueRanges[wms_url][0];
      let max = valueRanges[wms_url][1];

      let cql = `activity_end >= '${min * 100000}' `;
      cql += ` AND activity_end <= '${max * 100000}' `;
      cql += ` AND activity_cat IN (${visible_cat_str})`;
      cql += ` AND primary_ownership_group IN (${visible_owner_str})`;
      cql += ` AND agency IN (${visible_agency_str})`;
      cql += ` AND primary_ownership_group is not null`;
      cql += ` AND agency is not null`;
      cql += ` AND activity_cat is not null`;

      var xmlBodyStr = `<?xml version="1.0" encoding="UTF-8"?><wps:Execute xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.opengis.net/wps/1.0.0" xmlns:wfs="http://www.opengis.net/wfs" xmlns:wps="http://www.opengis.net/wps/1.0.0" xmlns:ows="http://www.opengis.net/ows/1.1" xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" xmlns:wcs="http://www.opengis.net/wcs/1.1.1" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0.0" service="WPS" xsi:schemaLocation="http://www.opengis.net/wps/1.0.0 http://schemas.opengis.net/wps/1.0.0/wpsAll.xsd">
  <ows:Identifier>gs:Aggregate</ows:Identifier>
  <wps:DataInputs>
    <wps:Input>
      <ows:Identifier>features</ows:Identifier>
      <wps:Reference mimeType="text/xml" xlink:href="http://geoserver/wps" method="POST">
        <wps:Body>
          <wps:Execute xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.opengis.net/wps/1.0.0" xmlns:wfs="http://www.opengis.net/wfs" xmlns:wps="http://www.opengis.net/wps/1.0.0" xmlns:ows="http://www.opengis.net/ows/1.1" xmlns:gml="http://www.opengis.net/gml" xmlns:ogc="http://www.opengis.net/ogc" xmlns:wcs="http://www.opengis.net/wcs/1.1.1" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0.0" service="WPS" xsi:schemaLocation="http://www.opengis.net/wps/1.0.0 http://schemas.opengis.net/wps/1.0.0/wpsAll.xsd">
            <ows:Identifier>gs:Query</ows:Identifier>
            <wps:DataInputs>
              <wps:Input>
                <ows:Identifier>features</ows:Identifier>
                <wps:Reference mimeType="text/xml" xlink:href="http://geoserver/wfs" method="POST">
                  <wps:Body>
                    <wfs:GetFeature service="WFS" version="1.0.0" outputFormat="GML2" xmlns:ITS="https://sparcal.sdsc.edu/geoserver/ITS">
                      <wfs:Query typeName="${wfs_name}"/>
                    </wfs:GetFeature>
                  </wps:Body>
                </wps:Reference>
              </wps:Input>
              <wps:Input>
                <ows:Identifier>attribute</ows:Identifier>
                <wps:Data>
                  <wps:LiteralData>activity_cat</wps:LiteralData>
                </wps:Data>
              </wps:Input>
              <wps:Input>
                <ows:Identifier>attribute</ows:Identifier>
                <wps:Data>
                  <wps:LiteralData>activity_quantity</wps:LiteralData>
                </wps:Data>
              </wps:Input>
              <wps:Input>
                <ows:Identifier>filter</ows:Identifier>
                <wps:Data>
                  <wps:ComplexData mimeType="text/plain; subtype=cql"><![CDATA[${cql}]]></wps:ComplexData>
                </wps:Data>
              </wps:Input>
            </wps:DataInputs>
            <wps:ResponseForm>
              <wps:RawDataOutput mimeType="application/json">
                <ows:Identifier>result</ows:Identifier>
              </wps:RawDataOutput>
            </wps:ResponseForm>
          </wps:Execute>
        </wps:Body>
      </wps:Reference>
    </wps:Input>
    <wps:Input>
      <ows:Identifier>aggregationAttribute</ows:Identifier>
      <wps:Data>
        <wps:LiteralData>activity_quantity</wps:LiteralData>
      </wps:Data>
    </wps:Input>
    <wps:Input>
      <ows:Identifier>function</ows:Identifier>
      <wps:Data>
        <wps:LiteralData>Sum</wps:LiteralData>
      </wps:Data>
    </wps:Input>
    <wps:Input>
      <ows:Identifier>singlePass</ows:Identifier>
      <wps:Data>
        <wps:LiteralData>True</wps:LiteralData>
      </wps:Data>
    </wps:Input>
    <wps:Input>
      <ows:Identifier>groupByAttributes</ows:Identifier>
      <wps:Data>
        <wps:LiteralData>activity_cat</wps:LiteralData>
      </wps:Data>
    </wps:Input>
  </wps:DataInputs>
  <wps:ResponseForm>
    <wps:RawDataOutput mimeType="application/json">
      <ows:Identifier>result</ows:Identifier>
    </wps:RawDataOutput>
  </wps:ResponseForm>
</wps:Execute>`;

      var config = {
        headers: {
          'Content-Type': 'text/plain',
          // "Access-Control-Allow-Origin": "*",
          Accept: 'application/json',
        },
      };

      await axios
        .post('https://sparcal.sdsc.edu/geoserver/ITS/wps', xmlBodyStr, config)
        .then((res) => {
          let resultList = res.data.AggregationResults;
          resultList
            .map((item) => item[0])
            .forEach((item) => {
              if (!returned_categories.includes(item)) {
                returned_categories.push(item);
              }
            });
          data_collections.push(resultList);
          count += 1;
          if (count === Object.keys(valueRanges).length) {
            // let newData = [...this.state.data];
            let newData = [...this.props.categoryData];
            let madeChanges = false;
            newData = newData.map((item) => {
              let acres = 0;
              for (let i = 0; i < data_collections.length; i++) {
                acres += this.getAcres(item.category, data_collections[i]);
              }
              if (Math.ceil(item.acres) !== Math.ceil(acres)) {
                madeChanges = true;
              }
              return {
                category: item.category,
                acres: Math.ceil(acres),
                visible: item.visible,
              };
            });
            if (madeChanges) {
              // this.setState({
              //     data: newData
              // });
              this.props.setCategoryData(newData);
            }
          }
        })
        .catch((err) => {
          // var res;
          // if (wfs_name.includes('Polygon')) {
          //     res = {
          //         "GroupByAttributes": ["activity_cat"],
          //         "AggregationResults": [
          //             ["MECH_HFR", 4000423.17053515],
          //             ["TREE_PLNTING", 713854.5799999987],
          //             ["BENEFICIAL_FIRE", 1445396.9575621183],
          //             ["SANI_SALVG", 312802.4999999996],
          //             ["TIMB_HARV", 936736.1400000021],
          //             ["GRAZING", 71574.75000000001],
          //             ["LAND_PROTEC", 1414764.0342575898],
          //             ["WATSHD_IMPRV", 210299.0300000507]],
          //         "AggregationFunctions": ["Sum"],
          //         "AggregationAttribute": "activity_quantity"
          //     };
          // } else {
          //     res = {
          //         "GroupByAttributes": ["activity_cat"],
          //         "AggregationResults": [["MECH_HFR", 367246.470000003]],
          //         "AggregationFunctions": ["Sum"],
          //         "AggregationAttribute": "activity_quantity"
          //     };
          // }
          //
          // let resultList = res.AggregationResults;
          // resultList.map(item => item[0]).forEach(
          //     item => {
          //         if (!returned_categories.includes(item)) {
          //             returned_categories.push(item);
          //         }
          //     }
          // )
          // data_collections.push(resultList);
          // count += 1;
          // if (count === Object.keys(valueRanges).length) {
          //     // console.log("400 =====> merge data ");
          //
          //     let newData = [...this.state.data];
          //     let madeChanges = false;
          //     newData = newData.map(item => {
          //
          //         // console.log('------- process item: ' + item.category);
          //         let acres = 0;
          //         for (let i = 0; i < data_collections.length; i++) {
          //             // console.log('     -----' + JSON.stringify(data_collections[i]));
          //             // console.log('     =====' + getAcres(item.category, data_collections[i]));
          //             acres += getAcres(item.category, data_collections[i]);
          //         }
          //         if (Math.ceil(item.acres) !== Math.ceil(acres)) {
          //             madeChanges = true;
          //         }
          //         return {
          //             category: item.category,
          //             acres: Math.ceil(acres),
          //             visible: item.visible,
          //         }
          //     });
          //
          //     // console.log(JSON.stringify(newData, null, 2));
          //     if (madeChanges) {
          //         // console.log("==========> made changes: TRUE");
          //         this.setState({
          //             data: newData
          //         });
          //     }
          // }
        });
    }

    this.setState({ loading: false });
  }, 1000);

  componentDidMount() {
    this.debounceLog(this.props.valueRanges);
  }

  componentDidUpdate(prevProps) {
    let prevVisibleOwners = prevProps.ownerData
      .filter((e) => e.visible)
      .map((e) => e.owner);
    prevVisibleOwners.sort();
    let currentVisibleOwners = this.props.ownerData
      .filter((e) => e.visible)
      .map((e) => e.owner);
    currentVisibleOwners.sort();
    let prevVisibleAgencies = prevProps.agencyData
      .filter((e) => e.visible)
      .map((e) => e.agency);
    prevVisibleAgencies.sort();
    let currentVisibleAgencies = this.props.agencyData
      .filter((e) => e.visible)
      .map((e) => e.agency);
    currentVisibleAgencies.sort();

    let prevVisibleCategories = prevProps.categoryData
      .filter((e) => e.visible)
      .map((e) => e.category);
    prevVisibleCategories.sort();
    let currentVisibleCategories = this.props.categoryData
      .filter((e) => e.visible)
      .map((e) => e.category);
    currentVisibleCategories.sort();

    if (
      JSON.stringify(prevVisibleOwners) !==
        JSON.stringify(currentVisibleOwners) ||
      JSON.stringify(currentVisibleAgencies) !==
        JSON.stringify(prevVisibleAgencies) ||
      prevVisibleCategories.length < currentVisibleCategories.length
    ) {
      this.debounceLog(this.props.valueRanges);
    }
  }

  render() {
    let total_acres = 0;
    this.props.categoryData.forEach((item) => {
      total_acres += item.acres;
    });

    return (
      <div>
        {this.state.loading ? (
          <div> Loading ... </div>
        ) : (
          <div style={{ width: '100%', marginTop: 0 }}>
            {/* <ResponsiveContainer width="100%" height={200}>
                    <BarChart width={300} height={100} data={data}>
                        <Bar dataKey="acres"
                             label={{
                                 position: "top",
                                 style: {fontSize: 8},
                                 fill: "gray",
                             }}
                             onMouseOver={(data) => {
                                 this.setState({
                                     barGraphData: data
                                 })
                             }}
                             onClick={this.handleClick}>
                            {data.map((entry, index) => (
                                <Cell cursor="pointer"
                                      fill={
                                          data[index].visible === true ?
                                              categoryColorMap[data[index].category]
                                              :
                                              '#dddddd'
                                      }
                                      key={`cell-${index}`}/>
                            ))}
                        </Bar>
                        <Tooltip cursor={false}
                                 // position={{y: this.state.barGraphData.y - 60}}
                                 position={{y: 0}}
                                 content={<CustomTooltip/>}/>
                    </BarChart>
                </ResponsiveContainer> */}
            {/* <table style={{
                    fontSize: 11,
                    fontWeight: 'normal',
                    textAlign: 'left',
                    marginLeft: 1,
                    // marginTop: 10,
                    width: "100%"
                }}> */}
            {/* <tbody> */}
            {this.props.categoryData
              .sort((a, b) => b.acres - a.acres)
              .map((item) => (
                <Row style={{ border: '5px' }}>
                  <Col span={20}>
                    <Checkbox
                      onClick={() => this.handleClick(item)}
                      checked={item.visible}
                      style={{
                        paddingRight: 5,
                        fontWeight: 'normal',
                        fontSize: '13px',
                      }}
                    >
                      {item.category}
                    </Checkbox>
                  </Col>
                  <Col span={4}>
                    <div
                      style={{
                        float: 'right',
                        fontSize: '13px',
                        fontWeight: 'normal',
                      }}
                    >
                      {item.acres.toLocaleString()}
                    </div>
                  </Col>
                </Row>
              ))}
            <Row style={{ marginTop: '10px' }}>
              <Col span={20} style={{ fontSize: '13px' }}>
                Total Acres
              </Col>
              <Col span={4}>
                <div
                  style={{
                    float: 'right',
                    fontSize: '13px',
                    fontWeight: 'normal',
                  }}
                >
                  {total_acres.toLocaleString()}
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}
