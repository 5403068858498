import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import { FaCopy } from 'react-icons/fa';

const APIsModal = (props) => {
  const { onHide, data } = props;
  const displayData = (resourceData) => {
    if (resourceData.format === 'WMS') {
      return {
        description: resourceData.description,
        format: resourceData.format,
        wms_layer: resourceData.wms_layer,
        url: resourceData.url,
      };
    } else if (resourceData.format === 'WCS') {
      return {
        description: resourceData.description,
        format: resourceData.format,
        wcs_layer: resourceData.wcs_coverage_id,
        url: resourceData.url,
      };
    } else if (resourceData.format === 'WFS') {
      return {
        description: resourceData.description,
        format: resourceData.format,
        wfs_layer: resourceData.wfs_feature_id,
        url: resourceData.url,
      };
    } else if (resourceData.format === 'GDB') {
      return {
        description: resourceData.description,
        format: resourceData.format,
        gdb_layer: resourceData.name,
        url: resourceData.url,
      };
    } else if (resourceData.format === 'ArcGIS Feature Service') {
      return {
        description: resourceData.description,
        format: resourceData.format,
        ArcGIS_layer: resourceData.name,
        url: resourceData.url,
      };
    } else if (resourceData.format === 'GeoTiff') {
      return { format: resourceData.format, url: resourceData.url };
    } else if (resourceData.format === 'SHP') {
      return {
        description: resourceData.description,
        format: resourceData.format,
        shp_layer: resourceData.name,
        url: resourceData.url,
      };
    } else {
      return {};
    }
  };
  const handleDataOrganize = () => {
    const hashTable = new Map();
    data.resources.map((data) => {
      const values = displayData(data);
      let inMap = hashTable.get(values.format);
      if (inMap === undefined) {
        hashTable.set(values.format, [values]);
      } else {
        let currentValues = hashTable.get(values.format);
        let newValues = [...currentValues, values];
        hashTable.set(values.format, newValues);
      }
    });
    return hashTable;
  };
  const capitalize = (str) => {
    let string = '';
    str.split('_').forEach((word) => {
      if (word.length <= 3) {
        string += word.toUpperCase() + ' ';
      } else {
        string += word.charAt(0).toUpperCase() + word.slice(1) + ' ';
      }
    });
    return string.trim();
  };

  return (
    <Modal
      show={true}
      onHide={onHide}
      size="lg"
      aria-labelledby="view_more_modal"
      centered
    >
      <Modal.Header closeButton>
        <h4>APIs</h4>
      </Modal.Header>
      <Modal.Body>
        <Tabs>
          {Array.from(handleDataOrganize()).map(([dataKey, dataValues]) => {
            return (
              <Tab
                title={dataKey}
                eventKey={dataKey}
                key={data.name + ':' + dataKey + dataValues}
              >
                <br />
                {dataValues.map((dataValue, i) => {
                  return (
                    <>
                      {Object.entries(dataValue).map(([key, value]) => {
                        if (key == 'url' && dataKey.search('ArcGIS') != -1) {
                          return (
                            <div
                              style={{
                                marginBottom: '4px',
                                wordWrap: 'break-word',
                              }}
                              key={i}
                            >
                              <strong>{key}: </strong>
                              <a href={value} target="_blank">
                                {value}
                              </a>
                            </div>
                          );
                        } else if (key == 'url') {
                          return (
                            <div
                              style={{
                                marginBottom: '4px',
                                wordWrap: 'break-word',
                              }}
                              key={i}
                            >
                              <strong>{key.toUpperCase()}: </strong>
                              {value}{' '}
                              <Button
                                onClick={() => {
                                  navigator.clipboard.writeText(value);
                                }}
                                variant="secondary"
                                size="sm"
                              >
                                <FaCopy />
                              </Button>
                            </div>
                          );
                        }
                        return value ? (
                          <div
                            style={{
                              marginBottom: '4px',
                              wordWrap: 'break-word',
                            }}
                            key={i}
                          >
                            <strong>{capitalize(key)}:</strong>{' '}
                            {value ? value : 'null'}
                          </div>
                        ) : null;
                      })}
                      <br />
                    </>
                  );
                })}
              </Tab>
            );
          })}
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onHide()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default APIsModal;
