const config = {
  'Regional Resource Kit': {
    'Southern California': [
      {
        name: 'Housing Burden Percentile',
        url: 'https://sparcal.sdsc.edu/geoserver/rrk_sc/wms',
        layer: 'rrk_sc:HousingBurdenPctl',
        type: 'raster',
        max: 100,
        min: 0,
        color_start: '#FFC0CB',
        color_end: '#D90026',
        metadata: [
          {
            name: 'Data Vintage',
            value: '2021',
          },
          {
            name: 'Unit Of Measure',
            value: 'Percent',
          },
          {
            name: 'Description',
            value:
              '<p>Housing-Burdened Low-Income Households. Percent of households ' +
              'in a census tract that are both low income (making less than 80% of ' +
              'the HUD Area Median Family Income) and severely burdened by housing ' +
              'costs (paying greater than 50% of their income to housin costs). ' +
              '(5-year estimates, 2013-2017).</p>' +
              '<p>The cost and availability of housing is an important determinant of ' +
              'well-being. Households with lower incomes may spend a larger proportion ' +
              'of their income on housing. The inability of households to afford ' +
              'necessary non-housing goods after paying for shelter is known as ' +
              'housing-induced poverty. California has very high housing costs ' +
              'relative to much of the country, making it difficult for many to ' +
              'afford adequate housing. Within California, the cost of living varies ' +
              'significantly and is largely dependent on housing cost, availability, ' +
              'and demand.</p>' +
              '<p>Areas where low-income households may be stressed by high housing costs ' +
              'can be identified through the Housing and Urban Development (HUD) ' +
              'Comprehensive Housing Affordability Strategy (CHAS) data. We measure ' +
              'households earning less than 80% of HUD Area Median Family Income ' +
              'by county and paying greater than 50% of their income to housing costs. ' +
              'The indicator takes into account the regional cost of living for both ' +
              'homeowners and renters, and factors in the cost of utilities. CHAS data ' +
              "are calculated from US Census Bureau's American Community Survey (ACS).</p>",
          },
          {
            name: 'Creation Method',
            value:
              '<p>CalEnviroScreen, Version 4.0, is a science-based method for identifying ' +
              'impacted communities by taking into consideration pollution exposure and its ' +
              'effects, as well as health and socioeconomic status, at the census-tract level. ' +
              'CalEnviroScreen 4.0 uses the census tract as the unit of analysis. Census tract ' +
              "boundaries are available from the Census Bureau. CalEnviroScreen uses the Bureau's " +
              '2010 boundaries. New boundaries will be drawn by the Census Bureau as part of the ' +
              '2020 Census but will not be available until 2022. OEHHA will address updates to ' +
              'census tract geography in CalEnviroScreen at that time. There are approximately ' +
              '8,000 census tracts in California, representing a relatively fine scale of analysis. ' +
              'Census tracts are made up of multiple census blocks, which are the smallest geographic ' +
              'unit for which population data are available. Some census blocks have no people ' +
              'residing in them (unpopulated blocks).</p>' +
              '<p>The CalEnviroScreen model is based on the CalEPA working definition in that:' +
              '<ul><li>The model is place-based and provides information for the entire State of California ' +
              'on a geographic basis. The geographic scale selected is intended to be useful for ' +
              'a wide range of decisions.</li>' +
              '<li>The model is made up of multiple components cited in the above definition as ' +
              'contributors to cumulative impacts.</li>' +
              '<li>The model includes two components representing Pollution Burden - Exposures and ' +
              'Environmental Effects</li>' +
              '<li>The model includes two components representing Population Characteristics - ' +
              'Sensitive Populations (e.g., in terms of health status and age) and Socioeconomic ' +
              'Factors.</li></ul></p>' +
              '<p>The American Community Survey (ACS) is an ongoing survey of the US population ' +
              'conducted by the US Census Bureau and has replaced the long form of the decennial ' +
              'census. Unlike the decennial census, which attempts to survey the entire population ' +
              'and collecs a limited amount of information, the ACS releases results annually based ' +
              'on a sub-sample of the population and includes more detailed information on socioeconomic ' +
              'factors. Multiple years of data are pooled together to provide more reliable estimates ' +
              'for geographic areas with small population sizes. Each year, the HUD receives custom ' +
              'tabulations of ACS data from the US Census Bureau. These data, known as the "CHAS" data ' +
              '(Comprehensive Housing Affordability Strategy), demonstrate the extent of housing ' +
              'problems and housing needs, particularly for low-income households. The most recent ' +
              'results available at the census tract scale are the 5-year estimates for 2013-2017. ' +
              'The data are available from the HUD user website (see page 174 in the document link ' +
              'below:</p> ' +
              '<p><a target="_blank" ' +
              'href="https://oehha.ca.gov/media/downloads/calenviroscreen/report/calenviroscreen40reportf2021.pdf" >https://oehha.ca.gov/media/downloads/calenviroscreen/report/calenviroscreen40reportf2021.pdf</a></p>',
          },
          {
            name: 'Credits',
            value:
              '<p>California Environmental Protection Agency, CalEnviroScreen 4.0<br/>' +
              'Office of Environmental Health Hazard Assessment, CAL EPA</p>',
          },
          {
            name: 'Data disclaimer',
            value:
              '<p>The State makes no claims, promises, or guarantees about the accuracy, completeness,' +
              ' reliability, or adequacy of these data and expressly disclaims liability for errors ' +
              'and omissions in these data. No warranty of any kind, implied, expressed, or statutory, ' +
              'including but not limited to the warranties of non-infringement of third party rights, ' +
              'title, merchantability, fitness for a particular purpose, and freedom from computer ' +
              'virus, is given with respect to these data.</p>',
          },
        ],
      },
      {
        name: 'Canopy Veg Cover',
        url: 'https://sparcal.sdsc.edu/geoserver/rrk_sc/wms',
        layer: 'rrk_sc:CFO_CanopyCover2020Summer',
        type: 'raster',
        max: 100,
        min: 0,
        color_start: '#CCCCFF',
        color_end: '#0000B8',
        metadata: [
          {
            name: 'Data Vintage',
            value: 'Summer 2020',
          },
          {
            name: 'Unit Of Measure',
            value: 'percent | Min: 0 | Max: 100',
          },
          {
            name: 'Description',
            value:
              'This layer represents horizontal cover fraction occupied by tree ' +
              'canopies. Maps community type & fire regime, as well as available habitat ' +
              'for tree-dwelling species.',
          },
          {
            name: 'Creation Method',
            value:
              '<p>Each forest structure metric was derived directly from airborne lidar data, ' +
              'hosted by the USGS 3D Elevation Program. However, these data are only available ' +
              "for a small fraction of California's 423,970 km² area. To overcome this, " +
              "we trained deep learning odels'a form of pattern recognition to identify " +
              'these forest structure patterns in satellite imagery, then mapped each metric ' +
              'statewide.</p>' +
              '<p>These algorithms are of the U-net family of neural network architectures that ' +
              'perform pixel-wise regression and classification tasks. The satellite data ' +
              'includes imagery from Sentinel-1 C-band radar sensors and Sentinel-2 ' +
              'multispectral sensors at 10 m spatial resolution, collected in Fall 2019. ' +
              'Future versions will include imagery from PlanetScope multispectral sensors ' +
              'at 3 m resolution.</p>' +
              '<p>Downloaded from California Forest Observatory - Organizations - WIFIRE Commons ' +
              'Data Catalog (sdsc.edu). For more information, go to ' +
              '<a target="_blank" ' +
              'href="https://forestobservatory.com/about.html#about">https://forestobservatory.com/about.html#about</a> </p>',
          },
        ],
      },
      {
        name: 'Mean Percent FRI Departure, Since 1970',
        url: 'https://sparcal.sdsc.edu/geoserver/rrk_sc/wms',
        layer: 'rrk_sc:SoCal_meanPFRID_1970',
        type: 'raster',
        max: 79,
        min: -100,
        color_start: '#FFC0CB',
        color_end: '#AC001E',
        metadata: [
          {
            name: 'Data Vintage',
            value: `2021`,
          },
          {
            name: 'Unit Of Measure',
            value: `Percent`,
          },
          {
            name: 'Description',
            value:
              '<p>Mean Percent FRID (meanPFRID_1970) quantifies the extent in percentage ' +
              'to which recent fires (i.e., since 1970) are burning at frequencies similar ' +
              'to those that occurred prior to Euro-American settlement, with the mean ' +
              'reference FRI as the basis for comparison. Mean PFRID measures the departure ' +
              'of current FRI from reference mean FRI in percent</p>',
          },
          {
            name: 'Creation Method',
            value:
              '<p>The current FRI is calculated by dividing the number of years in the fire ' +
              'record (e.g., 2019-1970=49 years inclusive) by the number of fires occurring ' +
              'between 1970 and the current year in a given polygon plus one (CurrentFRI = ' +
              'Number of years/Number of fires +1). The mean reference FRI is an approximation ' +
              'of how often, on average, a given PFR likely burned in the three or four ' +
              'centuries prior to significant Euro-American settlement. This measure does ' +
              'not return to zero when a fire occurs, unlike FRID values used in some other ' +
              'analyses (e.g., NPS FRID Index).</p>',
          },
          {
            name: 'Credits',
            value: `<p><b>CALFIRE, Department of Forestry and Fire Protection</b><br/>
                                  <i>Data disclaimer:  
                                       The State of California and the Department of Forestry 
                                       and Fire Protection make no representations or warranties 
                                       regarding the accuracy of data or maps. The user will not 
                                       seek to hold the State or the Department liable under any 
                                       circumstances for any damages with respect to any claim by 
                                       the user or any third party on account of or arising from 
                                       the use of data or maps.</i>
                                  </p>
                                  <p><b>Existing Vegetation (CALVEG 2011), USDA Forest Service, 
                                  Region 5, MARS Team</b></p>
                                  <p><b>USDA Forest Service - Region 5 - Pacific Southwest Region</b><br/>
                                  <i>Data disclaimer:  
                                        The USDA Forest Service makes no warranty, expressed or implied, 
                                        including the warranties of merchantability and fitness for a 
                                        particular purpose, nor assumes any legal liability or responsibility
                                        for the accuracy, reliability, completeness, or utility of these 
                                        geospatial data, or for the improper or incorrect use of these 
                                        geospatial data. These geospatial data and related maps or graphics 
                                        are not legal documents and are not intended to be used as such.
                                        The data and maps may not be used to determine title, ownership, 
                                        legal descriptions or boundaries, legal jurisdiction, or 
                                        restrictions that may be in place on either public or private land. 
                                        Natural hazards may or may not be depicted on the data and maps, 
                                        and land users should exercise due caution. The data are dynamic 
                                        and may change over time. The user is responsible to verify the 
                                        limitations of the geospatial data and to use the data accordingly.
                                   </i>
                                  </p>
                        `,
          },
        ],
      },
    ],
  },
  'Interagency Tracking System': [
    {
      name: 'Million Acres Flat File Points',
      url: 'https://sparcal.sdsc.edu/geoserver/ITS/wms',
      layer: 'ITS:its_points_20230822',
      type: 'vector',
      min: 16409952,
      max: 16724448,
      color: 'rgba(159, 87, 174, 0.6)',
      // 'cql_filter': ` (activity_end >= '${16409952}') AND (activity_end <= '${16724448}') AND (activity_quantity is not null) AND (agency is not null)`
    },
    {
      name: 'Million Acres Flat File Lines',
      url: 'https://sparcal.sdsc.edu/geoserver/ITS/wms',
      layer: 'ITS:its_lines_20230822',
      type: 'vector',
      min: 16409952,
      max: 16724448,
      color: 'rgba(159, 87, 174, 0.6)',
      // 'cql_filter': ` (activity_end >= '${16409952}') AND (activity_end <= '${16724448}') AND (activity_quantity is not null) AND (agency is not null)`
    },
    {
      name: 'Million Acres Flat File Polygons',
      url: 'https://sparcal.sdsc.edu/geoserver/ITS/wms',
      layer: 'ITS:its_polygons_20230822',
      type: 'vector',
      min: 16409952,
      max: 16724448,
      color: 'rgba(159, 87, 174, 0.6)',
      // 'cql_filter': ` (activity_end >= '${16409952}') AND (activity_end <= '${16724448}') AND (activity_quantity is not null) AND (agency is not null)`
    },
  ],
};

export default config;
