import axios from 'axios';

const FilterCKAN = {
  getCollectionData: async () => {
    const getCollections = await axios
      .get(
        `${process.env.REACT_APP_PUBLIC_CKAN_CATALOG_URL}/package_search?fq=organization:california-wildfire-forest-resilience-task-force&fl=extras_collection_name&rows=1000`
      )
      .then((resp) => {
        return resp.data.result.results;
      });
    return getCollections;
  },
  getPillarData: async (collection) => {
    const getCollections = await axios
      .get(
        `${process.env.REACT_APP_PUBLIC_CKAN_CATALOG_URL}/package_search?fq=organization:california-wildfire-forest-resilience-task-force&q=extras_collection_name:"${collection}"&fl=extras_pillar&rows=1000`
      )
      .then((resp) => {
        return resp.data.result.results;
      });
    return getCollections;
  },
  getElementData: async (collection, pillar) => {
    const getCollections = await axios
      .get(
        `${process.env.REACT_APP_PUBLIC_CKAN_CATALOG_URL}/package_search?fq=organization:california-wildfire-forest-resilience-task-force&q=extras_collection_name:"${collection}" AND extras_pillar:"${pillar}"&fl=extras_element&rows=1000`
      )
      .then((resp) => {
        return resp.data.result.results;
      });
    return getCollections;
  },
  getSubelementData: async (collection, pillar, element) => {
    const getCollections = await axios
      .get(
        `${process.env.REACT_APP_PUBLIC_CKAN_CATALOG_URL}/package_search?fq=organization:california-wildfire-forest-resilience-task-force&q=extras_collection_name:"${collection}" AND extras_pillar:"${pillar}"
        AND extras_element:"${element}"&fl=extras_sub_element&rows=1000`
      )
      .then((resp) => {
        return resp.data.result.results;
      });
    return getCollections;
  },
};
export default FilterCKAN;
