import React from 'react';
import { Form, FormControl, Button, Dropdown, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import FilterCollection from './FilterCollection';

const SearchBarHeader = (props) => {
  return (
    <Form>
      <Form.Label
        style={{
          color: '#525335',
          fontWeight: 'bold',
          fontSize: '18px',
          marginBottom: '0px',
        }}
      >
        Search Catalog
      </Form.Label>
      <div style={{ display: 'flex', marginBottom: '4px' }}>
        <FormControl
          type="text"
          placeholder="Search the catalog..."
          className=" flex-grow-1"
          value={props.searchQuery}
          onChange={props.handleChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              props.handleSearchClick();
            }
          }}
        />
        <Button
          variant="primary"
          style={{
            backgroundColor: '#525335',
            fontWeight: 'bolder',
            fontSize: '16px',
          }}
          onClick={props.handleSearchClick}
        >
          Search
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexWrap: 'wrap',
        }}
      >
        <FilterCollection {...props} />
      </div>
    </Form>
  );
};

export default SearchBarHeader;
