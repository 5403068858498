import { useMemo, useState } from 'react';
import FilterCKAN from '../../lib/FilterCKAN';
import IconSelect from '../IconSelect';

const FilterData = (filterData) => {
  let set = new Set();
  filterData.forEach((data) => {
    Object.entries(data).map(([, value]) => {
      set.add(value);
    });
  });
  return Array.from(set).sort((a, b) => a.localeCompare(b));
};

const FilterCollection = (props) => {
  const {
    collectionNames,
    setCollectionNames,
    pillar,
    setPillar,
    element,
    setElement,
  } = props;
  const [collectionsList, setCollectionList] = useState(null);
  const [pillarList, setPillarList] = useState(null);
  const [elementList, setElementList] = useState(null);
  useMemo(() => {
    const fetchCollectionsData = async () => {
      const fetchData = await FilterCKAN.getCollectionData();
      if (fetchData) {
        let collectionArray = FilterData(fetchData);
        setCollectionList(collectionArray);
      }
    };
    fetchCollectionsData();
  }, []);
  const handlePillarList = async (selectedCollection) => {
    const fetchData = await FilterCKAN.getPillarData(selectedCollection);
    if (fetchData) {
      let pillarArray = FilterData(fetchData);
      if (pillarArray.length === 0) {
        setPillarList(null);
      } else {
        setPillarList(pillarArray);
      }
    }
  };
  const handleElementList = async (selectedPillar) => {
    const fetchData = await FilterCKAN.getElementData(
      collectionNames,
      selectedPillar
    );
    if (fetchData) {
      let elementArray = FilterData(fetchData);
      if (elementArray.length === 0) {
        setElementList(null);
      } else {
        setElementList(elementArray);
      }
    }
  };

  return (
    <>
      <div>
        {collectionsList && (
          <IconSelect
            title={'Collection Names'}
            value={collectionNames}
            list={collectionsList}
            hasIcons={false}
            onClick={(e) => {
              setCollectionNames(e);
              handlePillarList(e);
              setPillar('');
              setElement('');
              setElementList(null);
            }}
          ></IconSelect>
        )}
      </div>
      {pillarList && (
        <div>
          <IconSelect
            title={'Pillar'}
            value={pillar}
            list={pillarList}
            hasIcons={true}
            onClick={(e) => {
              setPillar(e);
              handleElementList(e);
              setElement('');
            }}
          ></IconSelect>
        </div>
      )}
      {elementList && (
        <div>
          <IconSelect
            title={'Element'}
            value={element}
            list={elementList}
            hasIcons={false}
            onClick={(e) => {
              setElement(e);
            }}
          ></IconSelect>
        </div>
      )}
    </>
  );
};
export default FilterCollection;
